import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Player,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import pdf from "../../images/icons/pdf.png";
import word from "../../images/icons/word.png";
import ppt from "../../images/icons/ppt.png";
import ToolsService from "../../services/tools.service";

export default class ToolsInresorts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyTutoriales: true,
      emptyImagen: true,
      emptyDocumentos: true,
      emptyPresentacion: true,
      list: [],
      list2: [],
      list3: [],
      list4: [],
      IdInvestmentProyect: 1,
      idCategoryDoc: 1,
      idCategoryPre: 2,
      idCategoryVi: 3,
      idCategoryImagen: 5,
    };
  }

  componentDidMount() {
    let data = {
      IdInvestmentProyect: this.state.IdInvestmentProyect,
      IdEducationalDocumentCategory: this.state.idCategoryDoc,
    };
    let data1 = {
      IdInvestmentProyect: this.state.IdInvestmentProyect,
      IdEducationalDocumentCategory: this.state.idCategoryPre,
    };
    let data2 = {
      IdInvestmentProyect: this.state.IdInvestmentProyect,
      IdEducationalDocumentCategory: this.state.idCategoryVi,
    };
    let data3 = {
      IdInvestmentProyect: this.state.IdInvestmentProyect,
      IdEducationalDocumentCategory: this.state.idCategoryImagen,
    };
    this.listDocumentos(data);
    this.listDocumentos2(data1);
    this.listDocumentos3(data2);
    this.listDocumentos4(data3);
  }

  onClick = (e, list) => {
    if (list === "Tutoriales") {
      if (this.state.emptyTutoriales === false) {
        this.setState({
          emptyTutoriales: true,
        });
      } else {
        this.setState({
          emptyTutoriales: false,
        });
      }
    }
    if (list === "Imagen") {
      if (this.state.emptyImagen === false) {
        this.setState({
          emptyImagen: true,
        });
      } else {
        this.setState({
          emptyImagen: false,
        });
      }
    }
    if (list === "Documentos") {
      if (this.state.emptyDocumentos === false) {
        this.setState({
          emptyDocumentos: true,
        });
      } else {
        this.setState({
          emptyDocumentos: false,
        });
      }
    }
    if (list === "Presentacion") {
      if (this.state.emptyPresentacion === false) {
        this.setState({
          emptyPresentacion: true,
        });
      } else {
        this.setState({
          emptyPresentacion: false,
        });
      }
    }
  };

  listDocumentos = async (data) => {
    let response = await ToolsService.getDocumentos(data);
    if (response != undefined) {
      if (response.status === 1) {
        this.setState({
          list: response.objModel,
        });
      }
    }
  };

  listDocumentos2 = async (data) => {
    let response = await ToolsService.getDocumentos(data);
    if (response != undefined) {
      if (response.status === 1) {
        this.setState({
          list2: response.objModel,
        });
      }
    }
  };

  listDocumentos3 = async (data) => {
    let response = await ToolsService.getDocumentos(data);
    console.log(data, 'sss', response)
    if (response != undefined) {
      if (response.status === 1) {
        this.setState({
          list3: response.objModel,
        });
      }
    }
  };

  listDocumentos4 = async (data) => {
    let response = await ToolsService.getImageFile(data);
    if (response != undefined) {
      if (response.status === 1) {
        this.setState({
          list4: response.objModel,
        });
      }
    }
  };

  downloadFile = async (e, item) => {
    let id = item.idEducationalDocument;
    let name = item.nameDocument;
    let response = await ToolsService.downloadFile(id, name);
    if (response != true) {
      alert("Ocurrió un error al descargar el archivo.");
    }
  };

  openRequest = (e, item) => {
    const url = item.fileName;
    window.open(url, "_blank");
  };

  render() {
    const {
      emptyTutoriales,
      emptyImagen,
      emptyDocumentos,
      emptyPresentacion,
    } = this.state;
    return (
      <div>
        <div style={{ paddingTop: "40px" }}>
          <hr className="line-divider"></hr>
        </div>

        <Row>
          <Col>
            <h4
              onClick={(e) => this.onClick(e, "Tutoriales")}
              style={{
                background: "#f0692c",
                textAlign: "left",
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "15px",
              }}
            >
              SECCIÓN 1 - TUTORIALES
            </h4>
            {!emptyTutoriales && (
              <div
                class="ibox-content text-center no-border"
                style={{
                  boxShadow: "2px 3px 7px 2px rgba(69, 65, 78, 0.25)",
                  minHeight: "205px",
                }}
              >
                <div class="m-b-sm text-center">
                  {this.state.list3.map((item, idx) => {
                    
                    return (
                      <p
                        className="p-style"
                        variant="link"
                        onClick={(e) => this.openRequest(e, item)}
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "start",
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                      >
                        {item.educationalDocumentFormat.extension ===
                          ".pdf" && (
                          <img
                            src={pdf}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        {item.educationalDocumentFormat.extension ===
                          ".pptx" && (
                          <img
                            src={ppt}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        <span style={{ fontSize: "18px" }}>
                          {item.nameDocument}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div>
          <hr className="line-divider"></hr>
        </div>
        <Row>
          <Col>
            <h4
              onClick={(e) => this.onClick(e, "Imagen")}
              style={{
                background: "#f0692c",
                textAlign: "left",
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "15px",
              }}
            >
              SECCIÓN 2 - IMÁGENES
            </h4>
            {!emptyImagen && (
              <div
                class="ibox-content text-center no-border"
                style={{
                  boxShadow: "2px 3px 7px 2px rgba(69, 65, 78, 0.25)",
                  minHeight: "205px",
                }}
              >
                <div class="m-b-sm text-center">
                  <div className="imagen-general">
                    {this.state.list4.map((item, idx) => {
                      return (
                        <div className="imagen">
                          <span>{item.namePicture}</span>
                          <img
                            src={"data:image/png;base64," + item.base64}
                          ></img>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div>
          <hr className="line-divider"></hr>
        </div>
        <Row>
          <Col>
            <h4
              onClick={(e) => this.onClick(e, "Documentos")}
              style={{
                background: "#f0692c",
                textAlign: "left",
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "15px",
              }}
            >
              SECCIÓN 3 - DOCUMENTOS MUESTRA
            </h4>
            {!emptyDocumentos && (
              <div
                class="ibox-content text-center no-border"
                style={{
                  boxShadow: "2px 3px 7px 2px rgba(69, 65, 78, 0.25)",
                  minHeight: "205px",
                }}
              >
                <div class="m-b-sm text-center">
                  {this.state.list.map((item, idx) => {
                    return (
                      <p
                        className="p-style"
                        variant="link"
                        onClick={(e) => this.downloadFile(e, item)}
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "start",
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                      >
                        {item.educationalDocumentFormat.extension ===
                          ".pdf" && (
                          <img
                            src={pdf}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        {item.educationalDocumentFormat.extension ===
                          ".docx" && (
                          <img
                            src={word}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        <span style={{ fontSize: "18px" }}>
                          {item.nameDocument}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div>
          <hr className="line-divider"></hr>
        </div>
        <Row>
          <Col>
            <h4
              onClick={(e) => this.onClick(e, "Presentacion")}
              style={{
                background: "#f0692c",
                textAlign: "left",
                cursor: "pointer",
                color: "#fff",
                paddingLeft: "15px",
              }}
            >
              SECCIÓN 4 - PRESENTACIÓN OPORTUNIDAD / PLAN DE MERCADEO
            </h4>
            {!emptyPresentacion && (
              <div
                class="ibox-content text-center no-border"
                style={{
                  boxShadow: "2px 3px 7px 2px rgba(69, 65, 78, 0.25)",
                  minHeight: "205px",
                }}
              >
                <div class="m-b-sm text-center">
                  {this.state.list2.map((item, idx) => {
                    return (
                      <p
                        className="p-style"
                        variant="link"
                        onClick={(e) => this.downloadFile(e, item)}
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "start",
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                      >
                        {item.educationalDocumentFormat.extension ===
                          ".pdf" && (
                          <img
                            src={pdf}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        {item.educationalDocumentFormat.extension ===
                          ".pptx" && (
                          <img
                            src={ppt}
                            style={{ width: "34px", margin: "7px" }}
                          />
                        )}
                        <span style={{ fontSize: "18px" }}>
                          {item.nameDocument}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
