import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import logoBcp from "../../images/bcp-logo.jpg";
import logoInter from "../../images/interbank-logo.png";
import logoOther from "../../images/other-logo.png";
// import { PayPalButton } from 'react-paypal-button-v2'
import logo from "../../images/paypal-logo.png";
import walletImge from "../../images/wallet.png";
import UtilService from "../../services/utils.service";
import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "../payment/PayVoucher";
import PayVoucherInter from "../payment/PayVoucherInter";
import PayOther from "./PayOther";
import PayWallet from "./PayWallet";

/**
 * Class: Regierter Aftre payment
 */
export default class PayRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idPackage: 0,
      comision: 0,
      total: "0",
      initial: "0",
      totalCuotas: "0",
      discount: 0,
      packages: [],
      packages2: [],
      name: "",
      description: "",
      price: "",
      quotes: 1,
      perQuotes: 0,
      countQuotes: 0,
      initialPrice: "",
      duration: "",
      message: "",
      codeMessage: "",
      isOkQuote: true,
      showPayPal: false,
      showVoucher: false,
      isPaid: false,
      typePaidMethod: 0,
      method: "",
      bank: "",
      vouchers: [],
      typePay: 0,
      typeBank: {},
      typeExchange: {
        venta: 1,
      },
      split: {
        isQuotaInitialSplitting: false,
        numberQuotesInitial: 0,
        initialQuoteAmount: 0,
      },
      suscription: undefined,
      showWallet: false,
      amountWallet: 0,
      idUser: 0,
      validWallet: false,
      isloading: false,
      codeWallet: "",
    };

    this.getTypeBank = this.getTypeBank.bind(this);
    this.setCommission = this.setCommission.bind(this);
  }

  setCommission(commission) {
    this.setState({ comision: commission });
  }
  componentDidMount() {
    // Data from registerPaymentView
    if (
      this.props.packages !== undefined &&
      this.props.packages2 !== undefined
    ) {
      //console.log(this.props.packages);
      this.setState({ packages: this.props.packages });
      this.setState({ packages2: this.props.packages });
      let item = this.props.packages[0];
      let item2 = this.props.packages2[0];
      // console.log(this.props.packages[0]);
      if (item !== undefined) {
        if (!this.props.split.isQuotaInitialSplitting) {
          let initial = Number(item.initialPrice);
          let priceAll = Number(item.price);
          let quotesAll = priceAll - initial;
          let quotes = Number(item.numberQuotas);
          let perQuotes = quotesAll / quotes;
          // let temp = initial + perQuotes;
          let temp = initial + perQuotes * this.state.countQuotes;

          // totalCuotas:  perQuotes,
          this.setState({
            idPackage: item2.id,
            name: item.name,
            description: item2.description,
            price: item.price,
            initialPrice: item.initialPrice,
            duration: item.duration,
            total: temp,
            initial: initial,
            totalCuotas: perQuotes * this.state.countQuotes,
            perQuotes: perQuotes,
            quotes: item.numberQuotas,
            isLoaded: true,
            code: "",
            split: this.props.split,
          });
        } else {
          let initial = Number(this.props.split.initialQuoteAmount);
          let priceAll = Number(item.price);
          let quotesAll =
            priceAll - initial * this.props.split.numberQuotesInitial;
          let quotes = Number(item.numberQuotas);
          let perQuotes = quotesAll / quotes;
          // let temp = initial + perQuotes;
          let temp = initial + perQuotes * this.state.countQuotes;

          // totalCuotas:  perQuotes,
          this.setState({
            idPackage: item2.id,
            name: item.name,
            description: item2.description,
            price: item.price,
            initialPrice: item.initialPrice,
            duration: item.duration,
            total: initial,
            initial: initial,
            totalCuotas: perQuotes * this.state.countQuotes,
            perQuotes: perQuotes,
            quotes: item.numberQuotas,
            isLoaded: true,
            code: "",
            split: this.props.split,
          });
        }
      }
    }
    if (this.props.suscription !== undefined) {
      this.setState({
        suscription: this.props.suscription,
        idUser: this.props.suscription.idUser,
      });
    }

    //sessionStorage.setItem("amountPayLater",this.state.total)
  }

  //--- typePaidMethod
  // 1 -> paypal, 2 --> vouchers , 3 ->after pay(send email)
  //--- voucher body
  // type
  // bank
  // code operation
  // titular cuenta
  // Comision
  // voucher64
  // name

  componentWillReceiveProps(props) {
    // Data from registerPaymentView
    if (
      this.props.packages !== undefined &&
      this.props.packages2 !== undefined
    ) {
      //console.log(this.props.packages);
      this.setState({ packages: this.props.packages });
      this.setState({ packages2: this.props.packages });
      let item = this.props.packages[0];
      let item2 = this.props.packages2[0];
      // console.log(this.props.packages[0]);
      if (item !== undefined) {
        if (!this.props.split.isQuotaInitialSplitting) {
          let initial = Number(item.initialPrice);
          let priceAll = Number(item.price);
          let quotesAll = priceAll - initial;
          let quotes = Number(item.numberQuotas);
          let perQuotes = quotesAll / quotes;
          // let temp = initial + perQuotes;
          let temp = initial + perQuotes * this.state.countQuotes;

          // totalCuotas:  perQuotes,
          this.setState({
            idPackage: item2.id,
            name: item.name,
            description: item2.description,
            price: item.price,
            initialPrice: item.initialPrice,
            duration: item.duration,
            total: temp,
            initial: initial,
            totalCuotas: perQuotes * this.state.countQuotes,
            perQuotes: perQuotes,
            quotes: item.numberQuotas,
            isLoaded: true,
            code: "",
            split: this.props.split,
          });
        } else {
          let initial = Number(this.props.split.initialQuoteAmount);
          let priceAll = Number(item.price);
          let quotesAll =
            priceAll - initial * this.props.split.numberQuotesInitial;
          let quotes = Number(item.numberQuotas);
          let perQuotes = quotesAll / quotes;
          // let temp = initial + perQuotes;
          let temp = initial + perQuotes * this.state.countQuotes;

          // totalCuotas:  perQuotes,
          this.setState({
            idPackage: item2.id,
            name: item.name,
            description: item2.description,
            price: item.price,
            initialPrice: item.initialPrice,
            duration: item.duration,
            total: initial,
            initial: initial,
            totalCuotas: perQuotes * this.state.countQuotes,
            perQuotes: perQuotes,
            quotes: item.numberQuotas,
            isLoaded: true,
            code: "",
            split: this.props.split,
          });
        }
      }
    }
    if (this.props.suscription !== undefined) {
      this.setState({
        suscription: this.props.suscription,
        idUser: this.props.suscription.idUser,
      });
    }
  }

  componentDidMount() {
    this.getTypeBank();
    this.getExchange();
    if (this.props.eventPay) {
      this.props.eventPay(0, "quotesPay");
    }
  }

  // Get exchange dolar
  async getExchange() {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }
  /**
   * Method to get packages information
   */
  async getPackages(id) {
    if (this.state.idPackage !== id) {
      let item = await UtilService.getPackageById(id);

      if (item !== undefined) {
        let initial = Number(item.initialPrice);
        let priceAll = Number(item.price);
        let quotesAll = priceAll - initial;
        let quotes = Number(item.quotes);
        let perQuotes = quotesAll / quotes;
        let temp = initial + perQuotes;

        this.setState({
          idPackage: id,
          idUser: item.idUser,
          name: item.name,
          description: item.description,
          price: item.price,
          initialPrice: item.initialPrice,
          duration: item.duration,
          total: temp,
          initial: initial,
          totalCuotas: perQuotes,
          perQuotes: perQuotes,
          quotes: item.quotes,
          isLoaded: true,
          code: "",
        });
      }

      // this.forceUpdate();
    }
    //sessionStorage.setItem("amountPayLater",this.state.total)
  }

  async getTypeBank() {
    let response = await UtilService.getTypeBank();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeBank: (this.state.typeBank = response.objModel),
        });
      }
    }
  }

  onEventCode = (e) => {
    e.preventDefault();
    if (this.state.code.length > 0) {
      this.validateCode(this.state.code);
    }
  };

  async validateCode(code) {
    let codePromotion = await UtilService.verifyCode(code);

    if (codePromotion !== undefined && codePromotion.status > 0) {
      this.setState({
        codeMessage: "",
        discount: 1,
      });
    } else {
      this.setState({
        codeMessage: "El código no es válido o ya expiró.",
        discount: 0,
      });
    }
  }

  handleChange = (e) => {
    let value = Number(e.target.value);
    if (value >= 0 && value <= this.state.quotes) {
      let totalQuote = this.state.perQuotes * value;
      let total = this.state.initial + totalQuote - this.state.discount;

      this.setState({
        total: total,
        totalCuotas: totalQuote,
        isOkQuote: true,
        countQuotes: value,
      });

      if (this.props.eventPay) {
        this.props.eventPay(value, "quotesPay");
      }
    } else {
      let totalQuote = this.state.perQuotes * 0;
      let total = this.state.initial + totalQuote - this.state.discount;

      this.setState({
        total: total,
        totalCuotas: totalQuote,
        isOkQuote: false,
        countQuotes: 0,
      });

      if (this.props.eventPay) {
        this.props.eventPay(0, "quotesPay");
      }

      var el = document.getElementById("txtQuotes");
      el.value = "0";
    }
    // sessionStorage.setItem("amountPayLater",this.state.total)
  };

  handleChangeInitial = (e) => {
    let value = Number(e.target.value);
    if (value >= 0 && value <= this.state.split.numberQuotesInitial) {
      let totalNumInitial = this.state.split.initialQuoteAmount * value;
      let total = totalNumInitial - this.state.discount;

      this.setState({
        total: total,
        initial: totalNumInitial,
        countInitial: value,
      });

      // if (this.props.eventPay) {
      //   this.props.eventPay(value, "quotesPay");
      // }
    } else {
      let initial = this.state.split.initialQuoteAmount;
      let total = initial;

      this.setState({
        total: total,
        countInitial: 1,
      });

      if (this.props.eventPay) {
        this.props.eventPay(0, "quotesPay");
      }
    }
    // sessionStorage.setItem("amountPayLater",this.state.total)
  };

  onChange = (e, field) => {
    this.setState({ [field]: e.target.value });
    // this.setState({ [field]: e.target.value }, () => {
    //     if (this.props.onChange) {
    //         this.props.onChange(e.target.value, field);
    //       }
    // });
  };

  /**
   * Event pay
   */
  eventPay = (value, field) => {
    this.setState({ [field]: value });
    if (this.props.eventPay) {
      this.props.eventPay(value, field);
    }
  };

  /**
   * Connect with paypal
   * @param {*} e
   */
  onEventPayPal = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago con PayPal ha registrado exitosamente.");
      } else {
        if (this.state.vouchers.length > 0) {
          alert("Usted ha subido comprobantes de pago.");
        } else {
          this.handleShow();
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  // Handle modal
  handleClose = () => {
    this.setState({
      showPayPal: false,
    });
  };
  handleShow = () => {
    this.setState({
      showPayPal: true,
    });
  };

  handleRadio = (e, id) => {
    this.setState({
      typePay: id,
    });
    // Send type selected vouchers - paypal or after
    this.props.eventPay(id, "typePay");
  };

  // Add list of vouchers
  addVoucher = (voucherList) => {
    let vouchers = this.state.vouchers;
    vouchers = vouchers.concat(voucherList);
    this.setState({
      vouchers: vouchers,
    });
    this.props.eventPay(vouchers, "vouchers");
    // sessionStorage.setItem("amountPayLater",this.state.total)
  };

  deleteVoucher = (e, idx) => {
    let temp = [];
    let vouchers = this.state.vouchers;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    this.setState({
      vouchers: temp,
    });
    this.props.eventPay(temp, "vouchers");
  };

  // Change of voucher
  onchangePayVoucher = (value, field) => {
    this.setState({ [field]: value });
    if (this.props.onchangePayVoucher) {
      this.props.onchangePayVoucher(value, field);
    }
  };

  // Handle modal voucher
  handleShowVoucher = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        if (bank === "BCP") {
          this.setState({
            showVoucher: true,
            bank: "BCP",
            isBcp: true,
            isInter: false,
          });
        } else if (bank === "Interbank") {
          this.setState({
            showVoucher: true,
            bank: "Interbank",
            isBcp: false,
            isInter: true,
          });
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseVoucher = () => {
    this.setState({
      showVoucher: false,
    });
  };

  // Call function save paypal of parent
  savePaypal = () => {
    if (this.props.savePaypal) {
      this.handleClose();
      this.props.savePaypal();
    }
  };

  // Handle show modal wallet
  handleShowWallet = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showWallet: true,
        });
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseWallet = () => {
    this.setState({
      showWallet: false,
    });
  };

  // Register Payment only with wallet
  registerPayWallet = (amount) => {
    // Register wallet
    this.props.registerPayWallet(amount);
  };

  eventWallet = (amount) => {
    // Register wallet
    this.setState({
      amountWallet: amount,
    });
    if (this.props.eventWallet) {
      this.props.eventWallet(amount);
    }
  };

  // Pay Other
  // Payment other
  handleShowOther = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showOther: true,
          bank: "Otros",
          isOther: true,
          isBcp: false,
          isInter: false,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseOther = () => {
    this.setState({
      showOther: false,
    });
  };

  handleCodeWallet = (e) => {
    this.setState({
      codeWallet: e.target.value.trim(),
    });
  };

  handleValidateCode = async () => {
    // sessionStorage.setItem("amountPayLater",this.state.total)
    if (this.state.codeWallet.length > 0) {
      this.setState({ isloading: true });
      let code = this.state.codeWallet.split("-"); // TODO use regex to validate code
      //console.log(this.state.suscription);
      let username =
        this.state.suscription.user && this.state.suscription.user.username
          ? this.state.suscription.user.username
          : "";
      let response = await UtilService.validateCodeWallet(username, code[1]);
      if (
        response !== undefined &&
        response.status === 1 &&
        response.objModel === 1
      ) {
        this.setState({
          isValidCode: true,
        });
      } else {
        this.setState({
          isValidCode: false,
          isloading: false,
        });
        alert("Código no valido.");
      }
    } else {
      alert("Ingrese código de wallet.");
    }
  };
  sumartotalvoucher = () => {
    let vouchers = this.state.vouchers;
    let totalamount = Number(0);
    let cambio = Number(this.state.typeExchange.venta);
    let cantidad = 0;
    let moneda = "";

    for (let i = 0; i < vouchers.length; i++) {
      // Split base64 code type and string
      moneda = vouchers[i].typeExchangeDes;
      cantidad = Number(vouchers[i].amount);
      if (moneda == "Dólares") {
        //dolar
        totalamount += cantidad;
      } else {
        //moneda=2 sol
        //convertir sol a dolar
        cantidad /= cambio;
        totalamount += cantidad;
      }
    }
    //console.log(totalamount)
    return totalamount.toFixed(2);
  };
  render() {
    const { vouchers, typePay, typeExchange, isPaid, idUser } = this.state;

    return (
      <div>
        <Form.Label className="content-subtitle">Métodos de pagos</Form.Label>

        <div>
          <Form.Group>
            <Row>
              <Col sm={12}>
                <Form.Label>Paquete seleccionado</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Row>
                  <Form.Label column="sm">Descripción</Form.Label>
                </Row>
                {/* <Row>
                                    <Col> 
                                        <Form.Text>{this.state.name} </Form.Text>
                                    </Col>
                                </Row> */}
                <Row>
                  <Col>
                    <Form.Text>{this.state.description} </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Text>Duración: {this.state.duration} </Form.Text>
                  </Col>
                </Row>
              </Col>
              <Col sm={3}>
                <Row>
                  <Form.Label column="sm">Precio Total</Form.Label>
                </Row>
                <Row>
                  <Col>
                    <Form.Text>{this.state.price}</Form.Text>
                  </Col>
                </Row>
              </Col>
              {/* <Col>
                                <Form.Label>Nro. de cuotas</Form.Label>
                           </Col> */}
              <Col sm={3}>
                <Row>
                  <Form.Label column="sm">Inicial</Form.Label>
                </Row>
                <Row>
                  <Col>
                    <Form.Text>{this.state.initialPrice}</Form.Text>
                  </Col>
                </Row>
              </Col>
              <Col sm={3}>
                <Row>
                  <Form.Label column="sm">Nro. de cuotas</Form.Label>
                </Row>
                <Row>
                  <Col>
                    <Form.Text>{this.state.quotes}</Form.Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Form.Text style={{ color: "red", fontSize: "10" }}>
                  {this.state.message}
                </Form.Text>
              </Col>
            </Row>
          </Form.Group>
          <hr></hr>
          {/* <Form.Group>
                        <Row>
                            <Col sm={6}>
                            <Form.Label>Código promocional</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl
                                placeholder="Ingresa un código"
                                aria-describedby="basic-addon2"
                                onChange={e => this.onChange(e, "code")}
                                />
                                <InputGroup.Append>
                                    <Button variant="secondary" size="sm" onClick={this.onEventCode}>
                                        <Spinner animation="border" role="status"  size="sm"
                                            style={{display: this.state.loading? 'inline-block': 'none'}}>
                                            <span className="sr-only">Validar</span>
                                        </Spinner> Validar
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Text className="textAlert">{this.state.codeMessage}</Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                    <hr></hr> */}
          {!this.state.split.isQuotaInitialSplitting && (
            <div style={{ width: "60%" }}>
              <Row>
                <Col>
                  <Form.Label>Detalle</Form.Label>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                                <Form.Text style={{fontSize:'14px'}}>Inicial</Form.Text>
                            </Col> */}
                <Col sm="8">
                  <Form inline>
                    <Form.Text style={{ fontSize: "14px" }}>
                      Inicial : &nbsp;{" "}
                    </Form.Text>
                    <Form.Control
                      size="sm"
                      type="number"
                      min="1"
                      max="1"
                      defaultValue="1"
                      onChange={(e) => this.handleChange(e)}
                    ></Form.Control>
                  </Form>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }}>
                    $ {this.state.initial}
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col sm="8">
                  <Form inline>
                    <Form.Text style={{ fontSize: "14px" }}>
                      Total de cuotas a pagar : &nbsp;{" "}
                    </Form.Text>
                    <Form.Control
                      id="txtQuotes"
                      size="sm"
                      type="number"
                      min="0"
                      max={this.state.quotes}
                      defaultValue="0"
                      onChange={(e) => this.handleChange(e)}
                    ></Form.Control>
                  </Form>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }} id="totalCuota">
                    $ {this.state.totalCuotas}
                  </Form.Text>
                </Col>
              </Row>
              <Row style={{ display: this.state.isOkQuote ? "none" : "block" }}>
                <Col>
                  <Form.Text className="textAlert">
                    El número máximo de cuotas a pagar es : {this.state.quotes}
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Text style={{ fontSize: "14px" }}>
                    Descuento : &nbsp;{" "}
                  </Form.Text>
                </Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }} id="idDiscount">
                    $ {this.state.discount}
                  </Form.Text>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <Form.Label>Total: </Form.Label>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Label id="total">$ {this.state.total}</Form.Label>
                </Col>
              </Row>
            </div>
          )}
          {this.state.split.isQuotaInitialSplitting && (
            <div style={{ width: "60%" }}>
              <Row>
                <Col>
                  <Form.Label>Detalle</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form inline>
                    <Form.Text style={{ fontSize: "14px" }}>
                      {`El pago de inicial ha sido fraccionado en ${this.state.split.numberQuotesInitial} partes.`}
                    </Form.Text>
                  </Form>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                                <Form.Text style={{fontSize:'14px'}}>Inicial</Form.Text>
                            </Col> */}
                <Col sm="8">
                  <Form inline>
                    <Form.Text style={{ fontSize: "14px" }}>
                      Inicial : &nbsp;{" "}
                    </Form.Text>
                    {/* <Form.Control
                    size="sm"
                    type="number"
                    min="1"
                    max={this.state.split.numberQuotesInitial}
                    defaultValue="1"
                    onChange={(e) => this.handleChangeInitial(e)}
                  ></Form.Control> */}
                  </Form>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }}>
                    $ {this.state.initial}
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col sm="8">
                  <Form inline>
                    <Form.Text style={{ fontSize: "14px" }}>
                      Total de cuotas a pagar : &nbsp;{" "}
                    </Form.Text>
                    {/* <Form.Control
                    id="txtQuotes"
                    size="sm"
                    type="number"
                    min="0"
                    max={0}
                    defaultValue="0"
                    onChange={(e) => this.handleChange(e)}
                  ></Form.Control>*/}
                  </Form>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }} id="totalCuota">
                    $ {this.state.totalCuotas}
                  </Form.Text>
                </Col>
              </Row>
              <Row style={{ display: this.state.isOkQuote ? "none" : "block" }}>
                <Col>
                  <Form.Text className="textAlert">
                    El número máximo de cuotas a pagar es : {this.state.quotes}
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Text style={{ fontSize: "14px" }}>
                    Descuento : &nbsp;{" "}
                  </Form.Text>
                </Col>
                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Text style={{ fontSize: "14px" }} id="idDiscount">
                    $ {this.state.discount}
                  </Form.Text>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <Form.Label>Total: </Form.Label>
                </Col>

                <Col sm="4" style={{ textAlign: "right" }}>
                  <Form.Label id="total">$ {this.state.total}</Form.Label>
                </Col>
              </Row>
            </div>
          )}
          <hr></hr>
          {!this.state.isValidCode && (
            <Form.Group>
              <Row>
                <Col>
                  {/*    AQUI ES DONDE INGRESAS EL CODIGO */}
                  <Form.Label>Tengo un codigo Wallet</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form inline>
                    <Form.Control
                      onChange={(e) => this.handleCodeWallet(e)}
                    ></Form.Control>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={(e) => this.handleValidateCode()}
                    >
                      {this.state.isloading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {this.state.isloading ? "Validando" : "Validar"}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Form.Group>
          )}
          {this.state.isValidCode && (
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>Tengo un codigo Wallet</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    {this.state.codeWallet} <FaCheckCircle />
                  </Form.Label>
                </Col>
              </Row>
            </Form.Group>
          )}
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Selecione un medio de pago: </Form.Label>
              </Col>
            </Row>
            {/*      DESDE AQUI SE SELECCIONA UN MEDIO DE PAGO */}
            <Row style={{ padding: 16 }}>
              <Col sm={2}>
                <Image
                  src={logo}
                  rounded
                  style={{ width: "100%", maxWidth: 136, cursor: "pointer" }}
                  onClick={this.onEventPayPal}
                ></Image>
              </Col>
              <Col sm={2}>
                <Image
                  src={logoBcp}
                  rounded
                  style={{ width: "100%", maxWidth: 136, cursor: "pointer" }}
                  onClick={(e) => {
                    this.handleShowVoucher(e, "BCP");
                  }}
                ></Image>
              </Col>
              <Col sm={2}>
                <Image
                  src={logoInter}
                  rounded
                  style={{ width: "120%", maxWidth: 136, cursor: "pointer" }}
                  onClick={(e) => {
                    this.handleShowVoucher(e, "Interbank");
                  }}
                ></Image>
              </Col>

              <Col sm={2}>
                <Image
                  src={logoOther}
                  rounded
                  style={{ width: "120%", maxWidth: 136, cursor: "pointer" }}
                  onClick={(e) => {
                    this.handleShowOther(e, "Otros");
                  }}
                ></Image>
              </Col>
              {this.state.isValidCode && (
                <Col sm={3}>
                  <div
                    onClick={(e) => {
                      this.handleShowWallet(e);
                    }}
                  >
                    <p style={{ margin: 2, fontWeight: "bold" }}>
                      <Image
                        src={walletImge}
                        style={{ width: "32px", marginRight: "10px" }}
                      ></Image>
                      Mi Wallet
                    </p>
                  </div>
                </Col>
              )}
            </Row>
            {/* show the table if has register */}
            {vouchers.length > 0 && (
              <Row style={{ padding: 16 }}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>Banco</th>
                      <th>Operación</th>
                      <th>Código</th>
                      <th>Nota</th>
                      <th>Archivo</th>
                      <th>Moneda</th>
                      <th>Monto</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {vouchers.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.bank}</td>
                        <td>{item.typeDescription}</td>
                        <td>{item.code}</td>
                        <td>{item.nota}</td>
                        <td>{item.name}</td>
                        <td>{item.typeExchangeDes}</td>
                        <td>{item.amount}</td>
                        <td
                          style={{ fontSize: 15 }}
                          onClick={(e) => {
                            this.deleteVoucher(e, idx);
                          }}
                        >
                          <RiDeleteBinLine></RiDeleteBinLine>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            )}
            {/* show the PayPal payment if it was successful */}

            {isPaid && (
              <Form.Group>
                <Form.Label>Su pago ha sido registrado con PAYPAL.</Form.Label>
              </Form.Group>
            )}
          </Form.Group>
          <hr></hr>
        </div>

        {/* Modal */}
        <Modal
          show={this.state.showPayPal}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={this.state.total}
                description={this.state.name}
                eventPay={this.eventPay}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                savePaypal={this.savePaypal}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            {/* <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button> */}
          </Modal.Footer>
        </Modal>

        {/* Modal upload voucher*/}
        <Modal
          show={this.state.showVoucher}
          onHide={this.handleCloseVoucher}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de {this.state.bank}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isBcp && (
              <Form.Group>
                <PayVoucher
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  setCommission={this.setCommission}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            {this.state.isInter && (
              <Form.Group>
                <PayVoucherInter
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  setCommission={this.setCommission}
                ></PayVoucherInter>
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>
        {/* Modal pay with wallet*/}
        <Modal
          show={this.state.showWallet}
          onHide={this.handleCloseWallet}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={idUser}
                total={this.state.total}
                registerPayWallet={this.registerPayWallet}
                eventWallet={this.eventWallet}
                close={this.handleCloseWallet}
                fontSize={10}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>

        {/* Modal upload voucher other tickets*/}
        <Modal
          show={this.state.showOther}
          onHide={this.handleCloseOther}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={this.state.total}
                addVoucher={this.addVoucher}
                close={this.handleCloseOther}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                fontSize={10}
                setCommission={this.setCommission}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
