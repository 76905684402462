import React, { Component } from 'react';
//import Account from '../../components/user/Account';
import { Form, Row, Col, Table, Spinner, Container } from 'react-bootstrap';
//import Avatar from 'react-avatar-edit';
import Validation from '../../components/utils/Validation';
import UserService from '../../services/user.service';
import AuthService from '../../services/auth.service';

export default class PersonalAccountView extends Component {
    
    constructor(props) {
        super(props);
        this.state ={
            tickets: [],
            loading: true,
            emptyList: true
        }
        this.getTickets = this.getTickets.bind(this);
    }

    //Acá van los metodos ni bien cargue la página
    componentDidMount(){
        this.getTickets();
    }

    //Este método sirve para obtener la lista de los tickets del usuario.
    async getTickets(){

        let id = AuthService.getCurrentIdUser();
        let response = await UserService.getTicketUser(id);

        if(response !== undefined){
            if(response.status === 1 && response.objModel.length >0) {
                this.setState({
                    tickets: response.objModel,
                    emptyList: false,
                    message: "",
                    loading: false
                });
            } else {
                this.setState({
                    tickets: [],
                    emptyList: true,
                    message: "No hay tickets para mostrar.",
                    loading: false
                }); 
            }
        } else {
            this.setState({
                tickets: [],
                emptyList: true,
                message: "Ocurrió un problema mientras obteníamos los tickets. Inténtelo más tarde.",
                loading: false
            });
        }
    }

    render() {
        const {loading, emptyList, tickets } = this.state;
        return(
            <div className="container-fluid">
                <div>
                <div class="form-style" style={{ margin: "0 auto" }}>
                    <Form>
                        <Form.Group>

                           
                            <Container>
                            <Form.Group>
                                <h4>Seguimiento de Tickets</h4>
                            </Form.Group>
                { loading && 
                    <div>
                        <Spinner animation="border" variant="dark">
                        </Spinner>
                        <p><Form.Label>Cargando información ...</Form.Label></p>
                    </div>
                }
                {   this.state.emptyList && !this.state.loading && 
                    <Form.Label>{this.state.message}</Form.Label>
                }
                {  !emptyList && 
                    <Row>
                        <Col sm={12}>
                            <Table responsive>
                                <thead className="table-head">
                                    <tr>
                                    <th>N°</th>
                                    <th style={{width: '11%'}}>Fecha de Asignación</th>
                                    <th style={{width: '10%'}}>Número de Ticket</th>
                                    <th style={{width: '12%'}}>Tipología</th>
                                    <th>Solicitante</th>
                                    <th>Usuario</th>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th>Nro Documento</th>
                                    <th>Status</th>
                                    <th>Observaciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { tickets.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{idx+1}</td>
                                                    <td>{Validation.convertFechaHora(item.fecha)}</td> 
                                                    <td>{item.nroTicket}</td> 
                                                    <td>{(item.tipologia)}</td>
                                                    <td>{(item.solicitante)}</td> 
                                                    <td>{(item.usuario)}</td> 
                                                    <td>{(item.nombre)}</td> 
                                                    <td>{(item.apellidos)}</td> 
                                                    <td>{(item.documento)}</td> 
                                                    <td>{(item.status)}</td>
                                                    <td>{(item.obv)}</td> 
                                                
                                                <td>
                                                    {/* <Button className="ml-2 mt-2" size="sm" variant="info">Pagar Bonus</Button>
                                                    <Button className="ml-2 mt-2" size="sm" variant="success">Calcular Rangos Compuestos</Button>
                                                    <Button className="ml-2 mt-2" size="sm" variant="warning">Calcular Rango Residual</Button> */}
                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
                            </Container>
                        </Form.Group>
                    </Form>
                </div>
            </div>
            </div>
            
        );
    }
}