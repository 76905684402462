import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Image,
  Modal,
  Table,
  Alert,
  Container,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { RiDeleteBinLine } from "react-icons/ri";

import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "../payment/PayVoucher";
import logo from "../../images/paypal-logo.png";
import logoBcp from "../../images/bcp-logo.jpg";
import logoInter from "../../images/interbank-logo.png";
import walletImge from "../../images/wallet.png";
import logoOther from "../../images/other-logo.png";
import BanColombia from "../../images/colombia/bancolombia.jpg";
import DaVivienda from "../../images/colombia/davivienda.jpg";
import UtilService from "../../services/utils.service";
import PayVoucherInter from "../payment/PayVoucherInter";
import PayWallet from "../payment/PayWallet";
import PayOther from "../payment/PayOther";
import "./PaymentMethod.css";
import Select from "react-select";
import ModalContent from "components/ModalContent";
import PayVoucherDaVivienda from "components/payment/PayVoucherDaVivienda";

const RANGE_WALLET_MIN = 3;
//const RANGE_WALLET_MAX = 11;
const API_URL = process.env.REACT_APP_APP_URL_API;
const Styles = {};
/**
 * Class
 */
export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idPackage: 0,
      comision: 0,
      total: "0",
      initial: "0",
      initialToPay: 0,
      numInitial: 1,
      totalInitial: 1,
      totalCuotas: "0",
      isOpen: false,
      discount: props.discount,
      kitInit: {
        price: 0,
      },
      packages: [],
      name: "",
      description: "",
      price: "",
      quotes: 0,
      perQuotes: 0,
      countQuotes: 0,
      initialPrice: "",
      duration: "",
      message: "",
      codeMessage: "",
      isOkQuote: true,
      showPayPal: false,
      showVoucher: false,
      isPaid: false,
      mount: 0,
      typePaidMethod: 0,
      method: "",
      bank: "",
      vouchers: [],
      typePay: 0,
      typeBank: {},
      typeExchange: {
        venta: 1,
      },
      showWallet: false,
      amountWallet: 0,
      idUser: 0,
      showWalletSection: props.showWallet,
      assignedUser: "",
      assignedResult: "",
      showOther: false,
      packageStatus: props.packageStatus,
      checkboxpalcontado: false,
      valcuotas: 0,
      packagesm: [],
      idsuscription: undefined,
      idPackageDetail: undefined,
      sacum: 0,
      arraynuminitial: [],
      promotionalCode: "",
    };
    this.getTypeBank = this.getTypeBank.bind(this);
    this.setCommission = this.setCommission.bind(this);
  }

  setCommission(commission) {
    this.setState({ comision: commission });
  }
  //--- typePaidMethod
  // 1 -> paypal, 2 --> vouchers , 3 ->after pay(send email)
  //--- voucher body
  // type
  // bank
  // code operation
  // titular cuenta
  // Comision
  // voucher64
  // name
  buyprocess(prop) {
    // From register step 3: select suscription

    let item = prop.packages;
    let kit = prop.kitInit;
    let total = 0;
    if (kit.length === 0) {
      kit = {};
      kit.price = 0; // TODO exonerado
    }
    this.setState({
      discount: prop.discount,
      packages: item,
      kitInit: kit,
    });

    let initial = Number(item[0].listPackageDetails[0].initialPrice);
    let priceAll = Number(item[0].listPackageDetails[0].price);
    let quotesAll = priceAll - initial;
    let quotes = Number(item[0].listPackageDetails[0].numberQuotas);
    let perQuotes = quotesAll / quotes;
    let initialToPay = initial;
    let totalInitial = 1;
    let arraynuminitial = [];
    for (let i = 0; i < item[0].listPackageDetails[0].numberInitialQuote; i++) {
      arraynuminitial[i] = Object.assign({}, { value: i + 1, label: i + 1 });
    }

    total = kit.price + initialToPay + perQuotes * 0 - this.state.discount;

    this.setState({
      idPackage: item[0].id,
      name: item[0].name,
      description: item[0].description,
      price: item[0].listPackageDetails[0].price,
      initialPrice: item[0].listPackageDetails[0].initialPrice,
      duration: item[0].listPackageDetails[0].duration,
      initial: initial,
      numInitial: item[0].listPackageDetails[0].numberInitialQuote,
      totalInitial: totalInitial,
      initialToPay: initialToPay,
      totalCuotas: perQuotes * 0,
      perQuotes: perQuotes,
      quotes: item[0].listPackageDetails[0].numberQuotas,
      isLoaded: true,
      code: "",
      checkpalcontado: false,
      total: total,
      isOkQuote: true,
      countQuotes: 0,
      arraynuminitial: arraynuminitial,
    });

    if (document.getElementById("txtQuotes")) {
      document.getElementById("txtQuotes").value = 0;
    }
    this.props.eventPay(0, "quotesPay");
    this.props.eventPay(total, "amountPay");
    this.props.eventPay(false, "ispalcontado");
    this.props.eventPay(1, "totalInitial");
  }
  migprocess(prop) {
    let data = prop.packagesm;
    //console.log("haber data", data);
    let idPackageDetail = data[0].idPackageDetail;
    let idsuscription = prop.upgradeExperience;
    let arraynuminitial = [];
    this.props.onChange(false, "isloadedmig");
    fetch(
      `${API_URL}/payment/suscription/${idsuscription}/upgrade/package/detail/${idPackageDetail}/options?initialFraction=1`
    )
      .then((res) => res.json())
      .then((response) => {
        //console.log("respuesta de migprocess", response);
        //console.log("haber ", response.objModel.numberFractionsInitial);
        if (response.status === 1) {
          for (let i = 0; i < response.objModel.numberFractionsInitial; i++) {
            arraynuminitial[i] = Object.assign(
              {},
              { value: i + 1, label: i + 1 }
            );
          }
          this.setState({
            idPackage: data[0].packageId,
            name: data[0].package.name,
            description: data[0].package.description,
            packagesm: data,
            numInitial: data[0].numberInitialQuote,
            idsuscription: idsuscription,
            idPackageDetail: idPackageDetail,
            total: response.objModel.amountPaidUpgrade,
            sacum: response.objModel.amountTotalDiscount,
            arraynuminitial: arraynuminitial,
            totalInitial: 1,
          });
          this.props.eventPay(0, "quotesPay"); //para asegurarme que envie 0 quotas
          this.props.eventPay(1, "totalInitial");
          this.props.eventPay(response.objModel.amountPaidUpgrade, "amountPay");
          this.props.eventPay(
            response.objModel.amountTotalDiscount,
            "accumulatedsalary"
          );
          this.props.eventPay(false, "ispalcontado");
          this.props.onChange(true, "isloadedmig");
        } else {
          this.props.onChange(true, "isloadedmig");
          this.props.onChange(true, "isErrorApiData");
        }
      })
      .catch((error) => {
        this.props.onChange(true, "isloadedmig");
        this.props.onChange(error, "isErrorApiData");
      });
  }
  componentDidUpdate(props) {
    if (this.props.packageStatus === "N") {
      if (this.props.packages[0] !== props.packages[0]) {
        this.buyprocess(this.props);
      }
    } else if (this.props.packageStatus === "U") {
      if (this.props.packagesm[0].packageId !== props.packagesm[0].packageId) {
        this.migprocess(this.props);
      }
    } else {
      console.log("Alguna otra opcion");
    }
  }

  componentDidMount() {
    this.getTypeBank();
    this.getExchange();

    this.setState({
      idUser: sessionStorage.getItem("id"),
    });

    if (this.props.packageStatus === "N") {
      this.buyprocess(this.props);
    } else if (this.props.packageStatus === "U") {
      this.migprocess(this.props);
    }
  }

  // Get exchange dolar
  async getExchange() {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }
  /**
   * Method to get packages information
   */
  async getPackages(id) {
    if (this.state.idPackage !== id) {
      let item = await UtilService.getPackageById(id);
      console.log("haber item en payment", item);
      if (item !== undefined) {
        let initial = Number(item.initialPrice);
        let priceAll = Number(item.price);
        let quotesAll = priceAll - initial;
        let quotes = Number(item.quotes);
        let perQuotes = quotesAll / quotes;
        let temp = initial + perQuotes;

        this.setState({
          idPackage: id,
          name: item.name,
          description: item.description,
          price: item.price,
          initialPrice: item.initialPrice,
          duration: item.duration,
          total: temp,
          initial: initial,
          totalCuotas: perQuotes,
          perQuotes: perQuotes,
          quotes: item.quotes,
          isLoaded: true,
          code: "",
        });
      }

      // this.forceUpdate();
    }
  }

  async getTypeBank() {
    let response = await UtilService.getTypeBank();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeBank: (this.state.typeBank = response.objModel),
        });
      }
    }
  }

  onEventCode = (e) => {
    e.preventDefault();
    if (this.state.code.length > 0) {
      this.validateCode(this.state.code);
    }
  };

  async validateCode(code) {
    let codePromotion = await UtilService.verifyCode(code);

    if (codePromotion !== undefined && codePromotion.status > 0) {
      this.setState({
        codeMessage: "",
        discount: 1,
      });
    } else {
      this.setState({
        codeMessage: "El código no es válido o ya expiró.",
        discount: 0,
      });
    }
  }

  handleChange = (e) => {
    let value = Number(e.target.value);
    if (value >= 0 && value <= this.state.quotes) {
      let kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
      let totalQuote = Number((this.state.perQuotes * value).toFixed(2));
      let total = Number(
        (
          this.state.initial +
          totalQuote -
          this.state.discount +
          kitCost
        ).toFixed(2)
      );
      this.setState({
        total: total,
        totalCuotas: totalQuote,
        isOkQuote: true,
        countQuotes: value,
      });

      this.props.eventPay(value, "quotesPay");
      this.props.eventPay(total, "amountPay");
      if (value < this.state.quotes) {
        this.props.eventPay(false, "ispalcontado");
      } else {
        this.props.eventPay(true, "ispalcontado");
      }
    } else {
      let totalQuote = this.state.perQuotes * 0;
      let total = this.state.initial + totalQuote - this.state.discount;

      this.setState({
        total: total,
        totalCuotas: totalQuote,
        isOkQuote: false,
        countQuotes: 0,
      });

      this.props.eventPay(0, "quotesPay");
      this.props.eventPay(total, "amountPaid");
    }
  };

  onChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  handleChangeInitial = (e) => {
    console.log("Entraste a handleChangeInitial");
    //ACCION EN EL SELECT
    let value = Number(e.target.value);
    //poner las cuotas a 0
    let valuequota = Number(0);
    let kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
    let totalQuote = this.state.perQuotes * valuequota;
    let total =
      this.state.initial / value + totalQuote - this.state.discount + kitCost;
    let initialToPay = this.state.initial / value;

    this.setState({
      total: total,
      totalCuotas: totalQuote,
      isOkQuote: true,
      countQuotes: valuequota,
      valcuotas: valuequota,
      totalInitial: value,
      valcuotas: 0,
      initialToPay: initialToPay,
    });

    if (document.getElementById("txtQuotes")) {
      document.getElementById("txtQuotes").value = 0;
    }

    this.props.eventPay(valuequota, "quotesPay");
    this.props.eventPay(value, "totalInitial");
    this.props.eventPay(total, "amountPay");
  };

  handleChangeInitialMig = (e) => {
    //ESTO ES AL SELECCIONAR UN NUMERO
    console.log("Accionaste el Select");
    console.log("------------");
    let value = Number(e.target.value);
    console.log("valor de Fraccionar inicial en (max. 3) :  ", value);
    let idsuscription = this.state.idsuscription;
    let idPackageDetail = this.state.idPackageDetail;
    console.log("concuerda el id? ", idsuscription);
    console.log("concuerda el idPackageDetail ? ", idPackageDetail);
    this.props.onChange(false, "isloadedmig");
    fetch(
      `${API_URL}/payment/suscription/${idsuscription}/upgrade/package/detail/${idPackageDetail}/options?initialFraction=${value}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("haber respuesta en el select", response);
        if (response.status === 1) {
          this.setState({
            totalInitial: value,
            total: response.objModel.migratonFeePayment,
            sacum: response.objModel.amountTotalDiscount,
          });
          this.props.eventPay(0, "quotesPay"); //para asegurarme que envie 0 quotas
          this.props.eventPay(value, "totalInitial");
          this.props.eventPay(
            response.objModel.migratonFeePayment,
            "amountPay"
          );
          this.props.eventPay(
            response.objModel.migratonFeePayment,
            "amountPay"
          );
          this.props.eventPay(
            response.objModel.amountTotalDiscount,
            "accumulatedsalary"
          );
          this.props.onChange(true, "isloadedmig");
        } else {
          this.props.onChange(true, "isErrorApiData");
          this.props.onChange(true, "isloadedmig");
        }
      })
      .catch((error) => {
        this.props.onChange(true, "isloadedmig");
        this.props.onChange(error, "isErrorApiData");
      });
  };
  /**
   * Event paypal
   */
  eventPay = (value, field) => {
    console.log("haber value", value, "haber field", field);
    // idMethodPayment: 0,
    // mountPaid : 0,
    // isPaid: false

    this.setState({ [field]: value });
    this.props.eventPay(value, field);
  };

  /**
   * Connect with paypal
   * @param {*} e
   */
  onEventPayPal = (e) => {
    // Verify  if the form in completed
    if (this.props.validateToPay() === true) {
      if (this.state.idPackage > 0) {
        if (this.state.isPaid) {
          alert("Su pago con PayPal ha registrado exitosamente.");
        } else {
          if (this.state.vouchers.length > 0) {
            alert("Usted ha subido comprobantes de pago.");
          } else {
            this.handleShow();
          }
        }
      } else {
        alert("Debes seleccionar un paquete de suscripción.");
      }
    } else {
      alert("Para pagar con PAYPAL es necesario que complete el formulario.");
    }
  };

  // Handle modal
  handleClose = () => {
    this.setState({
      showPayPal: false,
    });
    this.forceUpdate();
  };
  handleShow = () => {
    this.setState({
      showPayPal: true,
    });
  };
  handleShowModalCupon = () => {
    this.setState({ isOpen: true });
  };
  handleCloseModalCupon = () => {
    this.setState({ isOpen: false });
  };

  // Add list of vouchers
  addVoucher = (voucherList) => {
    let vouchers = this.state.vouchers;
    vouchers = vouchers.concat(voucherList);
    this.setState({
      vouchers: vouchers,
    });
    this.props.eventPay(vouchers, "vouchers");
  };

  deleteVoucher = (e, idx) => {
    let temp = [];
    let vouchers = this.state.vouchers;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    this.setState({
      vouchers: temp,
    });
    this.props.eventPay(temp, "vouchers");
  };

  // Change of voucher
  onchangePayVoucher = (value, field) => {
    this.setState({ [field]: value });
    if (this.props.onchangePayVoucher) {
      this.props.onchangePayVoucher(value, field);
    }
  };

  // Handle modal voucher
  handleShowVoucher = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        if (bank === "BCP") {
          this.setState({
            showVoucher: true,
            bank: "BCP",
            isBcp: true,
            isInter: false,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Interbank") {
          this.setState({
            showVoucher: true,
            bank: "Interbank",
            isBcp: false,
            isInter: true,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Davivienda") {
          this.setState({
            showVoucher: true,
            bank: "Davivienda",
            isBcp: false,
            isInter: false,
            isDavi: true,
            isBancol: false,
          });
        } else if (bank === "Bancolombia") {
          this.setState({
            showVoucher: true,
            bank: "Bancolombia",
            isBcp: false,
            isInter: false,
            isDavi: false,
            isBancol: true,
          });
        }
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseVoucher = () => {
    this.setState({
      showVoucher: false,
    });
  };

  // Handle show modal wallet
  handleShowWallet = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showWallet: true,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseWallet = () => {
    this.setState({
      showWallet: false,
    });
  };

  // Register Payment only with wallet
  registerPayWallet = (amount) => {
    // Register wallet
    if (this.props.eventWallet) {
      this.props.eventWallet(amount); //PackageMainView this.state.amountWallet
    }

    if (this.props.eventPay) {
      this.props.eventPay(3, "typePay"); //type pay 3
    }

    //Verify if all fields are completed
    // Verify  if the form in completed
    const val = this.props.validateToPay();
    if (val === true) {
      this.props.registerBuy();
    } else {
      alert("Para pagar con WALLET es necesario que complete el formulario.");
    }
  };

  eventWallet = (amount) => {
    console.log("event wallet");
    this.setState({
      amountWallet: amount,
    });
    if (this.props.eventWallet) {
      this.props.eventWallet(amount);
    }
  };

  // validate user to assign payment
  validateUser = async (e) => {
    console.log("registro");
    if (this.state.assignedUser.length > 0) {
      this.setState({
        assignedLoading: true,
      });
      let response = await UtilService.getRangeByUsername(
        this.state.assignedUser
      );
      if (response !== undefined && response.status === 1) {
        // verify is > oro
        if (response.objModel.position >= RANGE_WALLET_MIN) {
          this.setState({
            assignedResult: "Rango: " + response.objModel.name,
            assignedLoading: false,
          });
        } else {
          this.setState({
            assignedResult:
              "Rango: " + response.objModel.name + " - No permitido.",
            assignedLoading: false,
          });
        }
      } else {
        this.setState({
          assignedResult: "Usuario no existe.",
          assignedLoading: false,
        });
      }
    } else {
      alert("Ingrese usuario");
      this.setState({
        assignedLoading: false,
      });
    }
  };

  // Payment other
  handleShowOther = () => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showOther: true,
          bank: "Otros",
          isOther: true,
          isBcp: false,
          isInter: false,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };
  handlepromotionalcode = (e) => {
    this.setState({ promotionalCode: e.target.value });
    this.props.onChange(e.target.value, "promotionalCode");
  };
  handleCloseOther = () => {
    this.setState({
      showOther: false,
    });
  };

  // register buy
  registerBuy = () => {
    console.log("pay method");
    if (this.props.registerBuy) {
      this.props.registerBuy();
    }
  };
  handleckclicked = (e) => {
    if (e.target.checked == true) {
      let value = Number(this.state.quotes);

      if (value >= 0 && value <= this.state.quotes) {
        let kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
        let totalQuote = this.state.perQuotes * value;
        let total =
          this.state.initial + totalQuote - this.state.discount + kitCost;

        this.setState({
          total: total,
          totalCuotas: totalQuote,
          isOkQuote: true,
          countQuotes: value,
          valcuotas: value,
        });

        if (this.props.eventPay) {
          this.props.eventPay(value, "quotesPay");
          this.props.eventPay(total, "amountPay");
          this.props.eventPay(true, "ispalcontado");
        }
      }
    } else {
      let value = Number(0);

      //modificando cuotas

      let kitCost = this.state.kitInit.price ? this.state.kitInit.price : 0;
      let totalQuote = this.state.perQuotes * value;
      let total =
        this.state.initial + totalQuote - this.state.discount + kitCost;

      this.setState({
        total: total,
        totalCuotas: totalQuote,
        isOkQuote: true,
        countQuotes: value,
        valcuotas: value,
      });
      this.props.eventPay(value, "quotesPay");
      this.props.eventPay(total, "amountPay");
      this.props.eventPay(false, "ispalcontado");
    }
  };
  render() {
    const {
      vouchers,
      typePay,
      typeExchange,
      isPaid,
      idUser,
      amountWallet,
      checkpalcontado,
      kitInit,
      packages,
      showWalletSection,
      numInitial,
      totalInitial,
      quotes,
      valcuotas,
      totalCuotas,
      isOkQuote,
      discount,
      packagesm,
      sacum,
      total,
      typeBank,
      showWallet,
      showOther,
      showPayPal,
      showVoucher,
      bank,
      isBcp,
      name,
    } = this.state;

    const { packageStatus } = this.props;
    //console.log("haber packages", packages);
    console.log("METODOS DE PAGOS EN TIENDA")
    const nacionalidadUsuario = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
      : null;

    /* console.log(
      "ver nacionalidadUsuario PaymentMethod =>",
      nacionalidadUsuario
    ); */
    return (
      <div>
        {packageStatus === "N" && (
          <div>
            <Form.Label className="content-subtitle">
              Métodos de pagos
            </Form.Label>
            <Form.Group className="mb-3">
              <Row>
                <Col sm={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <td>
                          <Form.Label>Descripción</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Precio Total</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Inicial</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Nro. de cuotas</Form.Label>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {kitInit.length > 0 && (
                        <tr>
                          <td>
                            <Form.Text>{kitInit[0].name} </Form.Text>
                          </td>
                          <td>
                            <Form.Text>
                              $ {kitInit[0].listPackageDetails[0].price}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>0</Form.Text>
                          </td>
                          <td>
                            <Form.Text>0</Form.Text>
                          </td>
                        </tr>
                      )}
                      {packages.length > 0 && (
                        <tr>
                          <td>
                            <Form.Text>{packages[0].description} </Form.Text>
                            <Form.Text>
                              {"Duración: " +
                                packages[0].listPackageDetails[0]
                                  .monthsDuration +
                                " meses"}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>
                              $ {packages[0].listPackageDetails[0].price}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>
                              $ {packages[0].listPackageDetails[0].initialPrice}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>
                              {packages[0].listPackageDetails[0].numberQuotas}
                            </Form.Text>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Form.Group>

            {numInitial > 1 && (
              <Form.Group className="mb-3">
                {checkpalcontado === false ? (
                  <Form.Group as={Row}>
                    <Form.Label
                      className="sizetext"
                      column
                      xs={9}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      Fraccionar inicial en (max.{numInitial}):
                    </Form.Label>
                    <Col xs={3} sm={2} md={2} lg={1}>
                      <Form.Select
                        size="sm"
                        value={totalInitial}
                        onChange={(e) => this.handleChangeInitial(e)}
                      >
                        {this.state.arraynuminitial.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Label className="sizetext">
                    Fraccionar inicial en (max. {numInitial}) : &nbsp;{" "}
                    <small>{totalInitial}</small>
                  </Form.Label>
                )}
              </Form.Group>
            )}

            <div className="justify-content-between">
              <Row>
                <Col sm={5} md={5} lg={6}></Col>
                <Col sm={7} md={7} lg={6}>
                  {packages.length > 0 &&
                  packages[0].name.includes("Semilla") ? (
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Pago al contado"
                          onClick={(e) => this.handleckclicked(e)}
                          value={true}
                          checked={true}
                          className="sizecheckpcontado"
                        />
                        <br></br>
                      </Col>
                    </Row>
                  ) : (
                    <div>
                      {this.state.totalInitial > 1 ? (
                        <div></div>
                      ) : (
                        <Row>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Pago al contado"
                              onClick={(e) => this.handleckclicked(e)}
                              className="sizecheckpcontado"
                            />
                            <br></br>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}

                  {/* AQUI LA CONDICIONAL DE SEMILLA */}
                  {packages.length > 0 &&
                  packages[0].name.includes("Semilla") ? (
                    <span></span>
                  ) : (
                    <>
                      <Row>
                        <Col>
                          <Form.Label>Detalle</Form.Label>
                        </Col>
                      </Row>
                      {/* Kit de mantenimiento */}
                      {kitInit.length > 0 && (
                        <Row>
                          <Col sm="8">
                            <Form inline>
                              <Form.Text className="sizetext">
                                Kit : &nbsp;{" "}
                              </Form.Text>
                            </Form>
                          </Col>

                          <Col sm="4" className="aligntextpayment">
                            <Form.Text className="sizetext">
                              $ {kitInit[0].listPackageDetails[0].price}
                            </Form.Text>
                          </Col>
                        </Row>
                      )}
                      {/* Datos del paquete */}
                      {packages.length > 0 && (
                        <div>
                          <Row>
                            <Col xs="8" sm="8" md="6" lg="8">
                              <Form inline>
                                <Form.Text className="sizetext">
                                  Inicial : &nbsp;{" "}
                                </Form.Text>
                              </Form>
                            </Col>

                            <Col
                              xs="4"
                              sm="4"
                              md="6"
                              lg="4"
                              className="aligntextpayment"
                            >
                              <Form.Text className="sizetext">
                                $ {this.state.initialToPay}
                              </Form.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="9" sm="10" md="8" lg="8">
                              <Form.Text className="sizetext">
                                Total de cuotas a pagar : &nbsp;{" "}
                                {totalInitial == 1 &&
                                checkpalcontado == false ? (
                                  <Form.Control
                                    id="txtQuotes"
                                    size="sm"
                                    type="number"
                                    min="0"
                                    max={quotes}
                                    defaultValue="0"
                                    onChange={(e) => this.handleChange(e)}
                                  ></Form.Control>
                                ) : (
                                  <Form.Text className="sizetext">
                                    {valcuotas}
                                  </Form.Text>
                                )}
                              </Form.Text>
                            </Col>

                            <Col
                              xs="3"
                              sm="2"
                              md="4"
                              lg="4"
                              className="aligntextpayment"
                            >
                              <Form.Text className="sizetext" id="totalCuota">
                                $ {totalCuotas}
                              </Form.Text>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              display: isOkQuote ? "none" : "block",
                            }}
                          >
                            <Col>
                              <Form.Text className="textAlert">
                                El número máximo de cuotas a pagar es : {quotes}
                              </Form.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="8" sm="8" md="6" lg="8">
                              <Form.Text className="sizetext">
                                Descuento : &nbsp;{" "}
                              </Form.Text>
                            </Col>
                            <Col
                              xs="4"
                              sm="4"
                              md="6"
                              lg="4"
                              className="aligntextpayment"
                            >
                              <Form.Text className="sizetext" id="idDiscount">
                                $ {discount}
                              </Form.Text>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}

                  <hr></hr>
                  <Row>
                    <Col xs="8" sm="8" md="6" lg="8">
                      <Form.Label>Total: </Form.Label>
                    </Col>

                    <Col
                      xs="4"
                      sm="4"
                      md="6"
                      lg="4"
                      className="aligntextpayment"
                    >
                      <Form.Label id="total">$ {total}</Form.Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <Form.Group>
              {/*   METODOS DE PAGO EN TIENDA IMAGENES*/}
              <Row className="paddediconpaymentmethod">

              {(() => {
              switch (nacionalidadUsuario) {
                case 167:
                  return (
                    <>
                    <Col sm={2}>
                      <Image
                        src={logo}
                        rounded
                        style={{
                          width: "100%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={this.onEventPayPal}
                      ></Image>
                    </Col>
                    <Col sm={2}>
                      <Image
                        src={logoBcp}
                        rounded
                        style={{
                          width: "100%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.handleShowVoucher(e, "BCP");
                        }}
                      ></Image>
                    </Col>
                    <Col sm={2}>
                      <Image
                        src={logoInter}
                        rounded
                        style={{
                          width: "120%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.handleShowVoucher(e, "Interbank");
                        }}
                      ></Image>
                    </Col>
                    <Col sm={2}>
                      <Image
                        src={logoOther}
                        rounded
                        style={{
                          width: "120%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.handleShowOther(e, "Otros");
                        }}
                      ></Image>
                    </Col>

                    {this.state.showWalletSection && (
                      <Col sm={2}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    )}
                  </>
                  );
                case 45:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={DaVivienda}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Davivienda");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      {this.state.showWalletSection && (
                        <Col sm={2}>
                          <div
                            onClick={(e) => {
                              this.handleShowWallet(e);
                            }}
                          >
                            <p style={{ margin: 2, fontWeight: "bold" }}>
                              <Image
                                src={walletImge}
                                style={{ width: "32px", marginRight: "10px" }}
                              ></Image>
                              Mi Wallet
                            </p>
                          </div>
                        </Col>
                      )}
                    </>
                   
                  );
                default:
                  return (
                    <>
                    <Col sm={2}>
                      <Image
                        src={logo}
                        rounded
                        style={{
                          width: "100%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={this.onEventPayPal}
                      ></Image>
                    </Col>

                    <Col sm={2}>
                      <Image
                        src={logoOther}
                        rounded
                        style={{
                          width: "120%",
                          maxWidth: 136,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          this.handleShowOther(e, "Otros");
                        }}
                      ></Image>
                    </Col>
                    <Col sm={3}>
                      <div
                        onClick={(e) => {
                          this.handleShowWallet(e);
                        }}
                      >
                        <p style={{ margin: 2, fontWeight: "bold" }}>
                          <Image
                            src={walletImge}
                            style={{ width: "32px", marginRight: "10px" }}
                          ></Image>
                          Mi Wallet
                        </p>
                      </div>
                    </Col>
                  </>
                  );
              }
            })()}

              </Row>
              <Row
                style={
                  typePay === 1
                    ? { padding: 16 }
                    : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                }
              >
                <Col sm={12}>
                  <Form.Label>
                    (*) Puede adjuntar comprobantes de pago de BCP, Intebank y
                    Otros.
                  </Form.Label>
                </Col>
              </Row>
              {/* show the table if has register */}
              {vouchers.length > 0 && (
                <div className="mb-3">
                  <Table responsive>
                    <thead className="table-head">
                      <tr>
                        <th>Banco</th>
                        <th>Operación</th>
                        <th>Código</th>
                        <th>Nota</th>
                        <th>Archivo</th>
                        <th>Moneda</th>
                        <th>Monto</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {vouchers.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.bank}</td>
                          <td>{item.typeDescription}</td>
                          <td>{item.code}</td>
                          <td>{item.nota}</td>
                          <td>{item.name}</td>
                          <td>{item.typeExchangeDes}</td>
                          <td>{item.amount}</td>
                          <td
                            style={{ fontSize: 15 }}
                            onClick={(e) => {
                              this.deleteVoucher(e, idx);
                            }}
                          >
                            <RiDeleteBinLine></RiDeleteBinLine>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {/* Show amount payed with wallet */}
              {amountWallet > 0 && (
                <Row
                  style={{ padding: 16, paddingRight: 16, paddingBottom: 10 }}
                >
                  <Col sm={12}>
                    <Alert variant="success">
                      <b>Monto pagado con Wallet $ : </b>
                      {amountWallet}
                    </Alert>
                  </Col>
                </Row>
              )}
              {/* show the PayPal payment if it was successful */}

              {isPaid && (
                <Form.Group>
                  <Form.Label>
                    Su pago ha sido registrado con PAYPAL.
                  </Form.Label>
                </Form.Group>
              )}
            </Form.Group>
            <hr></hr>
          </div>
        )}
        {packageStatus === "U" && (
          <>
            <Form.Label className="content-subtitle">
              Métodos de pagos
            </Form.Label>
            <Form.Group>
              <Row>
                <Col sm={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <td>
                          <Form.Label>Descripción</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Precio Total</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Inicial</Form.Label>
                        </td>
                        <td>
                          <Form.Label>Nro. de cuotas</Form.Label>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {packagesm.length > 0 && (
                        <tr>
                          <td>
                            <Form.Text>
                              {packagesm[0].package.description}{" "}
                            </Form.Text>
                            <Form.Text>
                              {"Duración: " +
                                packagesm[0].monthsDuration +
                                " meses"}
                            </Form.Text>
                          </td>
                          <td>
                            <Form.Text>$ {packagesm[0].price}</Form.Text>
                          </td>
                          <td>
                            <Form.Text>$ {packagesm[0].initialPrice}</Form.Text>
                          </td>
                          <td>
                            <Form.Text>{packagesm[0].numberQuotas}</Form.Text>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Form.Label column sm="4" className="sizetext">
                  Saldo Acumulado: $ <small>{sacum}</small>
                </Form.Label>
              </Row>
            </Form.Group>
            {this.state.arraynuminitial.length > 1 && (
              <>
                <Form.Group as={Row}>
                  <Form.Label
                    className="sizetext"
                    column
                    xs={9}
                    sm={4}
                    md={3}
                    lg={2}
                  >
                    Fraccionar inicial en (max.{" "}
                    {this.state.arraynuminitial.length}) : &nbsp;{" "}
                  </Form.Label>
                  <Col xs={3} sm={2} md={2} lg={1}>
                    {/*    {console.log(
                      "aver arraynuminitial",
                      this.state.arraynuminitial,
                      "length",
                      this.state.arraynuminitial.length
                    )}
 */}
                    <Form.Select
                      size="sm"
                      value={totalInitial}
                      onChange={(e) => this.handleChangeInitialMig(e)}
                    >
                      {this.state.arraynuminitial.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </>
            )}

            <div className="justify-content-between">
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col>
                      <Form.Label>Detalle </Form.Label>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="8" sm="8" md="6" lg="8">
                      <Form inline>
                        <Form.Text className="sizetext">
                          Derecho a migración : &nbsp;{" "}
                        </Form.Text>
                      </Form>
                    </Col>

                    <Col
                      xs="4"
                      sm="4"
                      md="6"
                      lg="4"
                      className="aligntextpayment"
                    >
                      <Form.Text className="sizetext" id="totalCuota">
                        $ {total}
                      </Form.Text>
                    </Col>
                  </Row>

                  <hr></hr>
                  <Row>
                    <Col xs="8" sm="8" md="6" lg="8">
                      <Form.Label>Total: </Form.Label>
                    </Col>

                    <Col
                      xs="4"
                      sm="4"
                      md="6"
                      lg="4"
                      className="aligntextpayment"
                    >
                      <Form.Label id="total">$ {total}</Form.Label>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Form.Check type="checkbox" label="Mantener Cronograma" />
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={this.handleShowModalCupon}
                  >
                    Cupon
                  </Button>
                  <Button variant="warning ms-2" size="sm">
                    Periodo de gracia
                  </Button>

                  <ModalContent
                    title="Usar perido de gracia"
                    isOpen={this.state.isOpen}
                    handleClose={this.handleCloseModalCupon}
                  >
                    <div
                      className="
                       tw-flex 
                       tw-align-center
                       tw-justify-center
                       tw-flex-col
                       tw-p-4
                       tw-items-center
                       tw-text-center
                       "
                    >
                      <Form inline>
                        <div>
                          <Form.Group className="mb-3">
                            <Form.Label className="text-start">
                              Aplicar Cupon:
                            </Form.Label>
                            <Form.Control
                              //id="txtDays"
                              type="text"
                              min="1"
                              // max={this.state.numQuotes}
                              // value={this.state.days}
                              className="w-100"
                              //  onChange={(e) => this.handleChangeDays(e)}
                              //style={{
                              //width: "100px",
                              //margin: "0px auto",
                              //}}
                            ></Form.Control>
                          </Form.Group>
                        </div>

                        <Button
                          size="sm"
                          variant="primary"
                          onClick={(e) => this.useGracePeriod(e)}
                          style={{ marginTop: "10px" }}
                        >
                          <b>Aplicar</b>
                        </Button>
                      </Form>
                    </div>
                  </ModalContent>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <Form.Group>
              {/* Tipos de pago */}
             
              <Row className="paddediconpaymentmethod">
                <Col sm={2}>
                  <Image
                    src={logo}
                    rounded
                    className="csspaymentmethod"
                    onClick={this.onEventPayPal}
                  ></Image>
                </Col>
                <Col sm={2}>
                  <Image
                    src={logoBcp}
                    rounded
                    className="csspaymentmethod"
                    onClick={(e) => {
                      this.handleShowVoucher(e, "BCP");
                    }}
                  ></Image>
                </Col>
                <Col sm={2}>
                  <Image
                    src={logoInter}
                    rounded
                    className="csspaymentmethod"
                    onClick={(e) => {
                      this.handleShowVoucher(e, "Interbank");
                    }}
                  ></Image>
                </Col>
                <Col sm={2}>
                  <Image
                    src={logoOther}
                    rounded
                    className="csspaymentmethod"
                    onClick={(e) => {
                      this.handleShowOther();
                    }}
                  ></Image>
                </Col>
                {showWalletSection && (
                  <Col sm={2}>
                    <div
                      onClick={(e) => {
                        this.handleShowWallet(e);
                      }}
                    >
                      <p style={{ margin: 2, fontWeight: "bold" }}>
                        <Image
                          src={walletImge}
                          style={{ width: "32px", marginRight: "10px" }}
                        ></Image>
                        Mi Wallet
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
              <Row
                style={
                  typePay === 1
                    ? { padding: 16 }
                    : { padding: 16, pointerEvents: "none", opacity: 0.5 }
                }
              >
                <Col sm={12}>
                  <Form.Label>
                    (*) Puede adjuntar comprobantes de pago de BCP, Intebank y
                    Otros.
                  </Form.Label>
                </Col>
              </Row>
              {/* show the table if has register */}
              {vouchers.length > 0 && (
                <div className="mb-3">
                  <Table responsive>
                    <thead className="table-head">
                      <tr>
                        <th>Banco</th>
                        <th>Operación</th>
                        <th>Código</th>
                        <th>Nota</th>
                        <th>Archivo</th>
                        <th>Moneda</th>
                        <th>Monto</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {vouchers.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.bank}</td>
                          <td>{item.typeDescription}</td>
                          <td>{item.code}</td>
                          <td>{item.nota}</td>
                          <td>{item.name}</td>
                          <td>{item.typeExchangeDes}</td>
                          <td>{item.amount}</td>
                          <td
                            style={{ fontSize: 15 }}
                            onClick={(e) => {
                              this.deleteVoucher(e, idx);
                            }}
                          >
                            <RiDeleteBinLine></RiDeleteBinLine>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {/* Show amount payed with wallet */}
              {amountWallet > 0 && (
                <Row
                  style={{ padding: 16, paddingRight: 16, paddingBottom: 10 }}
                >
                  <Col sm={12}>
                    <Alert variant="success">
                      <b>Monto pagado con Wallet $ : </b>
                      {amountWallet}
                    </Alert>
                  </Col>
                </Row>
              )}
              {/* show the PayPal payment if it was successful */}

              {isPaid && (
                <Form.Group>
                  <Form.Label>
                    Su pago ha sido registrado con PAYPAL.
                  </Form.Label>
                </Form.Group>
              )}
            </Form.Group>
            <hr></hr>
          </>
        )}

        {/* Modal */}
        <Modal show={showPayPal} onHide={this.handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={total}
                description={name}
                eventPay={this.eventPay}
                typeBank={typeBank}
                exchange={typeExchange.venta}
                close={this.handleClose}
                registerBuy={this.registerBuy}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal upload voucher*/}
        <Modal
          show={showVoucher}
          onHide={this.handleCloseVoucher}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago en efectivo a través de {bank}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isBcp && (
              <Form.Group>
                <PayVoucher
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                  setCommission={this.setCommission}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            {this.state.isDavi && (
              <Form.Group>
                <PayVoucherDaVivienda
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                ></PayVoucherDaVivienda>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
         
            {this.state.isInter && (
              <Form.Group>
                <PayVoucherInter
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  setCommission={this.setCommission}
                ></PayVoucherInter>
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>

        {/* Modal pay with wallet*/}
        <Modal
          show={showWallet}
          onHide={this.handleCloseWallet}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={idUser}
                total={total}
                registerPayWallet={this.registerPayWallet}
                eventWallet={this.eventWallet}
                close={this.handleCloseWallet}
                fontSize={10}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>

        {/* Modal upload voucher other tickets*/}
        <Modal
          show={showOther}
          onHide={this.handleCloseOther}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={total}
                addVoucher={this.addVoucher}
                close={this.handleCloseOther}
                typeBank={typeBank}
                exchange={typeExchange.venta}
                fontSize={10}
                nacionalidadUsuario={nacionalidadUsuario}
                setCommission={this.setCommission}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
