import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import checkIcon from "assets/svg/check.png";

Font.register({
  family: "Calibri",
  fonts: [
    { src: "/pdf/fonts/Calibri Bold.TTF", fontWeight: 700 },

    {
      src: "/pdf/fonts/Calibri Regular.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Calibri",
  },
  body: {
    fontSize: 10.5,
    fontFamily: "Calibri",
    paddingTop: 10,
    paddingHorizontal: 85,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
    marginLeft: -10,
  },
  imgHeader: {
    maxWidth: "180px",
    // opacity: 0.5,
  },
  title: {
    fontSize: 11.5,
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
    width: "80%",
    margin: "0 auto",
  },
  subtitle: {
    fontFamily: "Calibri",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
  },
  titleList: {
    textAlign: "justify",
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 2,
  },
  image: {
    width: "110%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
  table: {
    display: "flex",
    width: "120%",
    borderStyle: "solid",
    // borderWidth: 1,
    borderColor: "grey",
    flexWrap: "wrap",
    marginVertical: 10,
    textAlign: "center",
    justifyContent: "center",
    marginLeft: -40,
  },
  tableRow: {
    margin: "0",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "grey",
    textDecoration: "underline",
    textAlign: "center",
  },
  tableCol: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "grey",
    textAlign: "center",
    margin: 0,
  },
  tableCellHeader: {
    margin: "0",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 8.5,
    textAlign: "center",
  },
  tableCell: {
    margin: "0",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 8,
    textAlign: "center",
  },
  footer: {
    fontSize: 10,
    textAlign: "right",
    position: "absolute",
    bottom: 40,
    right: 85,
  },
});

const fechaYear = (fecha) => {
  let fechaArray = fecha.split("/");

  return fechaArray[2];
};

function InresortsPDFNewTwo(props) {
  console.log("props InresortsPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;

  membershipmaintenanceletter = membershipmaintenanceletter
    .replace("DOLARES", "")
    .replace("M.N.", " ")
    .replace("USD", "dólares")
    .trim();

  membershipmaintenanceletter = membershipmaintenanceletter.toLowerCase();
  membershipmaintenanceletter =
    membershipmaintenanceletter.charAt(0).toUpperCase() +
    membershipmaintenanceletter.slice(1);

  let pricetotalLetter = props.dataPDF.pricetotalLetter;

  pricetotalLetter = pricetotalLetter
    .replace("DOLARES", "")
    .replace("M.N.", "")
    .replace("USD", "dólares")
    .trim();

  pricetotalLetter = pricetotalLetter.toLowerCase();
  pricetotalLetter =
    pricetotalLetter.charAt(0).toUpperCase() + pricetotalLetter.slice(1);

  let numberSharesletter = props.dataPDF.numberSharesLetters;

  numberSharesletter = numberSharesletter
    .replace("DOLARES CON 00/100 USD", "")
    .trim();

  numberSharesletter = numberSharesletter.toLowerCase();
  numberSharesletter =
    numberSharesletter.charAt(0).toUpperCase() + numberSharesletter.slice(1);

  const obtenerNombreMes = (mesNumero) => {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    return meses[mesNumero - 1];
  };
  const fecha = props.dataPDF.creationDate.split("/");
  return (
    <Document>
      <Page wrap={true} size="A4">
        {/*1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "45px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "60px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CONTRATO DE ASOCIACIÓN EN PARTICIPACIÓN PARA EL DESARROLLO DE
              NEGOCIOS N° {props.dataPDF.user.username.toUpperCase()}-
              {props.dataPDF.codeComany}
              {props.dataPDF.countSuscriptionByFamily}-
              {props.dataPDF.typeShares}-{fechaYear(props.dataPDF.creationDate)}
            </Text>

            <Text style={styles.text}>
              Conste por el presente documento, el Contrato de Asociación en
              Participación para el Desarrollo de Negocios, que celebran de una
              parte{" "}
              <Text style={{ fontWeight: "600" }}>VALLE ENCANTADO S.A.C.</Text>,
              identificado con RUC N° 20601460271, constituida y regida bajo las
              leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia y
              Departamento de Lima, debidamente representada por el Sr. Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "600" }}>“VALLE ENCANTADO”</Text>; y de
              otra parte el(la) Sr(a). {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
              {props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL ASOCIADO”</Text>; en los
              términos y condiciones contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERA: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO, es una empresa encargada de
                  gestionar y desarrollar el proyecto inmobiliario
                </Text>
                <Text
                  style={{ textAlign: "justify", marginLeft: 18, width: "95%" }}
                >
                  {" "}
                  de carácter recreacional y hotelero de RIBERA DEL RIO CLUB
                  RESORT S.A., esta última identificada con RUC N° 20608720911,
                  debidamente inscrita en la Partida Electrónica N° 14809461 del
                  Registro de Personas Jurídicas de Registro de Personas
                  Jurídicas de Lima y ubicada en Mz. {"<<B>>"} Lote 72, Tercera
                  Etapa Cieneguilla, Provincia y Departamento de Lima. Por lo
                  que, VALLE ENCANTADO requiere contar con recursos y EL
                  ASOCIADO tiene interés en participar en el negocio conforme a
                  las condiciones establecidas en este instrumento.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}EL ASOCIADO es una persona natural que, en calidad de
                  asociado-inversionista, manifiesta, por
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  el presente acto, su voluntad expresa de participar en el
                  proyecto inmobiliario desarrollado por VALLE ENCANTADO en los
                  términos establecidos en este documento. Asimismo, declara
                  haber recibido la presentación del proyecto y con base en
                  ello, y luego de las consultas del caso, ha decidido invertir
                  en el desarrollo del mismo.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDA: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.1. Por el presente contrato, las partes acuerdan participar
                  en el negocio descrito en la cláusula
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  siguiente bajo la modalidad de asociación en participación.
                  VALLE ENCANTADO ha puesto a disposición de EL ASOCIADO,{" "}
                  {`${props.dataPDF.numberShares.toLocaleString({
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}{" "}
                  ({numberSharesletter}) acciones de inversión Clase{" "}
                  {props.dataPDF.typeShares} de RIBERA DEL RIO CLUB RESORT S.A,
                  por el Paquete de acciones de inversión:{" "}
                  {props.dataPDF.userPackage.toUpperCase()} conforme al
                  siguiente detalle:{" "}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  paddingBottom: 20,
                  paddingTop: 10,
                  margin: "auto",
                  flexWrap: "wrap",
                }}
              >
                {props.dataPDF.packagesavailable.map((e) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          minWidth: 50,
                          maxWidth: 200,
                          marginRight: 10
                        }}
                      >
                        <Text
                          style={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {e.package.name}
                        </Text>
                        {/* <Text>{e.numberShares} acc</Text> */}
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          minWidth: 50,
                          maxWidth: 200,
                          marginRight: 10
                        }}
                      >
                        <Text
                          style={{
                            textDecoration: "underline",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {e.package.name}
                        </Text>
                        {/* <Text>{e.numberShares} acc</Text> */}
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>
              <Text style={[styles.text, { marginLeft: 20 }]}>
                Estas acciones de inversión son Acciones Preferentes de segundo
                orden sin derecho a voto y con derecho a usufructo, referente a
                la facultad de disfrutar los espacios y recibir beneficios en
                los servicios de RIBERA DEL RIO CLUB RESORT S.A, con sujeción al
                “Programa de Beneficios”, y al “Código de Ética y Conducta” -
                Anexo 1 que es parte integral del presente contrato.
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>1</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.2. Por su parte, EL ASOCIADO se obliga a transferir a VALLE
                  ENCANTADO como capital de negocio,
                </Text>
                <Text style={[styles.text, { marginLeft: 20 }]}>
                  la suma pactada en la cláusula tercera, en la forma y
                  oportunidad convenidas.
                </Text>
              </View>
              <Text style={styles.subtitle}>
                TERCERA: PRECIO Y FORMA DE PAGO
              </Text>
              {(() => {
                const packageWords = props.dataPDF.userPackage.split(" ")
                  .length;
                if (packageWords === 1) {
                  return (
                    <View style={styles.text}>
                      <Text style={{ textAlign: "justify" }}>
                        3.1.{"  "}El capital de negocio por el Paquete de
                        acciones de inversión{" "}
                        {props.dataPDF.userPackage.toUpperCase()}, asciende a la
                        suma de
                      </Text>
                      <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                        {`US$${props.dataPDF.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}{" "}
                        ({pricetotalLetter}), el cual le otorga a EL ASOCIADO el
                        número de acciones y beneficios mencionados en el
                        numeral 2.1 del presente contrato.
                      </Text>
                    </View>
                  );
                } else if (packageWords === 2) {
                  return (
                    <View style={styles.text}>
                      <Text style={{ textAlign: "justify" }}>
                        3.1.{"  "}El capital de negocio por el Paquete de
                        acciones de inversión{" "}
                        {props.dataPDF.userPackage.toUpperCase()}, asciende a la
                      </Text>
                      <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                        suma de{" "}
                        {`US$$${props.dataPDF.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        , ({pricetotalLetter}), el cual le otorga a EL ASOCIADO
                        el número de acciones y beneficios mencionados en el
                        numeral 2.1 del presente contrato.
                      </Text>
                    </View>
                  );
                } else if (packageWords >= 3) {
                  return (
                    <View style={styles.text}>
                      <Text style={{ textAlign: "justify" }}>
                        3.1.{"  "}El capital de negocio por el Paquete de
                        acciones de inversión{" "}
                        {props.dataPDF.userPackage.toUpperCase()},
                      </Text>
                      <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                        asciende a la suma de{" "}
                        {`US$${props.dataPDF.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        , ({pricetotalLetter}), el cual le otorga a EL ASOCIADO
                        el número de acciones y beneficios mencionados en el
                        numeral 2.1 del presente contrato.
                      </Text>
                    </View>
                  );
                }
              })()}
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.2.{"  "}Las partes acuerdan que el abono del capital de
                  negocio, se efectuará en dólares de los Estados
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  Unidos de América, al amparo de lo dispuesto por el Artículo
                  1237 del Código Civil.
                </Text>
              </View>
              {props.dataPDF.isAllScheduleActive ? (
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    3.3.{"  "}El capital de negocio será abonado por EL ASOCIADO
                    en su totalidad, en la fecha de la suscripción
                  </Text>
                  <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                    del presente contrato.
                  </Text>
                </View>
              ) : (
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    3.3.{"  "}El capital de negocio será abonado por EL
                    ASOCIADO, en el plazo de {props.dataPDF.numberQuotas} meses,
                    mediante la
                  </Text>
                  <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                    cancelación de una cuota inicial de{" "}
                    {`US$ ${props.dataPDF.initialPrice.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}{" "}
                    y {props.dataPDF.numberQuotas} cuotas mensuales consecutivas
                    de{" "}
                    {`US$ ${props.dataPDF.quotaPrice.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}{" "}
                    cada una. La cuota inicial se abonará en la fecha de la
                    suscripción del contrato, mientras que las cuotas
                    subsiguientes se abonarán en la misma fecha del mes
                    siguiente correspondientemente, de acuerdo al Cronograma de
                    Pago de Inversión constado en el Anexo 2 del presente
                    documento.
                  </Text>
                </View>
              )}

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.4.{"  "}La cuota inicial y las cuotas mensuales
                  correspondientes, podrán ser canceladas en las siguien-
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  tes cuentas bancarias de titularidad de VALLE ENCANTADO, a
                  elección de EL ASOCIADO:
                </Text>
              </View>
              <Image
                style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
                src="/pdf/inresorts/precios.png"
              />
              <Text style={[styles.text, { marginLeft: 20 }]}>
                En caso de cambio o incrementos de cuentas bancarias, VALLE
                ENCANTADO informará oportunamente a través del correo registrado
                de EL ASOCIADO dentro de los primeros 7 (siete) días del cambio.
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.5.{"  "}EL ASOCIADO podrá abonar por adelantado una o más
                  cuotas mensuales no vencidas; sin embar-
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  go no se admitirá en ningún caso el pago parcial de estas
                  cuotas.
                </Text>
              </View>
              <Text style={styles.subtitle}>
                CUARTA: RÉGIMEN DE RENDIMIENTO Y RETORNO DE LA INVERSIÓN
              </Text>
              <Text style={styles.text}>
                Por su parte, en contraprestación, VALLE ENCANTADO se obliga a
                retribuir a EL ASOCIADO con Acciones Clase{" "}
                {props.dataPDF.typeShares} (Acciones Preferentes de segundo
                orden sin derecho a voto y con derecho a usufructo), contando
                con los siguientes beneficios:
              </Text>
              <Text style={[styles.titleList, { fontWeight: "semibold" }]}>
                - Acciones:
              </Text>

              <View style={styles.text}>
                <Text style={styles.list}>
                  - En cuanto EL ASOCIADO concluya con el abono de la totalidad
                  de las cuotas pactadas, del Crono-
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  grama de Pagos de Inversión - Anexo 2 que es parte integral
                  del presente contrato, tendrá derecho a recibir{" "}
                  {`${props.dataPDF.numberShares.toLocaleString({
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}{" "}
                  ({numberSharesletter}) acciones Clase{" "}
                  {props.dataPDF.typeShares}, libre de gravámenes, cargas y
                  cualquier obligación que limite o restrinja su dominio o libre
                  disposición, las cuales tienen derecho a la participación de
                  las utilidades de la empresa cuando estas se produzcan en
                  proporción del número de sus acciones de inversión que en
                  mérito al presente contrato se encuentra
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>2</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*3 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={{ marginLeft: 25 }}>
                  representando en su certificado de acciones obtenido.
                  Asimismo, como parte del presente contrato EL ASOCIADO por la
                  representación de las acciones tendrá derecho al paquete de
                  beneficios que dichas acciones representan de acuerdo al monto
                  de la inversión realizada por este, que se encuentran
                  detallados en el Programa de Beneficios. Queda entendido que
                  EL ASOCIADO participa de los resultados, tanto de las
                  utilidades como de las pérdidas de RIBERA DEL RIO CLUB RESORT
                  S.A., así como queda entendido que los beneficios mencionados
                  en esta cláusula son de carácter perpetuo, conforme a su
                  Programa de Beneficios.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={styles.list}>
                  - El cumplimiento del 100% de la inversión, hará acreedor a EL
                  ASOCIADO de un Certificado de
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  Acciones Clase {props.dataPDF.typeShares}, de acuerdo al
                  Paquete de acciones elegido por EL ASOCIADO.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={styles.list}>
                  - La inversión otorga el derecho a EL ASOCIADO a usufructuar
                  el Hotel Club Resort, según su
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  tipo de Programa de Beneficios. El tipo de Programa varía de
                  acuerdo con el número de acciones de inversión seleccionada
                  por EL ASOCIADO.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={styles.list}>
                  - Las acciones Clase {props.dataPDF.typeShares} corresponden
                  ser acciones preferentes de segundo orden sin derecho a
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  voto y con derecho a usufructo, estas confieren a su titular
                  la calidad de socio y le atribuye, entre ellos, los siguientes
                  derechos:
                </Text>

                <Text style={[styles.list, { marginLeft: 8, marginTop: 8 }]}>
                  1. Participar en el reparto de utilidades y del patrimonio
                  neto resultante de la liquidación.
                </Text>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  2. Derecho de usufructuar los espacios y beneficios en los
                  servicios de RIBERA DEL RIO CLUB
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: 5 }}>
                  {" "}
                  RESORT S.A., según el tipo de Programa de Beneficios
                  adquirido.
                </Text>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  3. Posibilidad de participar en el Directorio, a través de un
                  representante elegido por todos
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: 5 }}>
                  los accionistas del Sistema Red.
                </Text>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  4. Ser informado cuando menos semestralmente de las
                  actividades y gestión de RIBERA DEL
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: -5 }}>
                  RIO CLUB RESORT S.A.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ marginLeft: 30 }}>
                  Asimismo, le confiere a EL ASOCIADO los demás derechos
                  establecidos en el Estatuto de RIBERA DEL RIO CLUB RESORT
                  S.A., conforme a la Ley General de Sociedades.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={styles.list}>
                  - La entrega del Certificado de Acciones, el cual tiene como
                  característica ser vitalicio y heredable,
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  se realizará a la fecha del cumplimiento del abono total de la
                  inversión. En el caso del Programa de Beneficios Light en
                  adelante, se irán entregando Certificados Parciales, en
                  función al cumplimiento de los segmentos anuales, salvo
                  expresión escrita formal o electrónica por parte de EL
                  ASOCIADO. Cabe indicar que, el último certificado parcial
                  correspondiente a la duplicación de acciones, será entregado a
                  partir del primer día útil de enero del año 2027. En caso que
                  EL ASOCIADO haya cancelado el 100% del capital de negocio en
                  una fecha anterior a la indicada, VALLE ENCANTADO podrá
                  disponer la posibilidad de hacer la entrega anticipada de
                  dicho certificado bajo ese contexto u otro que considere
                  pertinente. Dichos certificados, serán legalizados
                  Notarialmente.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={styles.list}>
                  - Las acciones son heredables, por lo que, el heredero o
                  legatario se convierte en accionista de
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  la sociedad en aplicación del artículo 660 del Código Civil,
                  en tanto que, la muerte pone fin a la persona, produce la
                  apertura de su sucesión y origina la transmisión de su
                  patrimonio, constituido por activos y pasivos, es decir, por
                  los bienes, derechos y obligaciones.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>3</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*4 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: -10 }]}>
                  - EL ASOCIADO tiene derecho a invertir en nuevas acciones de
                  inversión, teniendo en considera-
                </Text>
                <Text style={{ marginLeft: 25 }}>
                  ción las siguientes condiciones:
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  - El monto del capital de negocio corresponderá al valor de
                  mercado vigente en el momento
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: -5 }}>
                  de la inversión.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 10, marginTop: -5 }]}>
                  - EL ASOCIADO al optar por la inversión en nuevas acciones de
                  inversión, acepta las condicio-
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: -5 }}>
                  nes y normas vigentes a dicha fecha.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  - EL ASOCIADO durante los primeros 12 meses puede ir migrando
                  entre Paquetes, desde la
                </Text>
                <Text style={{ marginLeft: 35, marginBottom: -5 }}>
                  MINI hasta VITALICIA ULTRA PREMIUM, siempre y cuando exista
                  disponibilidad. Para la cual, lo que deberá de abonar es la
                  diferencia del valor entre los Paquetes más la tasa de UPGRADE
                  vigente en el momento.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 10 }]}>
                  - El número de Paquete de acciones de inversión será
                  determinado en función a la ocupabili-
                </Text>
                <Text style={{ marginLeft: 35 }}>
                  dad, poniendo el Hotel Club Resort a disposición de sus socios
                  el 25% de su capacidad instalada anual.
                </Text>
              </View>
              <Text
                style={[
                  styles.titleList,
                  { marginTop: 10, fontWeight: "semibold" },
                ]}
              >
                - Usufructo: (Programa de beneficios):
              </Text>
              <View style={styles.text}>
                <Text style={styles.list}>
                  • El derecho de usufructo de EL ASOCIADO se aplica conforme al
                  siguiente detalle:
                </Text>
                <Text
                  style={[
                    styles.list,
                    { marginLeft: 8, marginTop: 10, fontWeight: "semibold" },
                  ]}
                >
                  - El Plan {props.dataPDF.userPackage} incluye para el socio y
                  sus beneficiarios: {props.dataPDF.beneficiaries}, disfrutar
                  ilimitadamente nuestras instalaciones los 365 días del año,
                  desde la inauguración de la primera etapa del Hotel Club
                  Resort.
                </Text>
                <View style={[styles.text, { paddingLeft: 20, paddingTop: 8 }]}>
                  <Text style={styles.list}>
                    - <Text style={{ fontWeight: "semibold" }}>Piscinas:</Text>{" "}
                    1 piscina bebes, 1 para niños de 6 a 12 años y 1 para
                    adultos
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Áreas deportivas:
                    </Text>{" "}
                    2 canchitas de fulbito y 2 de vóley multiusos.
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Área de Juegos Infantiles:
                    </Text>{" "}
                    Inflables, camas elásticas, resbaladeras, columpios,
                    laberintos, muros de escalar, entre otros.
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Sala de Juegos:
                    </Text>{" "}
                    Fulbito de mano, tenis de mesa, billas, tiro al sapo y más.
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Salones de Recepciones:
                    </Text>{" "}
                    4 salones de recepciones para eventos con capacidades que
                    van desde 80 personas hasta 450 personas.
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Restaurantes:
                    </Text>{" "}
                    1 Buffet y 1 A la Carta.
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Bar y Karaoke.
                    </Text>
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Spa, Sauna y Gimnasio
                    </Text>
                  </Text>
                  <Text style={styles.list}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Áreas Verdes y Áreas de Reposo:
                    </Text>{" "}
                    Más de 2,500 m2 de área verde
                  </Text>
                  <Text style={[styles.list, { marginBottom: -8 }]}>
                    -{" "}
                    <Text style={{ fontWeight: "semibold" }}>
                      Estacionamiento Privado:
                    </Text>{" "}
                    Amplias playas de estacionamiento con capacidad para más de
                    200 vehículos.
                  </Text>
                </View>
                <Text style={[styles.list, { marginLeft: 8, marginTop: 10 }]}>
                  -{" "}
                  <Text
                    style={{
                      textDecoration: "underline",
                      fontWeight: "semibold",
                    }}
                  >
                    Invitados
                  </Text>
                  : {props.dataPDF.numberGuests} Pases libres para invitados por
                  mes (acumulable x2, 2 veces por año en día de
                </Text>

                <Text style={{ marginLeft: 35 }}>
                  {" "}
                  semana - No aplica para feriado, ni feriado largo). Los
                  invitados adicionales deberán pagar por su ingreso según
                  tarifario vigente. En el caso de eventos y reservas, la
                  cantidad de invitados puede variar de acuerdo a las
                  condiciones convenidas con EL ASOCIADO.
                </Text>

                <Text style={[styles.list, { marginLeft: 8, marginTop: 10 }]}>
                  -{" "}
                  <Text
                    style={{
                      textDecoration: "underline",
                      fontWeight: "semibold",
                    }}
                  >
                    Alojamiento incluido
                  </Text>{" "}
                  <Text style={{ fontWeight: "semibold" }}>
                    (No aplica para feriado, ni feriado largo):
                  </Text>{" "}
                  Según el Paquete de acciones
                </Text>
                <Text style={{ marginLeft: 35 }}>
                  adquirido, como se muestra en la siguiente tabla:
                </Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Mini</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Experience</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Light</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Standard</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Vitalicia</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Vitalicia Plus</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Vitalicia Premium
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Vitalicia Premium Plus
                      </Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>
                        Vitalicia Ultra Premium
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>160 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>320 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>640 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>960 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>2400 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>2560 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>4800 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>5120 puntos</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>9600 puntos</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>4</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*5 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text
                  style={[
                    styles.list,
                    {
                      paddingLeft: 35,
                      textDecoration: "underline",
                      paddingBottom: 5,
                    },
                  ]}
                >
                  Habitación Estándar Resort:
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Fin de semana: Viernes y Sábado
                  3pm - 12pm.
                </Text>
                <Text style={{ paddingLeft: 53 }}>
                  Equivalente a 30 puntos.
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm -
                  12pm.
                </Text>
                <Text style={{ paddingLeft: 53 }}>
                  Equivalente a 20 puntos.
                </Text>
              </View>
              <View style={styles.text}>
                <Text
                  style={[
                    styles.list,
                    {
                      paddingLeft: 35,
                      textDecoration: "underline",
                      paddingBottom: 5,
                    },
                  ]}
                >
                  Apartamento Estándar Resort:
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Fin de semana: Viernes y Sábado
                  3pm - 12pm.
                </Text>
                <Text style={{ paddingLeft: 53 }}>
                  Equivalente a 60 puntos.
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm -
                  12pm.
                </Text>
                <Text style={{ paddingLeft: 53 }}>Equivalentea 40 puntos.</Text>
              </View>

              <View style={styles.text}>
                <Text
                  style={[
                    styles.list,
                    {
                      paddingLeft: 35,
                      textDecoration: "underline",
                      paddingBottom: 5,
                    },
                  ]}
                >
                  Suite Resort:
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Fin de semana: Viernes y Sábado
                  3pm - 12 pm.
                </Text>
                <Text style={{ paddingLeft: 53 }}>
                  Equivalente a 120 puntos.
                </Text>
                <Text
                  style={[styles.text, { paddingLeft: 45, marginBottom: -8 }]}
                >
                  - 1 noche de alojamiento en Semana: Domingo a Jueves 3pm -
                  12pm.{" "}
                </Text>
                <Text style={{ paddingLeft: 53 }}>
                  Equivalente a 80 puntos.
                </Text>
              </View>
              <Text style={[styles.list, { marginLeft: 8, marginTop: 10 }]}>
                - <Text style={{ fontWeight: "semibold" }}>Descuentos:</Text>{" "}
                Restaurante y Bar: 20%, Servicios Spa, Salas de Cumpleaños
                Infantiles y Salas
              </Text>
              <Text style={{ marginLeft: 34 }}>
                de Recepciones: 25%, Alojamiento Adicional: 30% e Invitados
                Adicionales: 50%. Para otros servicios que adquiera el socio,
                aplicarán los precios según el tarifario vigente.
              </Text>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 8, marginTop: 7 }]}>
                  -{" "}
                  <Text style={{ fontWeight: "semibold" }}>
                    Descuentos vacacionales:
                  </Text>{" "}
                  Los asociados que hayan pagado al 100% su programa Vitalicio,
                </Text>
                <Text style={{ marginLeft: 34 }}>
                  Vitalicio Premium y Vitalicio Ultra Premium, tendrán
                  beneficios vacacionales a través de:
                </Text>
                <View
                  style={[
                    styles.list,
                    {
                      marginLeft: 18,
                      display: "flex",
                      flexDirection: "row",
                      width: "93%",
                      paddingTop: 5,
                    },
                  ]}
                >
                  <Image
                    src={checkIcon}
                    style={{ width: 12, height: 12, marginRight: 3 }}
                  />
                  <Text>
                    <Text style={{ fontWeight: "semibold" }}>
                      Vive Ahora Travel.
                    </Text>{" "}
                    Agencia de Viaje EXCLUSIVA PARA SOCIOS, adquiere todo para
                    tus vacaciones Nacionales e Internacionales: Noches de
                    hotel, Paquetes armados y mucho más... directamente tomados
                    de las Operadoras Turísticas más importantes.
                  </Text>
                </View>
                <View
                  style={[
                    styles.list,
                    {
                      marginLeft: 18,
                      display: "flex",
                      flexDirection: "row",
                      width: "93%",
                      paddingTop: 5,
                    },
                  ]}
                >
                  <Image
                    src={checkIcon}
                    style={{ width: 12, height: 12, marginRight: 3 }}
                  />
                  <Text>
                    <Text style={{ fontWeight: "semibold" }}>RCI:</Text>{" "}
                    Compañía Internacional de intercambio vacacional, la cual le
                    permite al afiliado disfrutar de los siguientes beneficios:
                  </Text>
                </View>
                <Text style={[styles.text, { paddingLeft: 48, paddingTop: 3 }]}>
                  - Semanas de Intercambio Vacacional desde $ 359.00 (Beneficio
                  activo desde Inauguración
                </Text>
                <Text style={[styles.text, { paddingLeft: 53, marginTop: -8 }]}>
                  del Resort) (Temporada Alta / Media / Baja).
                </Text>
                <Text style={[styles.text, { paddingLeft: 48, marginTop: -7 }]}>
                  - Semanas de Escape desde $ 299.00 (Sujeto a cambios que RCI
                  podría realizar en el tiem-
                </Text>
                <Text style={[styles.text, { paddingLeft: 53, marginTop: -8 }]}>
                  po) (Temporada Media / Baja).
                </Text>

                <Text style={[styles.text, { paddingLeft: 48, marginTop: -7 }]}>
                  - Más de 4,500 Desarrollos en más de 110 Países. flexibilidad
                  para viajar y posibidad de
                </Text>
                <Text style={[styles.text, { paddingLeft: 53, marginTop: -8 }]}>
                  traspasar, regalar o ceder beneficios a un tercero.
                </Text>
                <Text style={[styles.text, { paddingLeft: 50, marginTop: -2 }]}>
                  Para participar de este programa se requiere hacer pagos por
                  concepto de Afiliación: $ 99.00 y por mantenimiento anual: $
                  59.00. - Estos conceptos son pagados por EL ASOCIADO. EL
                  ASOCIADO puede decir participar o no en este programa.
                </Text>
              </View>
              <Text style={[styles.list, { marginLeft: 8, marginTop: -10 }]}>
                -{" "}
                <Text style={{ fontWeight: "semibold" }}>
                  Beneficiarios Adicionales:
                </Text>{" "}
                El socio podrá adherir a su membresía hasta 3 beneficiarios
                adicio-
              </Text>
              <Text style={{ paddingLeft: 30 }}>
                nales. Cabe indicar que, estos 3 beneficiarios adicionales
                mencionados en este párrafo, son adicionales a los que su
                programa gratuitamente le brinda. El costo de cada uno de ellos
                será US$ 50 por año.
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>5</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*6*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: 10 }]}>
                  •{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    Durante el Desarrollo de construcción del Hotel
                  </Text>
                  : El socio y sus beneficiarios podrán hacer uso
                </Text>
                <Text style={[styles.text, { paddingLeft: 23 }]}>
                  gratuito de las instalaciones para FULL DAY todos los fines de
                  semana y feriados (bajo reserva). También tendrán descuentos
                  en Restaurante (10%), Servicios (20%) y alquiler de
                  alojamiento (30%). Para otros servicios que adquiera el socio,
                  aplicarán los precios según el tarifario vigente.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: -5 }]}>
                  •{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    Identificación
                  </Text>
                  : A las 48 horas de realizado el pago de la cuota de ingreso,
                  se actualizará en sis-
                </Text>
                <Text style={[styles.text, { paddingLeft: 23 }]}>
                  tema el núcleo familiar de EL ASOCIADO. Los cuales, para poder
                  hacer uso del Programa de beneficios que otorga el Paquete de
                  acciones seleccionado, sólo deberán mostrar su Documento de
                  Identidad (DNI, Carnet de Extranjería o Pasaporte).
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: -5 }]}>
                  •{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    Kit de Bienvenida
                  </Text>
                  : Cada ASOCIADO, después de formalizar su sistema de pago,
                  recibe el Kit en
                </Text>
                <Text style={[styles.text, { paddingLeft: 23 }]}>
                  un plazo de 15 días útiles. El Kit incluye: Carta de
                  Bienvenida donde se consigna su número de socio, Cronograma de
                  Pagos de Inversión (caso hubiese fraccionado), y el Reglamento
                  de conducta y Código de Ética de RIBERA DEL RIO CLUB RESORT
                  S.A. Este Kit de Bienvenida únicamente será entregado en
                  alguna de nuestras oficinas previa coordinación con el titular
                  o vía correo electrónico según prefiera EL ASOCIADO.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: -5 }]}>
                  • <Text style={{ textDecoration: "underline" }}>Cesión</Text>:
                  EL ASOCIADO puede ceder, transferir o donar, parcial o
                  totalmente cualquier beneficio
                </Text>
                <Text style={[styles.text, { paddingLeft: 24 }]}>
                  y/u obligaciones bajo este contrato, con el solo llenado de
                  los formatos correspondientes, cabe señalar que el cesionario
                  de la posición, el receptor de la donación o el comprador
                  deberá reunir los requisitos para poder ser parte de la
                  sociedad. Estos son:
                </Text>
                <View style={{ paddingLeft: 30, marginTop: -5 }}>
                  <Text>- No tener antecedentes penales.</Text>
                  <Text>- No tener acreencias judiciales civiles.</Text>
                </View>
              </View>
              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: -2 }]}>
                  • VALLE ENCANTADO no se hace responsable por el tiempo que EL
                  ASOCIADO no haga uso del
                </Text>
                <Text style={[styles.text, { paddingLeft: 24 }]}>
                  Hotel Club Resort y sus beneficios durante la vigencia del
                  mencionado Programa.
                </Text>
              </View>

              <Text style={styles.subtitle}>
                QUINTA: OBLIGACIONES DE LAS PARTES
              </Text>

              <Text style={styles.text}>
                5.1.VALLE ENCANTADO tiene las siguientes obligaciones:
              </Text>

              <Text
                style={[
                  styles.text,
                  { paddingLeft: 10, fontWeight: "semibold" },
                ]}
              >
                - Acciones
              </Text>
              <View style={styles.text}>
                <Text style={styles.list}>
                  • VALLE ENCANTADO se obliga la gestionar, administrar y
                  realizar el negocio, materia del presen-
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  te contrato, de manera ordenada y adecuada. En tal sentido,
                  VALLE ENCANTADO deberá proceder con la diligencia, prudencia,
                  buena fe y lealtad.
                </Text>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • VALLE ENCANTADO se obliga a otorgar las acciones materia de
                  este contrato a EL ASOCIADO,
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  libres de gravámenes, cargas y cualquier obligación que limite
                  o restrinja su dominio o libre disposición, las cuales tienen
                  derecho a usufructo en beneficio del socio o accionista en el
                  momento establecido en la cláusula cuarta del presente
                  contrato.
                </Text>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • Suscribir todos los documentos o contratos necesarios para
                  perfeccionar el otorgamiento de
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  titularidad de acciones.
                </Text>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • VALLE ENCANTADO se compromete que RIBERA DEL RIO CLUB RESORT
                  S.A. abone las utilidades
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  correspondientes por cada año fiscal a EL ASOCIADO, en caso si
                  hubiere, conforme a los plazos establecidos por Ley para la
                  repartición de utilidades y conforme a los certificados de
                  acciones obtenidos.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>6</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*7*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <Text
                style={[
                  styles.text,
                  { paddingLeft: 10, fontWeight: "semibold" },
                ]}
              >
                - Usufructo:
              </Text>

              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • En virtud del presente contrato, VALLE ENCANTADO deberá
                  suministrar a EL ASOCIADO, la fa-
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  cultad de disfrutar los espacios y recibir beneficios en los
                  servicios de RIBERA DEL RÍO CLUB RESORT S.A., con sujeción al
                  “Programa de Beneficios” y al “Código de Ética y Conducta” que
                  EL ASOCIADO tiene derecho conforme al número de acciones de
                  acuerdo a su inversión y que ambos documentos son parte
                  integral del presente contrato.
                </Text>
              </View>
              <Text style={[styles.text, { marginTop: 5 }]}>
                5.2. EL ASOCIADO tiene las siguientes obligaciones:
              </Text>

              <Text
                style={[
                  styles.text,
                  { paddingLeft: 10, fontWeight: "semibold" },
                ]}
              >
                - Acciones
              </Text>

              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • EL ASOCIADO se obliga a efectuar el abono de las cuotas
                  mensuales en el momento y forma
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  pactados, conforme a lo dispuesto en la cláusula tercera de
                  este documento. En caso contrario, EL ASOCIADO recaerá en
                  incumplimiento según lo dispuesto en la cláusula sétima.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={[styles.list, { marginTop: 5 }]}>
                  • Suscribir los documentos y contratos que sean necesarios
                  para perfeccionar el otorgamiento
                </Text>
                <Text style={{ paddingLeft: 23 }}>
                  de titularidad de las acciones seleccionadas de acuerdo a su
                  inversión.
                </Text>
              </View>

              <Text
                style={[
                  styles.text,
                  { paddingLeft: 10, fontWeight: "semibold" },
                ]}
              >
                - Usufructo
              </Text>

              <View style={styles.text}>
                <Text style={[styles.list, { fontWeight: "semibold" }]}>
                  {"a)"} Mantenimiento
                </Text>
                <View style={{ marginLeft: 15, marginTop: 5, width: "97.5%" }}>
                  <Text style={styles.list} numberOfLines={0}>
                    • El pago por concepto de mantenimiento corresponde a: US$
                    {"  "}
                    {props.dataPDF.membershipmaintenance} (
                    {membershipmaintenanceletter}) anuales. Cabe indicar que,
                    este concepto se descontará directamente de los ingresos que
                    genere RIBERA DEL RIO CLUB RESORT S.A. cada año. En caso
                    dichos ingresos no sean suficientes para cubrir el
                    mencionado concepto, este pago será asumido por EL ASOCIADO,
                    esto aplicará a partir que el Hotel Club Resort se encuentre
                    al 100% desarrollado.
                  </Text>
                </View>

                <View style={styles.text}>
                  <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                    • Mientras que el Hotel Club Resort se encuentre en etapa de
                    desarrollo no habrá ningún
                  </Text>
                  <Text style={{ paddingLeft: 38 }}>
                    costo relacionado al mantenimiento, salvo circunstancias de
                    fuerza mayor en el que el Hotel Club Resort no sea
                    autosostenible, para ello, se realizará una Junta General de
                    accionistas para plantear un mantenimiento temporal le de
                    viabilidad al proyecto durante su desarrollo.
                  </Text>
                </View>

                <View style={styles.text}>
                  <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                    • En caso el pago por mantenimiento deba ser asumido por EL
                    ASOCIADO, éste deberá can-
                  </Text>
                  <Text style={{ paddingLeft: 38 }}>
                    celar el este concepto hasta el último día calendario del
                    mes de enero del año respectivo. Este concepto podrá variar,
                    según lo determine y justifique la administración
                    (incremento costo vida, inflación, valor dinero en el
                    tiempo, etc).
                  </Text>
                </View>

                <View style={styles.text}>
                  <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                    • El no uso del Programa de Beneficios de Ribera del Río
                    Club Resort S.A., no exonera de este
                  </Text>
                  <Text style={{ paddingLeft: 38 }}>pago al titular.</Text>
                </View>

                <View style={styles.text}>
                  <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                    • En caso de incumplimiento de pago de la cuota del
                    mantenimiento anual, EL ASOCIADO que-
                  </Text>
                  <Text style={{ paddingLeft: 38 }}>
                    dará inhabilitado temporalmente hasta regularizar el pago,
                    es decir, se procederá a la suspensión de los beneficios
                    según el Programa de Beneficios y no se habilitará el USO,
                    hasta la subsanación del pago o pagos pendientes.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>7</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*8*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                • Esta operación no requiere estado de cuenta previo para su
                cancelación. Puede ser abona-
              </Text>
              <Text style={{ paddingLeft: 38 }}>
                do en las cuentas corrientes que serán informadas por RIBERA DEL
                RIO CLUB RESORT S.A. en su oportunidad, identificándose con su
                número de Documento de Identidad y/o pago directo en alguna
                oficina del Hotel Club Resort. o en las cuentas corrientes que
                para tal fin se le proporcionaran a EL ASOCIADO con la finalidad
                que abone dichos mantenimientos, asimismo una vez realizado
                estos, bastará una comunicación electrónica con la copia del
                depósito correspondiente para dar por cancelada la obligación.
                RIBREA DEL RIO RESORT S.A.; emitirá los documentos contables de
                acuerdo a Ley dentro de las 48 horas siguientes a la
                confirmación del abono.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={[styles.list, { fontWeight: "semibold" }]}>
                {"b)"} Términos de Conducta
              </Text>
              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                  • EL ASOCIADO deberá responsabilizarse por los daños que éste,
                  su cónyuge, hijos, hijas e
                </Text>
                <Text style={{ paddingLeft: 38 }}>
                  invitados pudieran causar a las instalaciones de RIBERA DEL
                  RÍO CLUB RESORT S.A., ya sea por dolo, culpa leve o culpa
                  inexcusable.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                  • EL ASOCIADO deberá cancelar las sanciones que RIBERA DEL RÍO
                  CLUB RESORT S.A., a tra-
                </Text>
                <Text style={{ paddingLeft: 38 }}>
                  vés de su administración, podrá imponer a los socios que
                  constan en el Código de Ética y Conducta del Hotel Club
                  Resort.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                  • EL ASOCIADO declara conocer las disposiciones contenidas en
                  el Código de Ética y Conducta
                </Text>
                <Text style={{ paddingLeft: 38 }}>
                  de RIBERA DEL RÍO CLUB RESORT S.A, establecido en el Anexo 1
                  del presente contrato y que lo ha leído previamente a la
                  suscripción del presente documento, sin más constancia que la
                  firma puesta al pie, quedando suscrito a sus términos y
                  condiciones aceptando los procedimientos y sanciones que éste
                  contempla.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={[styles.list, { marginLeft: 15, marginTop: 5 }]}>
                  • EL ASOCIADO acepta tener conocimiento que RIBERA DEL RÍO
                  CLUB RESORT S.A. podrá
                </Text>
                <Text style={{ paddingLeft: 38, marginBottom: 5 }}>
                  modificar su Reglamento, así como podrá expedir otras normas,
                  reglamentos y políticas que tengan por finalidad mejorar el
                  uso de las instalaciones de RIBERA DEL RÍO CLUB RESORT S.A.
                  por parte de los afiliados y público en general, los cuales
                  están obligados a respetarlas desde la fecha en que estas sean
                  comunicadas, dichas modificaciones serán sometidas a la Junta
                  General de Accionistas obligatoria o convocada para tal fin.
                </Text>
              </View>

              <Text style={styles.subtitle}>
                SEXTA: FACULTADES DE LAS PARTES
              </Text>
              <Text style={styles.text}>
                6.1. Las partes declaran expresamente que corresponderá a VALLE
                ENCANTADO cualquier vinculación
              </Text>
              <Text style={{ paddingLeft: 22, marginTop: -8 }}>
                económica que en el desarrollo del negocio se acuerde con
                terceros, para lo cual VALLE ENCANTADO actuará en nombre propio
                al celebrar contratos, al asumir obligaciones o al adquirir
                créditos. En consecuencia, queda convenido que no existirá
                relación jurídica alguna entre los terceros y EL ASOCIADO; y,
                asimismo, los terceros no adquirirán obligaciones frente a EL
                ASOCIADO ni éste ante aquellos.
              </Text>

              <Text style={[styles.text, { marginTop: 5 }]}>
                6.2. Queda expresamente establecido que EL ASOCIADO no asume ni
                asumirá ninguna responsabili-
              </Text>
              <Text style={{ paddingLeft: 22, marginTop: -8 }}>
                dad, de ninguna naturaleza, por las acciones, decisiones,
                compromisos o cualquier otro acto, sea verbal o escrito, que
                realice VALLE ENCANTADO, ni por las consecuencias que ellos
                generen al vincularse con otros asociados-inversionistas en el
                desarrollo del negocio referido en el presente contrato, hecho
                que EL ASOCIADO declara conocer plenamente y acepta en el
                presente acto en cuanto a su naturaleza y relación jurídica.
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>8</Text>
      </Page>
      <Page wrap={true} size="A4">
        {/*9*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <Text style={[styles.text, { marginTop: 5 }]}>
                6.3. En virtud de lo acordado en la presente cláusula, las
                partes convienen que VALLE ENCANTADO
              </Text>
              <Text style={{ paddingLeft: 22, marginTop: -8 }}>
                no requiere de ningún tipo de consentimiento previo adicional de
                EL ASOCIADO para cualquier vinculación económica celebrada en el
                desarrollo del negocio con terceros, más allá de lo expresado y
                acordado en la citada cláusula.
              </Text>

              <Text style={[styles.text, { marginTop: 5 }]}>
                6.4. EL ASOCIADO declara estar conforme en que no tendrá
                injerencia directa ni indirecta en las
              </Text>
              <Text
                style={{ paddingLeft: 22, marginTop: -8, marginBottom: 15 }}
              >
                actividades de VALLE ENCANTADO.
              </Text>

              <Text style={styles.subtitle}>SÉTIMA: INCUMPLIMIENTO</Text>
              {props.dataPDF.isAllScheduleActive ? (
                <>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify", marginBottom: 5 }}>
                      El ASOCIADO renuncia irrevocablemente a invocar hechos de
                      terceros o causas no imputables a él, en caso de
                      incumplimiento de cualesquiera de las obligaciones
                      asumidas en el presente CONTRATO.
                    </Text>
                  </View>

                  <Text style={styles.subtitle}>
                    OCTAVA: CUMPLIMIENTO DE NORMAS SOBRE PREVENCIÓN DE LAVADO DE
                    ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      A la suscripción del presente contrato, las partes se
                      sujetan a las normas vigentes y aplicables sobre
                      prevención de lavado de activos y financiamiento del
                      terrorismo del Reglamento de Gestión de Riesgo de Lavado
                      de activos, aprobado mediante Resolución SBS N° 2660-2015.
                      En ese sentido, las partes declaran que sus actividades,
                      así como los valores materia de la presente transferencia
                      o transferencias no se encuentran relacionados con
                      actividades ilícitas, particularmente de lavado de activos
                      o del financiamiento del terrorismo.
                    </Text>
                    <Text
                      style={{
                        textAlign: "justify",
                        marginTop: 10,
                      }}
                    >
                      Si alguna de las partes resultase implicada o involucrada
                      en una investigación relacionada con actividades ilícitas
                      de lavado de activos y/o financiamiento del terrorismo o
                      fuese incluida en Listas de control como las de la ONU,
                      OFAC, Listas de terroristas de la Unión Europea, Listas de
                      Países y Territorios No Cooperantes y otras que señale la
                      SBS; esta se compromete a indemnizar y/o pagar los daños y
                      perjuicios directos, multas, sanciones, costas, honorarios
                      de abogados, en los que la parte involucrada haya
                      incurrido tras un procedimiento en el que hubiera sido
                      finalmente condenado por resolución administrativa o
                      judicial firme, y que pudiera sufrir sin que medie
                      responsabilidad suya, esto como consecuencia directa de
                      los hechos o actos dolosos o mediando culpa grave de la
                      parte involucrada y que generen demandas, denuncias de
                      todo tipo, medidas cautelares fuera y dentro del proceso,
                      reclamos interpuestos por cualquier causa y ante cualquier
                      fuero judicial, arbitral o administrativo. La parte
                      involucrada deberá asumir los gastos mencionados en la
                      presente cláusula hasta que la parte afectada se vea
                      eximida del proceso.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>
                    NOVENA: ASOCIACIÓN EN PARTICIPACIÓN
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      En armonía con lo establecido por los artículos 438 y 441
                      de la Ley General de Sociedades, las partes dejan
                      constancia de que el presente contrato de asociación en
                      participación no genera la creación de una persona
                      jurídica y tampoco tiene razón social ni denominación
                      alguna. En consecuencia, VALLE ENCANTADO actuará en nombre
                      propio en las relaciones comerciales que se originen a
                      propósito del presente contrato.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>DÉCIMA: CONFIDENCIALIDAD</Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      Las partes acuerdan que, si no cuentan con el
                      consentimiento previo de la otra parte, no revelarán el
                      contenido de este contrato a ningún tercero, a no ser que
                      alguna de las partes tenga que revelar dicha información:
                      (a) para implementar o hacer cumplir este contrato; (b) si
                      se ve obligada a hacerlo por una ley, tribunal o norma;
                      (c) si sus abogados le informan que está obligada
                      legalmente a hacerlo o que el hecho de que no cumpla con
                      hacerlo pueda dar lugar a que asuma alguna responsabilidad
                    </Text>
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      7.1.{"  "}El ASOCIADO renuncia irrevocablemente a invocar
                      hechos de terceros o causas no imputables
                    </Text>
                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      a él, en caso de incumplimiento de cualesquiera de las
                      obligaciones asumidas en el presente CONTRATO.
                    </Text>
                  </View>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      7.2.{"  "}El incumplimiento del abono de las cuotas
                      pactadas conforme a los plazos estipulados en el Cro-
                    </Text>
                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      nograma de Pagos de Inversión constado en el Anexo 2 del
                      presente documento, anulan el derecho a EL ASOCIADO de
                      posesión de las acciones que le correspondieran, como se
                      describe en el punto 7.3 de la presente cláusula.
                    </Text>
                  </View>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      7.3.{"  "}En caso que EL ASOCIADO se atrase en el pago de
                      las cuotas del financiamiento, según las fechas
                    </Text>
                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      estipuladas en el cronograma de pagos, se procederá a la
                      suspensión de los beneficios según Programa de Beneficio,
                      que es parte integral del presente contrato como sigue:
                    </Text>
                    <View style={{ paddingLeft: 32 }}>
                      <Text style={{ marginTop: 5 }}>
                        {"a)"} Atraso de UNA (1) CUOTA MENSUAL, se procederá a
                        la suspensión de los beneficios de des-
                      </Text>
                      <Text style={{ paddingLeft: 12 }}>
                        cuentos en todos los servicios del Hotel Club Resort,
                        más la aplicación de la mora automática conforme a lo
                        establecido en la Cláusula Octava.
                      </Text>
                      <Text style={{ marginTop: 5 }}>
                        {"b)"} Atraso de DOS (2) CUOTAS MENSUALES, se procederá
                        a la suspensión de los beneficios del
                      </Text>
                      <Text style={{ paddingLeft: 12 }}>
                        plan de alojamiento incluido en el programa de
                        beneficios, más la aplicación de la mora automática
                        conforme a lo establecido en la Cláusula Octava.
                      </Text>
                      <Text style={{ textAlign: "justify", marginTop: 5 }}>
                        {"c)"} Atraso de TRES (3) CUOTAS MENSUALES, se procederá
                        a la resolución del presente contrato
                      </Text>
                      <Text style={{ paddingLeft: 12 }}>
                        y entrará a Preliquidación, teniendo un (1) mes de plazo
                        para regularizar los abonos de las cuotas pendientes,
                        plazo en el cual también se aplicará la mora automática
                        conforme a lo establecido en la Cláusula Octava. Si EL
                        ASOCIADO no cumple con regularizar los abonos pendientes
                        en el plazo adicional otorgado, se resolverá el presente
                        contrato por incumplimiento contractual y entrará a
                        Liquidación.
                      </Text>
                    </View>
                  </View>

                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      7.4.{"  "}Aplicado el supuesto de liquidación y resolución
                      contractual mencionado en el punto anterior,
                    </Text>
                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      EL ASOCIADO pierde el derecho de la titularidad de las
                      acciones por haber incumplido el contrato y por ende no se
                      le emitirá el Certificado de acciones correspondiente, y
                      en caso se ya se hubiese emitido certificados anticipados
                      esto serían anulados.
                    </Text>

                    <Text style={{ textAlign: "justify", marginTop: 8 }}>
                      7.5.{"  "}VALLE ENCANTADO aplicará una penalidad del 100%
                      correspondiente al pago total de la CUOTA
                    </Text>

                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      INICIAL pagada por EL ASOCIADO y una penalidad
                      correspondiente al 50% del monto de dinero de las CUOTAS
                      MENSUALES pagadas por EL ASOCIADO, por el incumplimiento
                      contractual, esto correspondiente a gastos comerciales,
                      tributarios, legales y administrativos. Por lo cual,
                      quedarán nulos los certificados parciales que se le hayan
                      podido entregar a EL ASOCIADO y correspondiente
                      cancelación del Asiento en el libro de Matricula de
                      acciones de RIBERA DEL RIO CLUB RESORT S.A.
                    </Text>

                    <Text
                      style={{
                        textAlign: "justify",
                        marginLeft: 20,
                        marginTop: 10,
                      }}
                    >
                      En consecuencia, VALLE ENCANTADO le devolverá a EL
                      ASOCIADO el 50% restante del monto de dinero que haya
                      cancelado por las CUOTAS MENSUALES de su paquete de
                      acciones de inversión hasta la fecha de la resolución del
                      presente contrato, en servicios que brinda el Hotel Club
                      Resort (Alojamiento, Alquiler de Espacios, Fulldays).
                    </Text>
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
        <Text style={styles.footer}>9</Text>
      </Page>

      {!props.dataPDF.isAllScheduleActive && (
        <>
          <Page wrap={true} size="A4">
            {/*10*/}
            <View wrap={false} style={styles.page}>
              <View style={styles.header}>
                <Image
                  style={[styles.imgHeader, { height: "80px" }]}
                  src="/pdf/inresorts/1.png"
                />
                <Image
                  style={[styles.imgHeader, { height: "50px" }]}
                  src="/pdf/inresorts/2.png"
                />
                <Image
                  style={[styles.imgHeader, { height: "50px" }]}
                  src="/pdf/inresorts/3.png"
                />
              </View>
              <View style={styles.body}>
                <View>
                  <View style={styles.text}>
                    <Text
                      style={{
                        textAlign: "justify",
                        marginLeft: 20,
                        marginTop: 10,
                      }}
                    >
                      Para ello, VALLE ENCANTADO le otorgará a EL ASOCIADO un
                      Vale equivalente a dicho monto, para que pueda consumirlo
                      contratando los servicios del Hotel Club Resort. Este Vale
                      cuenta con un plazo de vigencia de 3 años como máximo,
                      calculado a partir de la fecha de su entrega a EL
                      ASOCIADO. Cabe indicar que, el mencionado vale no aplica
                      para Bares y Restaurantes. Esto quedará constado en el
                      Acta de Resolución del contrato y los costos involucrados
                      en la resolución del presente contrato, quedarán a cargo
                      de EL ASOCIADO.
                    </Text>
                  </View>

                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      7.6.{"  "}En caso se le haya entregado a EL ASOCIADO
                      Certificados Parciales, en función al cumplimiento
                    </Text>
                    <Text style={{ textAlign: "justify", marginLeft: 22 }}>
                      de los segmentos anuales, estos tendrán vigencia mientras
                      EL ASOCIADO termine de cancelar la totalidad de los pagos
                      pendientes establecidos en su Cronograma de Pagos de
                      Inversión, caso contrario, se revocarán las acciones
                      otorgadas mediante el Certificado Parcial de acciones
                      entregado anteriormente.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>OCTAVA: MORA AUTOMÁTICA</Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      En caso EL ASOCIADO incumpla con el abono de una o más
                      cuotas mensuales pactadas, conforme a los plazos
                      estipulados en el Cronograma de Pagos de Inversión,
                      constado en el Anexo 2 que es parte integral del presente
                      contrato, EL ASOCIADO incurrirá en mora automática a
                      partir del vencimiento del plazo de una cuota mensual, sin
                      necesidad de requerimiento de pago alguno ni intimación
                      Judicial o Extrajudicial, conforme lo establecido en el
                      numeral 7.3 de la Cláusula Sétima del presente documento.
                      Estos intereses moratorios se devengarán desde el día
                      siguiente a la fecha del vencimiento de la obligación,
                      inclusive hasta el día del pago total de la cuota o cuotas
                      pendientes de pago.
                    </Text>
                  </View>
                  <View style={styles.text}>
                    <Text
                      style={{
                        textAlign: "justify",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      La tasa diaria aplicable a la mora automática referida a
                      la presente cláusula, asciende al 0.5% de la cuota mensual
                      establecida en el Cronograma de Pagos de Inversión - Anexo
                      2, que es parte integral del presente contrato.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>
                    NOVENA: CUMPLIMIENTO DE NORMAS SOBRE PREVENCIÓN DE LAVADO DE
                    ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      A la suscripción del presente contrato, las partes se
                      sujetan a las normas vigentes y aplicables sobre
                      prevención de lavado de activos y financiamiento del
                      terrorismo del Reglamento de Gestión de Riesgo de Lavado
                      de activos, aprobado mediante Resolución SBS N° 2660-2015.
                      En ese sentido, las partes declaran que sus actividades,
                      así como los valores materia de la presente transferencia
                      o transferencias no se encuentran relacionados con
                      actividades ilícitas, particularmente de lavado de activos
                      o del financiamiento del terrorismo.
                    </Text>
                    <Text
                      style={{
                        textAlign: "justify",
                        marginTop: 10,
                      }}
                    >
                      Si alguna de las partes resultase implicada o involucrada
                      en una investigación relacionada con actividades ilícitas
                      de lavado de activos y/o financiamiento del terrorismo o
                      fuese incluida en Listas de control como las de la ONU,
                      OFAC, Listas de terroristas de la Unión Europea, Listas de
                      Países y Territorios No Cooperantes y otras que señale la
                      SBS; esta se compromete a indemnizar y/o pagar los daños y
                      perjuicios directos, multas, sanciones, costas, honorarios
                      de abogados, en los que la parte involucrada haya
                      incurrido tras un procedimiento en el que hubiera sido
                      finalmente condenado por resolución administrativa o
                      judicial firme, y que pudiera sufrir sin que medie
                      responsabilidad suya, esto como consecuencia directa de
                      los hechos o actos dolosos o mediando culpa grave de la
                      parte involucrada y que generen demandas, denuncias de
                      todo tipo, medidas cautelares fuera y dentro del proceso,
                      reclamos interpuestos por cualquier causa y ante cualquier
                      fuero judicial, arbitral o administrativo. La parte
                      involucrada deberá asumir los gastos mencionados en la
                      presente cláusula hasta que la parte afectada se vea
                      eximida del proceso.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.footer}>10</Text>
          </Page>
        </>
      )}
      <Page wrap={true} size="A4">
        {/*11*/}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              {props.dataPDF.isAllScheduleActive ? (
                <></>
              ) : (
                <>
                  <Text style={styles.subtitle}>
                    DÉCIMA: ASOCIACIÓN EN PARTICIPACIÓN
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      En armonía con lo establecido por los artículos 438 y 441
                      de la Ley General de Sociedades, las partes dejan
                      constancia de que el presente contrato de asociación en
                      participación no genera la creación de una persona
                      jurídica y tampoco tiene razón social ni denominación
                      alguna. En consecuencia, VALLE ENCANTADO actuará en nombre
                      propio en las relaciones comerciales que se originen a
                      propósito del presente contrato.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>
                    DÉCIMO PRIMERA: CONFIDENCIALIDAD
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      Las partes acuerdan que, si no cuentan con el
                      consentimiento previo de la otra parte, no revelarán el
                      contenido de este contrato a ningún tercero, a no ser que
                      alguna de las partes tenga que revelar dicha información:
                      (a) para implementar o hacer cumplir este contrato; (b) si
                      se ve obligada a hacerlo por una ley, tribunal o norma;
                      (c) si sus abogados le informan que está obligada
                      legalmente a hacerlo o que el hecho de que no cumpla con
                      hacerlo pueda dar lugar a que asuma alguna responsabilidad
                      frente a cualquier otra Entidad o se genere el riesgo de
                      la imposición de una sanción administrativa de cualquier
                      tipo a sus asesores y auditores profesionales de ser el
                      caso.
                    </Text>
                  </View>
                </>
              )}
              {props.dataPDF.isAllScheduleActive ? (
                <View style={styles.text}>
                  <Text style={{ textAlign: "justify" }}>
                    frente a cualquier otra Entidad o se genere el riesgo de la
                    imposición de una sanción administrativa de cualquier tipo a
                    sus asesores y auditores profesionales de ser el caso.
                  </Text>
                </View>
              ) : (
                <></>
              )}
              <Text style={styles.subtitle}>
                {props.dataPDF.isAllScheduleActive
                  ? "DÉCIMO PRIMERA"
                  : "DÉCIMO SEGUNDA"}
                : PAGO DE TRÁMITES LEGALES
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Las partes establecen que los gastos notariales
                  correspondientes a la legalización del presente contrato,
                  queda a cargo de EL ASOCIADO.
                </Text>
              </View>
              <Text style={styles.subtitle}>
                {props.dataPDF.isAllScheduleActive
                  ? "DÉCIMO SEGUNDA"
                  : "DÉCIMO TERCERA"}
                : ADICIONALES
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  El presente contrato aplica a las siguientes promociones:
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  • Duplicar el número de acciones del Paquete adquirido como
                  socio fundador, siempre
                </Text>
                <Text style={{ marginLeft: 28 }}>
                  y cuando cumpla con pagar el 100% del cronograma de pagos,
                  conforme a la cláusula tercera del presente contrato. Esto
                  solo aplica para planes vitalicios.
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  • Oportunidad de migrar a un Paquete de acciones superior,
                  dentro de los 6 meses
                </Text>
                <Text style={{ marginLeft: 28 }}>
                  siguientes a la fecha de suscripción del Contrato de
                  Asociación en participación para el desarrollo de negocios o
                  hasta que haya Stock, lo que pase primero, con los mismos
                  beneficios (Válido para Paquete de acciones desde Mini hasta
                  Vitalicia Premium).
                </Text>
              </View>
              {props.dataPDF.isAllScheduleActive ? (
                <></>
              ) : (
                <>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      EL ASOCIADO puede solicitar la devolución total de su
                      dinero en efectivo durante los primeros 7 días calendario
                      contados a partir del registro de suscripción. Pasado este
                      tiempo, aplica lo dispuesto en el numeral 7.5 de la
                      Cláusula Sétima del presente contrato.
                    </Text>
                  </View>
                </>
              )}
              <Text style={styles.subtitle}>
                {props.dataPDF.isAllScheduleActive
                  ? "DÉCIMO TERCERA"
                  : "DÉCIMO CUARTA"}
                : DOMICILIO Y NOTIFICACIONES LEGALES
              </Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  Las partes señalan como sus domicilios los consignados en la
                  introducción del presente contrato. Cualquier comunicación o
                  notificación deberá ser cursada a dichos domicilios por
                  escrito, con cargo de recepción. En caso de cambio de
                  domicilio, para su efecto o validez legal deberá comunicarse a
                  la otra parte, con cargo de recepción y a la brevedad posible.
                  En tanto no recepcione la otra parte la citada comunicación se
                  entenderán válidamente efectuadas las comunicaciones remitidas
                  al anterior domicilio y surtirán todos sus efectos legales.
                  Cualquier variación del mismo deberá ser comunicada mediante
                  carta notarial con una anticipación de treinta (30) días
                  naturales, sin cuyo requisito no tendrá efecto la variación
                  del domicilio.
                </Text>
              </View>

              {props.dataPDF.isAllScheduleActive ? (
                <>
                  <Text style={styles.subtitle}>
                    DÉCIMO CUARTA: LEGISLACION APLICABLE
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      El contrato se regirá por la Legislación Peruana. En todo
                      lo no previsto por las partes en el presente contrato,
                      ambas se someten a lo establecido por las normas del
                      Código Civil, la Ley General de Sociedades, los principios
                      de buena fe contractuales y demás del sistema jurídico que
                      resulten aplicables.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>
                    DÉCIMO QUINTA: COMPETENCIA ARBITRAL
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      En caso de cualquier discrepancia o conflicto que surgiera
                      entre las partes, en relación con la celebración,
                      interpretación, ejecución y/o efectos jurídicos,
                      resolución o cualquier otro vinculado con el presente
                      contrato, éste contrato será sometido a las disposiciones
                      del Centro de Arbitraje Nacional e Internacional de la
                      Cámara de Comercio de Lima.
                    </Text>
                  </View>

                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      Dicho Tribunal Arbitral estará constituido por tres (3)
                      miembros. Cada parte escoge a un (1) árbitro, siendo el
                      tercer miembro escogido por los árbitros de cada una de
                      las partes. En caso este tercer miembro no se escoja
                      dentro del plazo máximo de diez (10) días naturales, dicho
                      tercer miembro será escogido por el mismo Centro de
                      Arbitraje de la Cámara de Comercio de Lima. Este tercer
                      miembro será quien presida el Tribunal Arbitral.
                    </Text>
                  </View>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
        <Text style={styles.footer}>
          {props.dataPDF.isAllScheduleActive ? 10 : 11}
        </Text>
      </Page>
      <Page wrap={true} size="A4">
        {/* 12 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <View>
              {props.dataPDF.isAllScheduleActive ? (
                <></>
              ) : (
                <>
                  <Text style={styles.subtitle}>
                    DÉCIMO QUINTA: LEGISLACION APLICABLE
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      El contrato se regirá por la Legislación Peruana. En todo
                      lo no previsto por las partes en el presente contrato,
                      ambas se someten a lo establecido por las normas del
                      Código Civil, la Ley General de Sociedades, los principios
                      de buena fe contractuales y demás del sistema jurídico que
                      resulten aplicables.
                    </Text>
                  </View>
                  <Text style={styles.subtitle}>
                    DÉCIMO SEXTA: COMPETENCIA ARBITRAL
                  </Text>
                  <View style={styles.text}>
                    <Text style={{ textAlign: "justify" }}>
                      En caso de cualquier discrepancia o conflicto que surgiera
                      entre las partes, en relación con la celebración,
                      interpretación, ejecución y/o efectos jurídicos,
                      resolución o cualquier otro vinculado con el presente
                      contrato, éste contrato será sometido a las disposiciones
                      del Centro de Arbitraje Nacional e Internacional de la
                      Cámara de Comercio de Lima.
                    </Text>
                  </View>
                </>
              )}
              <View style={styles.text}>
                {props.dataPDF.isAllScheduleActive ? (
                  <></>
                ) : (
                  <>
                    <Text style={{ textAlign: "justify" }}>
                      Dicho Tribunal Arbitral estará constituido por tres (3)
                      miembros. Cada parte escoge a un (1) árbitro, siendo el
                      tercer miembro escogido por los árbitros de cada una de
                      las partes. En caso este tercer miembro no se escoja
                      dentro del plazo máximo de diez (10) días naturales, dicho
                      tercer miembro será escogido por el mismo Centro de
                      Arbitraje de la Cámara de Comercio de Lima. Este tercer
                      miembro será quien presida el Tribunal Arbitral.
                    </Text>
                  </>
                )}
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  El laudo del Tribunal será definitivo e inapelable, así como
                  de obligatorio cumplimiento y ejecución para las partes.
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  Cabe señalar que la parte que no se vea favorecida con el
                  laudo expedido por el Tribunal Arbitral, será la que asuma el
                  costo del procedimiento invocado, en cuanto a las costas y los
                  costos.
                </Text>
                <Text
                  style={{
                    textAlign: "justify",
                    marginTop: 10,
                  }}
                >
                  En señal de conformidad las partes suscriben este documento en
                  la ciudad de Lima, a los {fecha[0]} días del mes de{" "}
                  {obtenerNombreMes(parseInt(fecha[1]))} de {fecha[2]}.
                </Text>
                <Text></Text>
                <Text></Text>
              </View>
              <View
                style={{
                  paddingTop: 50,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Image
                    src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                    style={{ width: "100px", height: "50px" }}
                  />
                  <Text>____________________________</Text>
                  <Text>VALLE ENCANTADO S.A.C.</Text>
                  <Text>Omar Fernando Urteaga Cabrera</Text>
                  <Text>Gerente General</Text>
                  <Text>VALLE ENCANTADO</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <Text>____________________________</Text>
                  <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                  <Text>
                    Documento de identidad N°{props.dataPDF.user.nroDocument}
                  </Text>
                  <Text>EL ASOCIADO</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>
          {props.dataPDF.isAllScheduleActive ? 11 : 12}
        </Text>
      </Page>
    </Document>
  );
}

export default InresortsPDFNewTwo;
