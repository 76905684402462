import { 
    Form,
    Table,
    FormCheck,
    Accordion,
    Button,
} from "react-bootstrap";

export const HistoricalBonds = ({
    dataBonus = []
}) => {
    return (
        <Table
            striped
            bordered
            hover
            responsive="md"
            size="sm"
        >
            <thead className="table-head">
                <tr>
                    <th>Nr.</th>
                    <th>Nombre</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Descripción</th>
                    <th>Avance</th>
                    <th>Fondo Deseado</th>
                    <th>Estado</th>
                    <th>Objetivo</th>
                </tr>
            </thead>
            <tbody>
            {
                dataBonus.map((value, index) => (
                    <tr key={value.id}>
                        <td>{index + 1}</td>
                        <td style={{textTransform: 'uppercase'}}>{value.name}</td>
                        <td>{value.startDate}</td>
                        <td>{value.startDate}</td>
                        <td>{value.description}</td>
                        <td>{value.advance}</td>
                        <td>$ {value.amount}</td>
                        <td>{value.state}</td>
                        <td>{ value.serieStatus }</td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )
}


