import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import AuthService from "../../services/auth.service";
import WalletService from "../../services/wallet.service";

export default class ElectronicPurse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Accounts: [],
      loading: true,
      noData: false,
      message: "",
      idUser: -1,
      isAdd: false,
      isEdit: false,
      isDelete: false,
      showModal: false,
      itemPurse: undefined,
      companyElectronic: [],
      idElectronicPurseCompany: 0,
      name: "",
      lastname: "",
      username: "",
      loadingDelete: false,
      showModalDelete: false,
    };
    this.getIdUser = this.getIdUser.bind(this);
    this.getElectroniCompany = this.getElectroniCompany.bind(this);
    this.getInformationAccount = this.getInformationAccount.bind(this);
  }
  componentDidMount() {
    this.getIdUser();
    this.getInformationAccount();
    this.getElectroniCompany();
  }

  async getElectroniCompany() {
    let response = await WalletService.getElectronic();

    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          companyElectronic: (this.state.companyElectronic = response.objModel),
        });
      } else {
        this.setState({
          companyElectronic: (this.state.companyElectronic = []),
        });
      }
    } else {
      this.setState({
        companyElectronic: [],
      });
    }
  }

  async getIdUser() {
    let id = AuthService.getCurrentIdUser();
    if (id !== undefined) {
      this.setState({
        idUser: (this.state.idUser = id),
      });
    }
  }

  async getInformationAccount() {
    let idUser = this.state.idUser;

    let response = await WalletService.getElectronicbyUser(idUser);

    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          Accounts: (this.state.Accounts = response.objModel),
          loading: false,
          noData: false,
        });
      } else {
        this.setState({
          Accounts: (this.state.Accounts = []),
          loading: false,
          noData: true,
          message: "No hay registros para mostrar.",
        });
      }
    } else {
      this.setState({
        Accounts: [],
        loading: false,
        noData: true,
        message:
          "Ocurrió un error al obtener las cuentas bancarias. Vuelva a intentarlo más tarde.",
      });
    }
  }

  handleShow = (e, item) => {
    this.setState({
      showModal: (this.state.showModal = true),
      itemPurse: (this.state.itemPurse = item),
    });

    if (item != undefined) {
      this.setState({
        idElectronicPurseCompany: item.idElectronicPurseCompany,
        name: item.holderName,
        lastname: item.holderLastName,
        username: item.usernameAccount,
      });
    }
  };

  handleClose = () => {
    this.setState({
      showModal: (this.state.showModal = false),
      itemPurse: (this.state.itemPurse = undefined),
    });
  };

  handleSelect = (e, field) => {
    var value = Number(e.target.value);
    this.setState({
      field: (this.state[field] = value),
    });
  };

  onChange = (e, field) => {
    let value = e.target.value;

    this.setState({
      field: (this.state[field] = value),
    });
  };
  validate() {
    if (this.state.idElectronicPurseCompany <= 0) {
      alert("Seleccione compañia.");
      return false;
    }

    if (this.state.name.length <= 0) {
      alert("Ingrese nombre.");
      return false;
    }

    if (this.state.lastname <= 0) {
      alert("Ingrese apellido.");
      return false;
    }

    if (this.state.username <= 0) {
      alert("Ingrese usuario.");
      return false;
    }

    return true;
  }
  // Save
  saveElectronicPurse = async () => {
    if (this.validate()) {
      let data = {
        HolderName: this.state.name,
        HolderLastName: this.state.lastname,
        UsernameAccount: this.state.username,
        IdElectronicPurseCompany: this.state.idElectronicPurseCompany,
        IdUser: Number(this.state.idUser),
      };

      if (this.state.itemPurse === undefined) {
        let response = await WalletService.registerElectronicPurse(data);

        if (response !== undefined) {
          if (response.status === 1) {
            this.getInformationAccount();

            alert("Registro exitoso.");
            this.setState({
              showModal: false,
            });
          } else {
            alert(`Error: ${response.description}`);
            this.setState({
              showModal: false,
            });
          }
        } else {
          alert("Ocurrió un error al registrar billetera eletrónica.");
        }
      } else {
        data.IdElectronicPurse = this.state.itemPurse.idElectronicPurse;

        let response = await WalletService.updateElectronicPurse(data);

        if (response !== undefined) {
          if (response.status === 1) {
            this.getInformationAccount();

            alert("Registro modificado exitosamente.");
            this.setState({
              showModal: false,
              itemPurse: (this.state.item = undefined),
            });
          } else {
            alert(`Error: ${response.description}`);
            this.setState({
              showModal: false,
              itemPurse: (this.state.item = undefined),
            });
          }
        } else {
          alert("Ocurrió un error al registrar billetera eletrónica.");
        }
      }
    }
  };

  handleShowDelete = (e, item) => {
    this.setState({
      itemPurse: (this.state.itemPurse = item),
      showModalDelete: (this.state.showModalDelete = true),
    });
  };

  handleCloseDelete = (e, item) => {
    this.setState({
      itemPurse: (this.state.itemPurse = undefined),
      showModalDelete: (this.state.showModalDelete = false),
    });
  };

  saveDelete = async () => {
    let response = await WalletService.deleteElectronicPurse(
      this.state.itemPurse.idElectronicPurse
    );

    if (response !== undefined) {
      if (response.status === 1) {
        this.getInformationAccount();

        alert("Se ha eliminado el registro exitosamente.");
        this.setState({
          showModalDelete: false,
          itemPurse: (this.state.item = undefined),
          loadingDelete: (this.state.loadingDelete = false),
        });
      } else {
        //alert(`Error: ${response.description}`);
        alert(
          "No puede eliminar un monedero virtual si ya realizó alguna transacción, si desea eliminarlo solicítalo al administrador del sistema."
        );
        this.setState({
          showModalDelete: false,
          itemPurse: (this.state.item = undefined),
          loadingDelete: (this.state.loadingDelete = false),
        });
      }
    } else {
      alert("Ocurrió un error al eliminar billetera eletrónica.");
      this.setState({
        loadingDelete: (this.state.loadingDelete = false),
      });
    }
  };

  render() {
    const {
      Accounts,
      loading,
      noData,
      message,
      companyElectronic,
      idElectronicPurseCompany,
      loadingDelete,
    } = this.state;

    const EL_PAYPAL = 1;
    const EL_PAYEER = 2;

    return (
      <div>
        <Row style={{ marginTop: "30px" }}>
          <Col sm={12} style={{ textAlign: "left", paddingBottom: "10px" }}>
            <Button
              size="sm"
              onClick={(e) => {
                this.handleShow(e, undefined);
              }}
            >
              Añadir billetera
            </Button>
          </Col>
        </Row>
        {loading && (
          <div style={{ textAlign: "center", paddingTop: 30 }}>
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>{" "}
            <Form.Label>
              Cargando información de cuentas bancarias...
            </Form.Label>
          </div>
        )}
        {!loading && !noData && (
          <div>
            <Row>
              <Col sm={12}>
                <Table responsive>
                  <thead className="table-head">
                    <tr>
                      <th>Nº</th>
                      <th>Compañía</th>
                      <th>Titular</th>
                      <th>Código</th>
                      <th>Link de pago</th>
                      <th>Editar</th>
                      <th>eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Accounts.map((item, idx) => {
                      let company = "";
                      if (EL_PAYEER === item.idElectronicPurseCompany) {
                        company = "Payeer";
                      } else if (EL_PAYPAL === item.idElectronicPurseCompany) {
                        company = "Paypal";
                      }

                      return (
                        <tr>
                          <td>{idx * 1}</td>
                          <td>{company}</td>
                          <td>
                            {item.holderName} {item.holderLastName}
                          </td>
                          <td>{item.usernameAccount}</td>
                          <td>{item.paidLink}</td>
                          <td>
                            <div
                              onClick={(e) => {
                                this.handleShow(e, item);
                              }}
                            >
                              <FaEdit></FaEdit>
                            </div>
                          </td>
                          <td>
                            <div
                              onClick={(e) => {
                                this.handleShowDelete(e, item);
                              }}
                            >
                              <RiDeleteBin2Fill></RiDeleteBin2Fill>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}

        {noData && (
          <div>
            <Form.Label>{message}</Form.Label>
          </div>
        )}

        {/* Modal */}
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          style={{ fontSize: 11 }}
          size="sm"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Billetera electrónica</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Agregar nuevo billetera */}

            <Form.Group>
              <Form.Group style={{ paddingBottom: "15px" }}>
                <Form.Label>Seleccione compañía:</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={idElectronicPurseCompany}
                  onChange={(e) => {
                    this.handleSelect(e, "idElectronicPurseCompany");
                  }}
                  style={{ fontSize: 11 }}
                >
                  <option value={0} disabled>
                    Seleccione ...
                  </option>
                  {companyElectronic.map((elem) => (
                    <option
                      key={elem.idElectronicPurseCompany}
                      value={elem.idElectronicPurseCompany}
                    >
                      {elem.nameCompany}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group style={{ paddingBottom: "15px" }}>
                <Form.Label>Nombre del titular</Form.Label>
                <Form.Control
                  placeholder="Nombre"
                  defaultValue={this.state.name}
                  maxLength={50}
                  onChange={(e) => {
                    this.onChange(e, "name");
                  }}
                  style={{ fontSize: 11 }}
                ></Form.Control>
              </Form.Group>
              <Form.Group style={{ paddingBottom: "15px" }}>
                <Form.Label>Apellido del titular</Form.Label>
                <Form.Control
                  placeholder="Apellido"
                  defaultValue={this.state.lastname}
                  maxLength={50}
                  onChange={(e) => {
                    this.onChange(e, "lastname");
                  }}
                  style={{ fontSize: 11 }}
                ></Form.Control>
              </Form.Group>
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                placeholder="Usuario"
                defaultValue={this.state.username}
                maxLength={50}
                onChange={(e) => {
                  this.onChange(e, "username");
                }}
                style={{ fontSize: 11 }}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Row style={{ width: '100%' }}>
              <Col style={{ textAlign: "left" }}>
                <Button
                  variant="primary"
                  onClick={(e) => this.saveElectronicPurse()}
                  type="submit"
                  size="sm"
                >
                  Guardar
                </Button>
              </Col>
              <Col  style={{ textAlign: "right" }}>
                <Button
                  onClick={(e) => this.handleClose()}
                  variant="secondary"
                  size="sm"
                  type="button"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDelete}
          onHide={this.handleCloseDelete}
          size="sm"
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {loadingDelete && (
              <div>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>Eliminando billetera...</p>
              </div>
            )}
            {!loadingDelete && this.state.itemPurse !== undefined && (
              <div>
                <Form.Label>
                  ¿Desea eliminar la billetera electrónica de{" "}
                  {this.state.itemPurse.holderName} {this.state.itemPurse.holderLastName}?
                </Form.Label>
              </div>
            )}
            <Form.Label></Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col sm={6}>
                <Button onClick={(e) => this.saveDelete()} size="sm">
                  Sí
                </Button>
                </Col>
                <Col sm={6}>
                <Button
                  onClick={(e) => this.handleCloseDelete()}
                  variant="secondary"
                  className="ml-5"
                  size="sm"
                >
                  No
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
