import React from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

export const OtherIncome = () => {
  return (
   <Row className='py-5'>
        <Col xs={12} md={4} className='pe-5'>
            <Form className='text-start'>
                <Form.Group className="mb-3">
                    <Form.Label>Ingreso* </Form.Label>
                        <Form.Control type="number" placeholder="Ingrese el ingreso" size='lg' />
                    </Form.Group>
                <Button
                    type='submit'
                    className='mt-2'
                >
                    Guardar Ingresos
                </Button>
            </Form>
        </Col>
        <Col xs={12} md={8} className='pt-3'>
            <Table responsive size="sm">
                <thead className="table-head-comision">
                  <tr>
                    <th>Nro.</th>
                    <th>Ingreso</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
            </Table>
        </Col>
    </Row>
  
  )
}
