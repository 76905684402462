import "bootstrap/dist/css/bootstrap.min.css";
import { Component } from "react";
import { Col, Form, ListGroup, Row, Tab, Table, Tabs } from "react-bootstrap";
import LoadApi from "validators/LoadApi";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import Validation from "../utils/Validation";
import MEProductos from "./MEProductos";

const BONO_DIRECTO = 3;
const BONO_EQUIPO = 7;
const BONO_CUOTA = 21;
const BONO_SEGMEMEBRESIA = 22;
const BONO_MENBRESIA = 29;
const BONO_MIGRACION = 23;
const BONO_RANGO = 28;
const BONO_MEPRODUCTOS = 30;


const SUM_PROPERTY_FOR_ITEM_REGISTERS = [
  'amountExlusiveBrands',
  'amountMembershipsextras',
  'amountRecomendationteam',
  'amountawardrange',
  'amountmigrations',
  'amountquotes',
  'amountrecomendationdirect'
];



export default class Comission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [],
      loading: true,
      noData: false,
      noDataMessage: "",
      showModal: false,
      registerModal: [],
      loadingModal: false,
      noDataModal: false,
      noDataMes: "",
      totalPages: 1,
      page: 1,
      size: 30,
      totalRegister: 0,
      totalPagesModal: 1,
      pageModal: 1,
      sizeModal: 100, // TODO fix
      totalRegisterModal: 0,
      totalComission: 0,
      listDirecta: [],
      listEquipo: [],
      listCuota: [],
      listMembresia: [],
      listMigracion: [],
      listRango: [],
      listMEProductos: [],
    };
  }

  componentDidMount() {
    this.getRegister();
  }

  async getRegister() {
    this.setState({
      registers: (this.state.registers = []),
      loading: (this.state.loading = true),
    });
    // Get by id
    let idUser = AuthService.getCurrentIdUser();
    let data = {
      SizeList: this.state.size,
      NroPage: this.state.page,
      ParametersSearched: {
        IdSocioSearch: Number(idUser),
      },
    };
    let response = await UserService.getPeriodByUser(data);

    if (response !== undefined) {
      if (response.status !== 1) {
        //;
        this.setState({
          registers: (this.state.registers = []),
          loading: (this.state.loading = false),
          noData: (this.state.noData = true),
          noDataMesssage: (this.state.noDataMessage =
            "Ocurrió un problema mientras obteníamos los registros. Inténtelo más tarde."),
        });
      } else {
        console.log('=============================================================');
        console.log("ver response getPeriodByUser =>", response);
        console.log("Sin filtro status ", response.objModel.listElements);
        let filtroStatus = response.objModel.listElements.filter(
          (item) => item.status === 1
        );
        console.log("SE HACE UN FILTRO POR STATUS y este seria registers SIN DETAILS", filtroStatus);
        if (
          response.objModel !== null &&
          response.objModel.listElements.length > 0
        ) {
          this.setState({
            registers: (this.state.registers = response.objModel.listElements.filter(
              (item) => item.status === 1
            )),
            totalRegister: (this.state.totalRegister =
              response.objModel.totalElemList),
            totalPages: (this.state.totalPages = response.objModel.totalPages),
            loading: (this.state.loading = false),
            noData: (this.state.noData = false),
            noDataMesssage: (this.state.noDataMessage = ""),
          });
        } else {
          this.setState({
            registers: (this.state.registers = []),
            totalRegister: (this.state.totalRegister = 0),
            totalPages: (this.state.totalPages = 0),
            loading: (this.state.loading = false),
            noData: (this.state.noData = true),
            noDataMesssage: (this.state.noDataMessage =
              "No hay registros para mostrar."),
          });
        }
      }
    }
  }
////////////////////////////////////////////////////////////////////////////////////////////////////
  async getResumen(id) {
    // let response = {
    //     objModel: packages,
    //     status : 1
    // }
    // if(response !== undefined ){
    //     if(response.status !== 1) {
    //         //;
    //         this.setState({
    //             registerModal: this.state.registerModal = [],
    //             loadingModal: this.state.loadingModal = false,
    //             noDataModal: this.state.noDataModal = true,
    //             noDataMes : this.state.noDataMes = "Ocurrió un problema mientras obteníamos los registros. Inténtelo más tarde.",
    //         });
    //     } else {
    //         if(response.objModel.length > 0) {
    //             this.setState({
    //                 registerModal: this.state.registerModal = response.objModel,
    //                 loadingModal: this.state.loadingModal = false,
    //                 noDataModal: this.state.noDataModal = false,
    //                 noDataMes : this.state.noDataMes = "",
    //             });
    //         } else {
    //             this.setState({
    //                 registerModal: this.state.registerModal = response.objModel,
    //                 loadingModal: this.state.loadingModal = false,
    //                 noDataModal: this.state.noDataModal = true,
    //                 noDataMes : this.state.noDataMes = "No hay registros para mostrar.",
    //             });
    //         }
    //     }
    // }
  }

  // Handle main pagination
  handlePageChange = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
    });

    // Call to  services
    this.getRegister();
  };

  handleSizeChange = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      size: (this.state.size = value),
      page: (this.state.page = 1),
    });

    // Call to  services
    this.getRegister();
  };

  // TODO agregar paginadoHandle modal
  onclickDetails = (e, item) => {

    e.preventDefault();
    console.log('item en onclickDetails',item);
    this.setState({
      itemPeriod: (this.state.itemPeriod = item),
    });
   // console.log('tas enviando este ITEM =>',item)
    this.getDetailsPeriod(item);
  };

  getDetailsPeriod = async (item) => {
    console.log('SE ENVIA EL ITEM AL SELECCIONAR UNA COMISION EN getDetailsPeriod =>',item);
    if (item !== undefined) {
      let idUser = AuthService.getCurrentIdUser();
      let data = {
        SizeList: this.state.sizeModal,
        NroPage: this.state.pageModal,
        IdReference: item.id,
        ParametersSearched: {
          IdSocioSearch: Number(idUser),
        },
      };
      this.setState({
        // showModal: true,
        loadingModal: true,
      });

      let response = await UserService.getComisiones(data);
      if (response !== undefined) {
        if (response.status !== 1) {
          //;
          this.setState({
            registerModal: (this.state.registerModal = []),
            totalRegisterModal: (this.state.totalRegisterModal = 0),
            totalPagesModal: (this.state.totalPagesModal = 0),
            loadingModal: (this.state.loadingModal = false),
            noDataModal: (this.state.noDataModal = true),
            noDataMes: (this.state.noDataMes =
              "Ocurrió un problema mientras obteníamos los registros. Inténtelo más tarde."),
          });
        } else {
          if (response.objModel.listElements.length >= 0) {
            console.log("HABER RESPONSE de getComisiones Bonus=>", response.objModel.listElements);
            let registers = this.state.registers;
            console.log("ESTE ES registers CON DETAILS =>", registers);

            let totalComission = 0;
//EL MAP CAMBIA EL MISMO ARREGLO DE REGISTERS AQUI LE ESTA AGREGANDO DETAILS
            registers.map((register) => {
              if (register.id === item.id) {
                register.details = response.objModel.listElements;
                totalComission += response.objModel.listElements.reduce(
                  (total, item) => total + item.amount,
                  0
                );
              } else {
                register.details = [];
              }

              return register;
            });

            this.setState({
              registers: (this.state.registers = registers),//ENVIAS EL NUEVO REGISTERS CON DETAILS
              totalComission: (this.state.totalComission = totalComission),
              registerModal: (this.state.registerModal =
                response.objModel.listElements),
              totalRegisterModal: (this.state.totalRegisterModal =
                response.objModel.totalElemList),
              totalPagesModal: (this.state.totalPagesModal =
                response.objModel.totalPages),
              //   loadingModal: (this.state.loadingModal = false),
              noDataModal: (this.state.noDataModal = false),
              noDataMes: (this.state.noDataMes = ""),
            });
            // For to load all registers
            this.getAllDetail(idUser, item, response.objModel.totalPages);
          } else {
            this.setState({
              registerModal: (this.state.registerModal = []),
              totalRegisterModal: (this.state.totalRegisterModal = 0),
              totalPagesModal: (this.state.totalPagesModal = 0),
              loadingModal: (this.state.loadingModal = false),
              loadingModal: (this.state.loadingModal = false),
              noDataModal: (this.state.noDataModal = true),
              noDataMes: (this.state.noDataMes =
                "No hay registros para mostrar."),
            });
          }
        }
      }
    }
  };
////////////////////////////////////////FIN getDetailsPeriod
  getAllDetail = async (idUser, item, totalPagesModal) => {
    console.log('------------------------------------------------------------------------------------------');
    let data = {
      SizeList: this.state.sizeModal,
      IdReference: item.id,
      ParametersSearched: {
        IdSocioSearch: Number(idUser),
      },
    };
    for (let i = 2; i <= totalPagesModal; i++) {
      console.log('ENTRAS AQUI????????????????');
      data.NroPage = i;
      let response = await UserService.getComisiones(data);

      if (response !== undefined) {
        if (response.status === 1) {
          console.log('ENTRAS AQUI????????????????');
          //;
          if (response.objModel.listElements.length > 0) {
            let registers = this.state.registers;
            let totalComission = 0;
            registers.map((register) => {
              if (register.id === item.id) {
                // get last arrayy
                let temp = register.details;

                if (temp) {
                  register.details = temp.concat(
                    response.objModel.listElements
                  );
                } else {
                  register.details = response.objModel.listElements;
                }
                totalComission += register.details.reduce(
                  (total, item) => total + item.amount,
                  0
                );
              } else {
                register.details = [];
              }

              return register;
            });

            this.setState({
              registers: (this.state.registers = registers),
              totalComission: (this.state.totalComission = totalComission),
              registerModal: (this.state.registerModal =
                response.objModel.listElements),
              totalRegisterModal: (this.state.totalRegisterModal =
                response.objModel.totalElemList),
              totalPagesModal: (this.state.totalPagesModal =
                response.objModel.totalPages),
              loadingModal: (this.state.loadingModal = false),
              noDataModal: (this.state.noDataModal = false),
              noDataMes: (this.state.noDataMes = ""),
            });
          } else {
            this.setState({
              registerModal: (this.state.registerModal = []),
              totalRegisterModal: (this.state.totalRegisterModal = 0),
              totalPagesModal: (this.state.totalPagesModal = 0),
              loadingModal: (this.state.loadingModal = false),
              loadingModal: (this.state.loadingModal = false),
              noDataModal: (this.state.noDataModal = true),
              noDataMes: (this.state.noDataMes =
                "No hay registros para mostrar."),
            });
          }
        }
      }
    }
    ///////////////////////////////////////////////////////////

    /// get registers by types
    let registers = this.state.registers;
    console.log('ver registers por types =>',registers)
    //console.log("ver registers =>", registers);
    let idx = registers.findIndex((obj) => obj.id === item.id);
    console.log('ver idx',idx);
    if (idx >= 0) {
      let register = registers[idx];

      const listDirecta = register.details.filter(
        (obj) => obj.typebonus === BONO_DIRECTO
      );

      const listEquipo = register.details.filter(
        (obj) => obj.typebonus === BONO_EQUIPO
      );
      const listCuota = register.details.filter(
        (obj) => obj.typebonus === BONO_CUOTA
      );
      const listMembresia = register.details.filter(
        (obj) => obj.typebonus === BONO_SEGMEMEBRESIA
      );
      const listMembres = register.details.filter(
        (obj) => obj.typebonus === BONO_MENBRESIA
      );
      const listMigracion = register.details.filter(
        (obj) => obj.typebonus === BONO_MIGRACION
      );
      const listRango = register.details.filter(
        (obj) => obj.typebonus === BONO_RANGO
      );
      const listMarcasExclusivas = register.details.filter(
        (obj) => obj.typebonus === 30
      );

      const listBonos = register.details.filter(
        (obj) => obj.typebonus === 34
      );

      console.log('listBonos', listBonos)

      register.listDirecta = listDirecta;
      register.listEquipo = listEquipo;

      // only master
      if (Number(AuthService.getCurrentIdUser()) === 12853) {
        register.listCuota = listCuota;
      } else {
        register.listCuota = [];
      }

      register.listMembresia = listMembresia;
      register.listMembres = listMembres;
      register.listMigracion = listMigracion;
      register.listRango = listRango;
      register.listMarcasExclusivas= listMarcasExclusivas;
      register.listBonos = listBonos;

      registers[idx] = register;

      this.setState({
        registers: (this.state.registers = registers),
        itemShow: (this.state.itemShow = register),
      });
    }
    ///////////////////////////////////

    this.setState({
      loadingModal: (this.state.loadingModal = false),
    });
  };
  //FIN getAllDetail

  // Handle Modal pagination
  handlePageChangeModal = (e, field) => {
    let value = Number(e.target.value);
    this.setState({
      [field]: (this.state[field] = value),
    });

    // Call to  services
    this.getDetailsPeriod(this.state.itemPeriod);
  };

  handleSizeChangeModal = (e, field) => {
    let value = Number(e.target.value);

    this.setState({
      sizeModal: (this.state.sizeModal = value),
      pageModal: (this.state.pageModal = 1),
    });

    // Call to  services
    // Call to  services
    this.getDetailsPeriod(this.state.itemPeriod);
  };

  handleClose = () => {
    this.setState({
      showModal: (this.state.showModal = false),
      loadingModal: (this.state.loadingModal = false),
      registerModal: (this.state.registerModal = []),
      noDataModal: (this.state.noDataModal = false),
      noDataMes: (this.state.noDataMes = ""),
      itemPeriod: (this.state.itemPeriod = undefined),
      totalPagesModal: 1,
      pageModal: 1,
      sizeModal: 30,
      totalRegisterModal: 0,
    });
  };

  handleShow = () => {
    this.setState({
      showModal: (this.state.showModal = true),
    });
  };

  // Modal details
  onclickResumen = (e, id) => {
    e.preventDefault();
  };

  compareDate = (today, endDate) => {
    today = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
    let day = "";
    let month = "";
    let year = "";

    if (today.getTime() > endDate.getTime()) {
      day = ("00" + endDate.getDate()).slice(-2);
      month = ("00" + (endDate.getMonth() + 1)).slice(-2);
      year = endDate.getFullYear();
    } else {
      day = ("00" + today.getDate()).slice(-2);
      month = ("00" + (today.getMonth() + 1)).slice(-2);
      year = today.getFullYear();
    }

    return day + "-" + month + "-" + year;
  };

  roundValue = (value) => {
    let valueString = "0.00";
    if (value > 0)
      valueString = Math.round((value + Number.EPSILON) * 100) / 100;
    return valueString;
  };

  sumAmountcondition = (listObj) => {
    let acc = 0;
    const temp = listObj.filter(
      (item) => item.idStatus == 1 && item.forCondition.toUpperCase() === "SI"
    );

    return temp.reduce((acc, a) => {
      return acc + a.amount;
    }, 0);
  };

  sumAmountTotalProperty = (item = {}) => {
    let suma = 0;

    for (const key in item) {
      if ( SUM_PROPERTY_FOR_ITEM_REGISTERS.includes(key) ) {
        suma = suma + item[key];
      }
    }

    return Math.round((suma + Number.EPSILON) * 100) / 100;
  }

  render() {
    const {
      loading,
      registers,
      noData,
      noDataMessage,
      loadingModal,
      noDataModal,
      showModal,
      noDataMes,
      registerModal,
      page,
      size,
      totalPages,
      totalRegister,
      totalPagesModal,
      pageModal,
      sizeModal,
      totalRegisterModal,
    } = this.state;
    // console.log(registers)
    let optionPages = [];
    for (let i = 0; i < totalPages; i++) {
      optionPages.push(
        <option key={`pagecom${i}`} value={i + 1}>
          {i + 1}
        </option>
      );
    }

    // Modal
    let optionPagesModal = [];
    for (let i = 0; i < totalPagesModal; i++) {
      optionPagesModal.push(
        <option key={`pagemod${i}`} value={i + 1}>
          {i + 1}
        </option>
      );
    }

    return (
      <div style={{ padding: 30 }}>
        {/* Paginador */}
        <Row>
          <Form.Label column xs="auto" className="pe-0">
            Mostrando del {size * (page - 1) + 1} al{" "}
            {size * page > totalRegister ? totalRegister : size * page} de{" "}
            {totalRegister} registros. &nbsp;&nbsp;| Página
          </Form.Label>
          <Col xs="auto" className="pe-0">
            <Form.Select
              id="selecPagesComision"
              value={page}
              onChange={(e) => this.handlePageChange(e, "page")}
              size="sm"
            >
              {optionPages}
            </Form.Select>
          </Col>
          <Form.Label column xs="auto" className="pe-0">
            {" "}
            de {totalPages}. Por página:{" "}
          </Form.Label>
          <Col xs="auto" className="pe-0">
            <Form.Select
              id="selectCountcomision"
              defaultValue={"30"}
              size="sm"
              onChange={(e) => this.handleSizeChange(e, "size")}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </Form.Select>
          </Col>
          {/* <Form.Label column sm={6}>
              Mostrando del {size * (page - 1) + 1} al{" "}
              {size * page > totalRegister ? totalRegister : size * page} de{" "}
              {totalRegister} registros. &nbsp;&nbsp;| Página {" "}
              <Form.Select
                id="selecPagesComision"
                value={page}
                style={{ fontSize: 10 }}
                onChange={(e) => {
                  this.handlePageChange(e, "page");
                }}
                size="sm"
                style={{display:"inline",width:"auto"}}
              >
                {optionPages}
              </Form.Select>
              {" "} de {totalPages}.
              Por página: {" "}
              <Form.Select
              id="selectCountcomision"
              defaultValue={"30"}
              size="sm"
              style={{ fontSize: 10 }}
              onChange={(e) => {
                this.handleSizeChange(e, "size");
              }}
              style={{display:"inline",width:"auto"}}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </Form.Select>
            </Form.Label> */}
          {/* Page */}
        </Row>
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey="#comission1"
        >
          <Row>
            <Col sm={5} md={3} lg={3}>
              <div style={{ height: "100vh", overflow: "auto" }}>
                <ListGroup action horizontal="xs" href="#comission1">
                  {!loading &&
                    !noData &&
                    registers.map((item, idx) => {
                      // verfiy update
                      let today = new Date();
                      let endDate = new Date(item.endDate);
                      let update = this.compareDate(today, endDate);
                      let amount = Math.round((item.amounthTotal + Number.EPSILON) * 100) / 100;
                      // let amount = this.sumAmountTotalProperty(item);

                      return (
                        <ListGroup.Item
                          active={
                            this.state.itemPeriod &&
                            this.state.itemPeriod.id === item.id
                              ? true
                              : false
                          }
                          onClick={(e) => this.onclickDetails(e, item)}
                        >
                         {/*  COMISION INDIVIDUAL */}
                          <div>
                            <Row>
                              <Form.Label column>
                                Periodo:{" "}
                                {Validation.formatDate(item.initialDate)} al{" "}
                                {Validation.formatDate(item.endDate)}
                              </Form.Label>
                            </Row>
                            <Row>
                              <Form.Label column>
                                Actualizado al: {update}
                              </Form.Label>
                            </Row>
                            <Row>
                              <Form.Label>Monto: ${amount}</Form.Label>
                            </Row>
                          </div>
                             {/*  COMISION INDIVIDUAL */}
                        </ListGroup.Item>
                      );
                    })}
                  {loading && (
                    <LoadApi
                      indicatorLoad={true}
                      message="Cargando registros..."
                    ></LoadApi>
                  )}
                </ListGroup>
              </div>
            </Col>
            <Col xs={10} sm={7} md={9} lg={9}>
              {loadingModal && (
                <LoadApi
                  indicatorLoad={true}
                  message="Cargando registro..."
                ></LoadApi>
              )}
              {!loadingModal && noDataModal && (
                <Row sty>
                  <Col sm={12}>
                    <Form.Label>{noDataMes}</Form.Label>
                  </Col>
                </Row>
              )}
              {!loadingModal && !noDataModal && this.state.itemPeriod && (
                <div style={{ textAlign: "left", paddingTop: "20px" }}>
                  <Row>
                    <Col sm={12}>
                      <Tabs
                        className="custom-tabs-main"
                        defaultActiveKey="resume"
                      >
                        <Tab eventKey="resume" title="Resumen ">
                          <hr />
                          <div
                            style={{
                              textAlign: "center",
                              borderRadius: 5,
                              padding: 20,
                              background: "#dde6ef",
                            }}
                          >
                            {this.state.itemPeriod && (
                              <div>
                               {/*  {console.log(
                                  "this.state.itemPeriod Resumen =>",
                                  this.state.itemPeriod
                                )} */}
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Monto:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`$ ${Math.round(
                                      (this.state.itemPeriod.amounthTotal +
                                        Number.EPSILON) *
                                        100
                                    ) / 100}`}</Form.Label>
                                  </Col>
                                </Row>

                                <hr />

                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Recomendación Directa:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod
                                        .amountrecomendationdirect
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountrecomendationdirect
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Equipo:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod
                                        .amountRecomendationteam
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountRecomendationteam
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Pago de Cuota:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod.amountquotes &&
                                      Number(AuthService.getCurrentIdUser()) ===
                                        12853
                                        ? this.roundValue(
                                            this.state.itemPeriod.amountquotes
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono Membresias Extras:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod
                                        .amountMembershipsextras
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountMembershipsextras
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Migración:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod.amountmigrations
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountmigrations
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Logro de Rango:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod.amountawardrange
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountawardrange
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                {/* Comisiones Me */}
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Bono de Marcas Exclusivas:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod.amountExlusiveBrands
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountExlusiveBrands
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                                {/* Pago Bono */}
                                <Row>
                                  <Col sm={6} style={{ textAlign: "end" }}>
                                    <Form.Label
                                      style={{ fontSize: 14 }}
                                    >{`Pago Bono:`}</Form.Label>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Label style={{ fontSize: 14 }}>{`$ ${
                                      this.state.itemPeriod &&
                                      this.state.itemPeriod.amountExlusiveBrands
                                        ? this.roundValue(
                                            this.state.itemPeriod
                                              .amountbonus
                                          )
                                        : "0.00"
                                    } `}</Form.Label>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </div>
                        </Tab>
                        {/* <Tab eventKey="resume" title="Resumen">
                        <hr />
                        <div style={{textAlign: 'center', borderRadius: 5, padding:20, background:'#dde6ef'}}>
                           {this.state.itemPeriod && (
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label
                                  style={{ fontSize: 14 }}
                                >{`Periodo:`}</Form.Label>
                                <br></br>
                                <Form.Label
                                  style={{ fontSize: 16 }}
                                >{`Del ${Validation.formatDate(
                                  this.state.itemPeriod.initialDate
                                )} al ${Validation.formatDate(
                                  this.state.itemPeriod.endDate
                                )}`}</Form.Label>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        {this.state.itemPeriod && (
                          <Row>
                            <Col sm={6}>
                              <Form.Label style={{ fontSize: 14 }}>{`Actualizado al: ${this.compareDate(
                                new Date(),
                                new Date(this.state.itemPeriod.endDate)
                              )}`}</Form.Label>
                            </Col>
                            <Col sm={6}>
                              <Form.Label style={{ fontSize: 14 }}>{`Monto: $ ${Math.round(
                                (this.state.itemPeriod.amounthTotal +
                                  Number.EPSILON) *
                                  100
                              ) / 100}`}</Form.Label>
                            </Col>
                          </Row>
                        )}
                        </div>
                       
                        <hr />
                        <Row>
                          <Col sm={12}>
                            <div className="box-container-wrap">
                              <div className="card-comission">
                                <Form.Label>{`Bono de Recomendación Directa`}</Form.Label>
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listDirecta
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listDirecta
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                              <div className="card-comission">
                                <Form.Label>{`Bono de Equipo`}</Form.Label>
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listEquipo
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listEquipo
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                              <div className="card-comission">
                                <Form.Label>{`Bono de Pago de Cuota `}</Form.Label>
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listCuota
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listCuota
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                              <div className="card-comission">
                                <Form.Label>{`Bono Membresias extras`}</Form.Label>{" "}
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listMembresia
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listMembresia
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                              <div className="card-comission">
                                <Form.Label>{`Bono de Migracion`}</Form.Label>{" "}
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listMigracion
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listMigracion
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                              <div className="card-comission">
                                <Form.Label>{`Bono de Logro de Rango `}</Form.Label>{" "}
                                <Form.Label style={{ fontSize: 16 }}>{`$ ${
                                  this.state.itemShow &&
                                  this.state.itemShow.listRango
                                    ? this.roundValue(
                                        this.sumAmountcondition(
                                          this.state.itemShow.listRango
                                        )
                                      )
                                    : "0.00"
                                } `}</Form.Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab> */}
                        <Tab eventKey="directa" title="Recomendación Directa">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Nivel</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                               {/*  {console.log(
                                  "ver this.state.itemShow Recomendación Directa =>",
                                  this.state.itemShow
                                )} */}
                                {this.state.itemShow &&
                                  this.state.itemShow.listDirecta.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        <Tab eventKey="equipo" title="Bonificacion de Equipo">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {/*  {console.log('ver this.state.itemShow  =>',this.state.itemShow)} */}
                                {this.state.itemShow &&
                                  this.state.itemShow.listEquipo.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        <Tab eventKey="cuota" title="Pago de Cuota">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.itemShow &&
                                  this.state.itemShow.listCuota.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        <Tab eventKey="membresia1" title="Segunda Membresias">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.itemShow &&
                                  this.state.itemShow.listMembresia.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        {/* menbresia */}
                        <Tab eventKey="membresia2" title="Membresias Extras">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.itemShow &&
                                  this.state.itemShow.listMembres.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateRegister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        {/* ......... */}
                        <Tab eventKey="migracion" title="Migración">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                               {/*  {console.log(
                                  "haber listMigracion ",
                                  this.state.itemShow.listMigracion
                                )} */}
                                {this.state.itemShow &&
                                  this.state.itemShow.listMigracion.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        <Tab eventKey="rango" title="Logro de Rango">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.itemShow &&
                                  this.state.itemShow.listRango.map(
                                    (itemRegister, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemRegister.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemRegister.nameSlave}{" "}
                                            {itemRegister.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemRegister.typeBonusDescription}
                                          </td>
                                          <td>{itemRegister.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemRegister.dateregister
                                            )}
                                          </td>
                                          <td>{itemRegister.points}</td>
                                          <td>{itemRegister.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemRegister.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemRegister.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemRegister.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                        <Tab eventKey="mekeola" title="COMISIONES PRODUCTOS">
                          <MEProductos  listMarcasExclusivas={this.state.itemShow?.listMarcasExclusivas || []}/>
                        </Tab>
                        <Tab eventKey="bocomisionbono" title="COMISIONES BONOS">
                          <section className="mt-2">
                            <Table responsive size="sm">
                              <thead className="table-head-comision">
                                <tr>
                                  <th></th>
                                  <th>Nombre</th>
                                  <th>Tipo de Comisión</th>
                                  <th>Nivel</th>
                                  <th>Fecha</th>
                                  <th>Puntaje</th>
                                  <th>Porcentaje (%)</th>
                                  <th>Monto</th>
                                  <th>Por Estado</th>
                                  <th>Por Tipo de Membresía</th>
                                  {/* <th>Por Nivel</th> */}
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.itemShow &&
                                  this.state.itemShow.listBonos.map(
                                    (itemBono, idx) => {
                                      let amount =
                                        Math.round(
                                          (itemBono.amount +
                                            Number.EPSILON) *
                                            100
                                        ) / 100;
                                      let style = {
                                        color: "#fff",
                                        background: "#3897c3",
                                      };
                                      return (
                                        <tr key={idx}>
                                          <td>
                                            {sizeModal * (pageModal - 1) +
                                              1 +
                                              idx}
                                          </td>
                                          <td>
                                            {itemBono.nameSlave}{" "}
                                            {itemBono.lastNameSlave}
                                          </td>
                                          <td>
                                            {itemBono.typeBonusDescription}
                                          </td>
                                          <td>{itemBono.levelsponsor}</td>
                                          <td>
                                            {Validation.formatDate(
                                              itemBono.dateregister
                                            )}
                                          </td>
                                          <td>{itemBono.points}</td>
                                          <td>{itemBono.percents}</td>
                                          <td>$ {amount}</td>
                                          <td
                                            style={
                                              itemBono.idstatus === 1
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemBono.idstatus === 1
                                              ? "SI"
                                              : "NO"}
                                          </td>
                                          <td
                                            style={
                                              itemBono.forcondition.toUpperCase() ===
                                              "SI"
                                                ? style
                                                : {}
                                            }
                                          >
                                            {itemBono.forcondition}
                                          </td>
                                          {/* <td>{item.forLevel}</td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </Table>
                          </section>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}
