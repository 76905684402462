import React, { Component } from "react";
import { Form, Table, Button, Spinner, Modal, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Autosuggest from "react-autosuggest";
import AutosuggestCustom from "./AutosuggestCustom";

import Validation from "../utils/Validation";
import UserService from "../../services/user.service";
// import DropSuggest from './DropSuggest';

export default class Placement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [],
      value: "",
      loadRegisters: false,
      loading: true,
      noData: false,
      noDataMesssage: false,
      tree: [],
      idsUpliner: [],
      listUpliner: [],
      suggestions: [],
      loadSenData: false,
      loadConfirm: false,
      itemSaved: undefined,
    };

    this.getRegister = this.getRegister.bind(this);
    this.getListAvailable = this.getListAvailable.bind(this);
  }

  componentDidMount() {
    this.getRegister();
  }

  componentWillReceiveProps(props) {
    // From register step 3: select suscription
    this.setState({
      tree: (this.state.tree = props.data),
    });

    // Convert tree into list only with the child have < 3 children and the state team==SI
    let listTree = [];
    let idsUpliner = [];

    // verify the root
    let tree = this.state.tree;
    if (tree.length > 0) {
      if (tree[0].children.length < 3) {
        listTree.push(tree[0]);
        idsUpliner.push(tree[0].id);
      }
      this.getListAvailable(tree[0].children, listTree, idsUpliner);
    }

    // order list accordign to level
    listTree.sort(function(a, b) {
      return a.nivel - b.nivel;
    });
    this.setState({
      suggestions: (this.state.suggestions = listTree),
      idsUpliner: (this.state.idsUpliner = idsUpliner),
    });
  }
  getListAvailable = (children, listTree, idsUpliner) => {
    let list = [];
    for (let i = 0; i < children.length; i++) {
      if (
        children[i].children.length < 3 &&
        children[i].team !== undefined &&
        children[i].team.toUpperCase().includes("SI")
      ) {
        listTree.push(children[i]);
        idsUpliner.push(children[i].id);
      }
      //get children
      this.getListAvailable(children[i].children, listTree, idsUpliner);
    }
    // return list;
  };

  // Get register for the table: user uvailable
  async getRegister() {
    let response = await UserService.getRegPlacement();
    if (response !== undefined && response !== null) {
      if (response.status !== 1) {
        //;
        this.setState({
          registers: (this.state.registers = []),
          loading: (this.state.loading = false),
          loadRegisters: (this.state.loadRegisters = true),
          noData: (this.state.noData = true),
          noDataMesssage: (this.state.noDataMessage =
            "Ocurrió un problema mientras obteníamos los registros. Inténtelo más tarde."),
        });
      } else {
        if (response.objModel.length > 0) {
          this.setState({
            registers: (this.state.registers = response.objModel),
            loading: (this.state.loading = false),
            loadRegisters: (this.state.loadRegisters = true),
            noData: (this.state.noData = false),
            noDataMesssage: (this.state.noDataMessage = ""),
          });
        } else {
          this.setState({
            registers: (this.state.registers = response.objModel),
            loading: (this.state.loading = false),
            loadRegisters: (this.state.loadRegisters = false),
            noData: (this.state.noData = true),
            noDataMesssage: (this.state.noDataMessage =
              "No hay registros para mostrar."),
          });
        }
      }
    }
  }

  // Set position with post services
  setPosition = async (e, item) => {
    //Verify if upliner is selected;
    if (item.idUpliner !== undefined && item.idUpliner > 0) {
      // Change state
      this.setState({
        loadConfirm: (this.state.loadConfirm = true),
        itemSaved: (this.state.itemSaved = item),
      });
      // consume services without confirm
      // this.savePosition(item);
    } else {
      alert("Seleccione un upliner");
    }
  };

  savePosition = async (item) => {
    this.setState({
      loadSenData: (this.state.loadSenData = true),
    });

    let data = {
      idUpliner: item.idUpliner,
      idSon: item.idSon,
    };

    let response = await UserService.saveUpliner(data);
    console.log('response save po', item, response);
    // this.updateData(item);

    if (response !== undefined) {
      this.setState({
        loadSenData: (this.state.loadSenData = false),
      });
      if (response.status === 1) {
        // update suggestion
        this.updateData(item);
        alert("La información ha sido guardada correctamente.");
      } else {
        alert("No pudimos guardar su información. Inténtelo más tarde.");
      }
    } else {
      this.setState({
        loadSenData: (this.state.loadSenData = false),
      });
      alert("Tuvimos un error al guardar la información. Inténtelo más tarde.");
    }
  };

  // modal confirm
  handleCloseConfirm = () => {
    this.setState({
      loadConfirm: (this.state.loadConfirm = false),
      itemSaved: (this.state.itemSaved = undefined),
    });
  };

  handleConfirm = (e) => {
    this.setState({
      loadConfirm: (this.state.loadConfirm = false),
    });

    let savedData = this.state.itemSaved;
    this.savePosition(savedData);
  };
  /**
   * Update data after save  registration of upliner
   * @param {*} item
   */
  updateData = (item) => {
    // For looking in register item.IdSon and delete
    let register = this.state.registers;
    let temp = [];
    for (let i = 0; i < register.length; i++) {
      if (register[i].idSon !== item.idSon) {
        // TOD update upliners set in null
        register[i].idUpliner = 0;
        register[i].upliner = 0;

        temp.push(register[i]);
      }
    }
    // set suggestions until tree services response
    this.setState({
      registers: (this.state.registers = temp),
      loadSenData: (this.state.loadSenData = false),
      suggestions: (this.state.suggestions = []),
    });

    // Update tree residual
    if (this.props.updateTree) {
      this.props.updateTree("Actualizando lista de upliners.", true);
    }
  };

  /**
   * Update data after save  registration of upliner
   * @param {*} item
   */
  // updateData = (item) => {
  //     // For looking in register item.IdSon and delete
  //     let register = this.state.registers;
  //     let temp = []
  //     for(let i=0; i < register.length; i++) {
  //         if(register[i].idSon !== item.idSon) {
  //             temp.push(register[i])
  //         }
  //     }
  //     this.setState({
  //         registers : this.state.registers = temp
  //     });

  //     // looking into suggestion with ididUpliner and verify their child
  //     // idUpliner is idHijo in the array suggestion
  //     let idsUpliner = [];
  //     let suggestions = this.state.suggestions;
  //     let tempSug = []
  //     for(let i=0; i < suggestions.length; i++) {
  //         if(suggestions[i].idsocio === item.idUpliner) {
  //             //Verify children
  //             if(suggestions[i].children.length < 2) {
  //                 // if it < 2 => push item
  //                 suggestions[i].children.push({id: item.idSon});
  //                 tempSug.push(suggestions[i]);
  //             }
  //             // else {
  //             //     // else no consider in th enew array
  //             // }
  //         } else {
  //             tempSug.push(suggestions[i]);
  //             idsUpliner.push(suggestions[i].id);
  //         }
  //     }

  //     this.setState({
  //         suggestions : this.state.suggestions = tempSug,
  //         idsUpliner : this.state.idsUpliner = idsUpliner
  //     });

  // }

  selectUpliner = (item, upliner) => {
    // Receive item from autosuggestcustom
    //Update register in the list
    let register = this.state.registers;
    let flag = false;

    for (let i = 0; i < register.length; i++) {
      if (register[i].idSon === item.idSon) {
        if (upliner !== undefined) {
          register[i].idUpliner = upliner.id;
          register[i].upliner = upliner;
          flag = true;
        } else {
          register[i].idUpliner = -1;
          register[i].idUpliner = undefined;
          flag = true;
        }
      }
    }

    if (!flag) {
      alert("Registro no disponible.");
    } else {
      this.setState({
        registers: (this.state.registers = register),
      });
    }
  };

  handleSelectUpLiner = (e, item) => {
    //Update register in the list
    let register = this.state.registers;
    let name = e.target.options[e.target.selectedIndex].text;
    let id = e.target.value;
    let flag = false;

    for (let i = 0; i < register.length; i++) {
      if (register[i].idSon === item.idSon) {
        register[i].idUpliner = Number(id);
        flag = true;
      }
    }

    if (!flag) {
      alert("Registro no disponible.");
    } else {
      this.setState({
        registers: (this.state.registers = register),
      });
    }
  };

  getState = (idState) => {
    let description = "-";
    switch (idState) {
      case 0:
        // Inactivo
        description = "Inactivo";
        break;
      case 1:
        // Patrocinados directos
        description = "Activo";
        break;
      case 2:
        // Pendiente de validación inicial
        description = "Pendiente";
        break;
      case 3:
        // Rechazo de incial";
        description = "Inicial rechazado";
        break;
      case 4:
        // Pagar despues"
        description = "Pago después";
        break;
      case 5:
        // Deuda 1";
        // Pagar despues"
        description = "Deuda 1";
        break;
      case 6:
        // Deuda 2";
        description = "Deuda 2";
        break;
      case 7:
        // Deuda 3";
        description = "Deuda 3";
      case 8:
        description = "Preliquidacion";
        break;
      case 9:
        // Congelado";
        description = "Congelado";
        break;
      case 10:
        // Pendiente de validación";
        description = "Pendiente";
        break;
      case 11:
        // Pago rechazado";
        description = "Cuota rechazado";
        break;
      case 12:
        // Suscription finalizada";
        description = "Finalizado";
        break;
      case 13:
        description = "Pendiente";
        break;
      case 14:
        // Suscription finalizada";
        description = "Rechazo";
        break;
      case 15:
        // Suscription finalizada";
        description = "Liquidacion";
        break;
    }

    return description;
  };

  render() {
    const {
      registers,
      suggestions,
      value,
      loadRegisters,
      loading,
      noData,
      noDataMesssage,
      loadSenData,
      loadConfirm,
      itemSaved,
    } = this.state;
    
    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            <Form.Label className="content-subtitle">Posicionar</Form.Label>
          </Col>
        </Row>
        <Table responsive>
          <thead className="table-head">
            <tr>
              <th>Nº</th>
              <th>Usuario</th>
              <th>Nombres y Apellidos</th>
              <th>Fecha</th>
              <th>Tipo de Membresía</th>
              <th>Estado</th>
              <th>Upliner</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={6}>
                  <div>
                    <Spinner animation="border" variant="dark"></Spinner>
                    <p>Cargando registros...</p>
                  </div>
                </td>
              </tr>
            )}
            {loadRegisters &&
              this.state.registers.map((item, idx) => {
                let date = "";

                let value =
                  item.upliner !== undefined && typeof item.upliner == "object" ? item.upliner.title : "";
                  console.log('vl', item, value);
                return (
                  <tr key={item.idSon}>
                    <td>{idx + 1}</td>
                    <td>
                      <div style={{ width: "150px", wordBreak: "break-all" }}>
                        {item.username}
                      </div>
                    </td>
                    <td>
                      <div style={{ width: "200px", wordBreak: "break-all" }}>
                        {item.name} {item.lastname}
                      </div>
                    </td>
                    <td>{Validation.convertDateToString(item.createDate)}</td>
                    <td>{item.packageName}</td>
                    <td>{this.getState(item.idState)}</td>

                    <td align={"left"}>
                      {item.uplinerEnabled === 1 && (
                        <AutosuggestCustom
                          id={item.idSon}
                          value={value}
                          item={item}
                          list={suggestions}
                          selectUpliner={this.selectUpliner}
                        ></AutosuggestCustom>
                      )}
                    </td>
                    <td>
                      {item.uplinerEnabled === 1 && (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={(e) => {
                            this.setPosition(e, item);
                          }}
                        >
                          Posicionar
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}

            {noData && (
              <tr>
                <td colSpan="6">
                  <Form.Label>{noDataMesssage}</Form.Label>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={6}>
                <div style={{ height: "400px" }}></div>
              </td>
            </tr>
          </tbody>
        </Table>
        {/* loading */}
        <Modal
          show={loadSenData}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; Guardando información...</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
        {/* confirmacion */}
        <Modal show={loadConfirm} onHide={this.handleCloseConfirm}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {itemSaved !== undefined && (
              <div>
                ¿Desea posicionar a{" "}
                <b>
                  {itemSaved.name} {itemSaved.lastname}
                </b>{" "}
                debajo de <b>{itemSaved.upliner.title}</b>?
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseConfirm}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={(e) => this.handleConfirm(e)}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
