import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

function Factura({ modalFactura, setModalFactura, setForm }) {
  console.log("modalFactura =>", modalFactura);
  //const [show, setShow] = useState(modalFactura);

  const handleClose = () => setModalFactura(false);
  const handleShow = () => setModalFactura(true);
  const fecha = new Date();
  const hoy = fecha.getDate();

  const [datosFacturacion, setDatosFacturacion] = useState({
    id: 0,
    idDocument: 0,
    document: "",
    businessName: "",
    industry: "",
    address: "",
    email: "",
    phone: "",
    nameLegalRepresent: "",
    phoneLegalRepresent: "",
    idCountry: 333,
    insertDate: fecha,
    updateDate: fecha,
    delete: 0,
    idInsertUser: 0,
    idUpdateUser: 0,

  });
  /* "business": {
    "id": 0,

    "idDocument": 0,
    "document": "string",
    "businessName": "string",
    "industry": "string",
    "address": "string",
    "email": "string",
    "phone": "string",
    "nameLegalRepresent": "string",
    "phoneLegalRepresent": "string",
    "idCountry": 0

    "insertDate": "2022-10-14T22:27:13.747Z",
    "updateDate": "2022-10-14T22:27:13.747Z",
    "delete": 0,
    "idInsertUser": 0,
    "idUpdateUser": 0,
    
  } */
  const {
    id,
    idDocument,
    document,
    businessName,
    industry,
    address,
    email,
    phone,
    nameLegalRepresent,
    phoneLegalRepresent,
    idCountry,
    insertDate,
    updateDate,
    idInsertUser,
    idUpdateUser,
  } = datosFacturacion;

  const enviarInput = (e) => {
    setDatosFacturacion({
      ...datosFacturacion,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    //error
    if (
      document.trim() === "" ||
      businessName.trim() === "" ||
      industry.trim() === "" ||
      address.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === "" ||
      nameLegalRepresent.trim() === "" ||
      phoneLegalRepresent.trim() === ""
    ) {
      alert("Todos los campos son obligatorios");

      console.log("error");
      return;
    }
    //console.log(sesion);
    // iniciarSesion({ correo, clave });
    console.log("formulario guardado ");
    setForm(datosFacturacion);
    handleClose();
  };

  return (
    <>
      <Modal show={modalFactura} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Datos de Facturacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="lead">Datos de la empresa</h4>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="mb-3">
              <Form.Label>RUC(Empresa)</Form.Label>
              <Form.Control
                type="text"
                placeholder="RUC de la empresa"
                name="document"
                value={document}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Razón social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="businessName"
                value={businessName}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Giro/Industria</Form.Label>
              <Form.Control
                type="text"
                placeholder="Giro/Industria"
                name="industry"
                value={industry}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dirección"
                name="address"
                value={address}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                placeholder="Correo electrónico"
                name="email"
                value={email}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Teléfono"
                name="phone"
                value={phone}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nombre de representante legal (opcional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de representante legal"
                name="nameLegalRepresent"
                value={nameLegalRepresent}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Telefono de representante legal (opcional)
              </Form.Label>
              <Form.Control
                type="tel"
                placeholder="Telefono de representante legal"
                name="phoneLegalRepresent"
                value={phoneLegalRepresent}
                onChange={(e) => enviarInput(e)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Guardar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Factura;
