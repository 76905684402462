import React, { Component } from "react";
import { Form, Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import UserService from "../../services/user.service";
import history from "../navigation/history";
import PayQuote from "../../components/payment/PayQuote";
import utilsService from "../../services/utils.service";

/**
 * Component to register payments usign the link from the email
 */
export default class RegisterQuoteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      idSuscription: 0,
      suscription: {},
      loading: true,
      noData: false,
      vouchers: [],
      isPaid: false,
      loadSenData: false,
      isComplete: false,
      objPayPal: {
        isPaid: undefined,
        mountPaid: undefined,
        creationDate: undefined,
        modificationDate: undefined,
        idTransaction: undefined,
        intent: undefined,
        status: undefined,
      },
      walletAmount: 0,
      messageError: "",
      typeExchange: {
        venta: 1,
      },
      pay: 0,
      // Periodo de gracia
      ApplyGracePeriod: 0,
      GracePeriodDays: 0,
      totalPay2: 0,
      // Cupon
      ApplyCoupon: 0,
      OverdueCoupon: "",
      // dias
      NewExpirationDate: "0001-01-01T00:00:00",
      //
      dataPG:{},
      totalPagar:null,
      totalOverdue: 0,
      flagSchedule: 1,
      percentOverdueDetailId: 0,
      aplicategracePeriod: 0,
    };

    this.quoteToPay = this.quoteToPay.bind(this);
    this.getToken = this.getToken.bind(this);

    // Create reference to Paypal
    this.childPaypal = React.createRef();
  }

  componentDidMount() {
    this.getInfoURL();
    this.getExchange();
  }
  async getExchange() {
    let response = await utilsService.getExchanges(); //UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }

  getInfoURL() {
    let url = window.location;
    let params = url.search;
    let listParams = params.split("="); //queryString.parse(params);
    // If the URL is from email with token
    if (listParams.length === 2) {
      let token = listParams[1];
      this.getToken(token);
    } else if (listParams.length === 5) {
      // Load services to get information of payment
      let idSuscription = listParams[4];
      // console.log(idSuscription)
      let idMembershipDetail = listParams[2];

      this.getSuscription(idSuscription, idMembershipDetail);
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
        noDataMessage: "No pudimos obtener información.",
      });
    }
  }

  async getSuscription(idSuscription, idMembershipDetail) {
    // get suscription information
    this.setState({
      loading: (this.state.loading = true),
    });
    let response = await UserService.getSuscriptionById(idSuscription);
    // console.log(response)
    if (response !== undefined && response.status === 1) {
      this.getMemberShipInformation(
        idSuscription,
        idMembershipDetail,
        response.objModel
      );
    } else {
      this.setState({
        quote: undefined,
        noData: true,
        noDataMessage: "No pudimos obtener información.",
      });
    }
    this.setState({
      loading: false,
    });
  }

  // get informaction with id
  async getMemberShipInformation(
    idSuscription,
    idMembershipDetail,
    suscription
  ) {
    this.setState({
      loading: true,
    });
    console.log('ver idMembershipDetail =>',idMembershipDetail)
    let response = await UserService.getInfoMemberShip(idMembershipDetail);
    // console.log(response)
    this.setState({ loading: false });

    //
    if (response !== undefined) {
      if (response.status === 1) {
        // verify quotes's status

        //Pagado
        if (response.objModel.verif === 1) {
          // Pagado
          this.setState({
            loading: false,
            quote: undefined,
            noData: true,
            noDataMessage: `La ${response.objModel.quoteDescription} ya ha sido PAGADA.`,
          });
        } else if (
          response.objModel.verif === 2 ||
          response.objModel.verif === 10
        ) {
          // Pendiente de validacion
          this.setState({
            loading: false,
            quote: undefined,
            noData: true,
            noDataMessage: `La ${response.objModel.quoteDescription} esta PENDIENTE DE VALIDACION.`,
          });
        } else if (
          response.objModel.verif === 0 ||
          response.objModel.verif === 3 ||
          response.objModel.verif === 11
        ) {
          // Aun no pagado o rechazado

          let quote = {
            IdPayment: response.objModel.idPayment,
            idSuscription: Number(idSuscription),
            idPaquete: suscription.package.id,
            idUser: suscription.idUser,
            namePackage: suscription.package.description,
            quoteUsd: response.objModel.quoteUsd,
            quoteDescription: response.objModel.quoteDescription,
            isQuoteInitial: response.objModel.isQuoteInitial,
            totalOverdue: response.objModel.totalOverdue,
            totalPay: response.objModel.totalPay,
            daysOverdue:response.objModel.daysOverdue,
          };
        console.log('VER DATA quote =>',quote)
          // console.log(quote)
          this.setState({
            loading: false,
            quote: quote,
            noData: false,
          });
        }
      } else if (response.status === 0) {
        this.setState({
          loading: false,
          quote: undefined,
          noData: true,
          noDataMessage: "Token de pago expiró.",
        });
      } else {
        this.setState({
          loading: false,
          quote: undefined,
          noData: true,
          noDataMessage:
            "Ocurrió un problema al obtener información. Regrese más tarde.",
        });
      }
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
        noDataMessage:
          "Ocurrió un problema al obtener información. Regrese más tarde.",
      });
    }
  }

  // Get token
  async getToken(token) {
    if (token !== undefined) {
      let response = await UserService.getInfoQuotePayment(token);
      // console.log("respuesta pago")
      // console.log(response)
      //
      if (response !== undefined) {
        if (response.status === 1) {
          // TODO service get info by token should return idstate then remove this line
          // this.setState({
          //     loading: this.state.loading = true,
          // });
          this.getSuscription(
            response.objModel.idSuscription,
            response.objModel.idPayment
          );
        } else if (response.status === 0) {
          this.setState({
            loading: false,
            quote: undefined,
            noData: true,
            noDataMessage: "Token de pago expiró.",
          });
        } else {
          this.setState({
            loading: false,
            quote: undefined,
            noData: true,
            noDataMessage:
              "Ocurrió un problema al obtener información. Regrese más tarde.",
          });
        }
      } else {
        this.setState({
          loading: false,
          quote: undefined,
          noData: true,
          noDataMessage:
            "Ocurrió un problema al obtener información. Regrese más tarde.",
        });
      }
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
        noDataMessage: "No pudimos obtener información.",
      });
    }
  }

  // verify local storage  TODO change form calll services with
  quoteToPay() {
    if (
      localStorage.length > 0 &&
      localStorage.getItem("currentQuote") !== null
    ) {
      if (localStorage.getItem("currentQuote").length > 0) {
        let quote = JSON.parse(localStorage.getItem("currentQuote"));
        this.setState({
          loading: false,
          quote: quote,
          noData: false,
        });

        return quote;
      } else {
        this.setState({
          loading: false,
          quote: undefined,
          noData: true,
        });
        return undefined;
      }
    } else {
      this.setState({
        loading: false,
        quote: undefined,
        noData: true,
      });

      return undefined;
    }
  }

  // TODO change for only call send dato from paypal is payment is proccesed
  /**
   * Event to handle the paypal's payment and vouchers
   * @param {*} data
   * @param {*} field
   */
  eventPaypal = (data, field) => {
    if (field === "vouchers") {
      this.setState({
        [field]: data,
      });
    } else {
      let obj = this.state.objPayPal;

      obj[field] = data;
      this.setState({
        objPayPal: obj,
      });
      //verify if all field are not undefined
      this.verifyObjetPaypal(obj);
    }
  };

  verifyObjetPaypal = (obj) => {

    if (
      obj.isPaid !== undefined &&
      obj.mountPaid !== undefined &&
      obj.creationDate !== undefined &&
      obj.modificationDate !== undefined &&
      obj.idTransaction !== undefined &&
      obj.intent !== undefined &&
      obj.status !== undefined
    ) {
      if (obj.isPaid) {
        // save paypal and complete payment
        this.sendData();
      }
    } else {
    }
  };


  validate = () => {
    // verify if the payment was by paypal
    if (this.state.objPayPal.isPaid) {
      if (this.state.objPayPal.status === "COMPLETED") {
        return true;
      } else {
        alert(
          "Su pago con PayPal ha sido rechazado. Seleccione otro medio de pago."
        );
        return false;
      }
    } else if (this.state.vouchers.length > 0) {
      //  console.log("validando this.state.vouchers =>", this.state.vouchers);

      // console.log("validate", this.state.quote)
      let vouchersUpload = this.state.vouchers;
      let cambio = Number(this.state.typeExchange.venta);
      // console.log(cambio);
      let totalamount = Number(0);
      console.log('ver dataPG ==>',this.state.dataPG)
      let amountpay = Object.entries(this.state.dataPG).length === 0 ? Number(this.state.quote.totalPay) : this.state.dataPG.totalPay; //this.state.quote.quoteUsd
      console.log('amountpay ==>',amountpay)
      
      let comision = Number(0);
      // console.log("vouchersUpload ==>", vouchersUpload);
      //console.log("vouchersUpload[i].amount ==>", vouchersUpload[0].amount);
      if (vouchersUpload.length > 0) {
        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          //   console.log("vouchersUpload[i].amount ==>", vouchersUpload[i].amount);
          let moneda = Number(vouchersUpload[i].typeExchange);
          
          let cantidad = Number(
            moneda === 3
              ? vouchersUpload[i].amount?.replace(",", "")
              : vouchersUpload[i].amount
          );
          //  console.log("cantidad =>", cantidad);
          //console.log("moneda =>", moneda);
          if (moneda === 1) {
            //dolar
            totalamount += cantidad;
            comision = comision + Number(vouchersUpload[i].comision); //comision dolares
          } else if (moneda === 2) {
            //moneda=2 sol
            cantidad /= cambio; //convertir sol a dolar
            totalamount += cantidad;
            comision =
              comision +
              Number(Number(vouchersUpload[i].comisionExchange) / cambio); //comision soles
          } else if (moneda === 3) {
            //pesos colombianos
            cantidad /= 4802.97; //convertir peso a dolar
            totalamount += cantidad;
            comision =
              comision +
              Number(Number(vouchersUpload[i].comisionExchange) / 4802.97); //comision pesos
          }
        }
        //let montowallet=Number(sessionStorage.getItem("amountWallet"));
        let montowallet = Number(this.state.walletAmount);

        if (montowallet !== undefined) {
          totalamount += montowallet;
        }
        //agregando comision al monto total
        amountpay += comision;
        totalamount = Number(totalamount);
        // console.log(vouchersUpload)
        // console.log(cambio)
        // console.log(comision)
           console.log("totalamount  lo que escribes=>", totalamount);
          console.log('amountpay =>',amountpay);
          /////////////////////////////////////////////////////////////////////////////////////////////////////
          console.log(totalamount, comision, amountpay);
          if (comision > 0) {
            if (Math.round(totalamount + comision) < Math.round(amountpay + comision) || Math.round(totalamount + comision) > Math.round(amountpay + comision) ) {
              alert("El monto total ingresado no coincide con el monto a pagar");
              return false;
            }
            
          }
        /*if (totalamount < amountpay - 1 || totalamount > amountpay + 3) {
          // comision
          if (comision > 0) {
            alert(`Tienes una comisión de $${comision.toFixed(2)} dolares`);
            return false;
          }
          alert("El monto total ingresado no coincide con el monto a pagar ");
          return false;
        }*/
      }

      return true;
    } else {
      alert("Seleccione un medio de pago.");
      return false;
    }
  };

  eventAddWallet = (monto) => {
    this.setState({
      walletAmount: monto,
    });
  };
  /**
   * Register pay by tickets or paypal
   */
  // Paypal = 1,
  // Vouchers = 2,
  // Wallet = 3,
  // Mixto = 4,
  // Otros = 5,
  
  funcionJalarDatosDePG = (datos)=>{
    console.log('ver Datos funcionJalarDatosDePG=>',datos);
    this.setState({
      dataPG:datos,
    })
      }

  sendData = async () => {
    // get quote
    // let periotGracia = JSON.parse(localStorage.getItem("currentQuote"));
    console.log("ENVIANDO DATA EN PAGAR CUOTA");
    if (this.validate()) {
      this.setState({
        loadSenData: true,
      });

      ////////////////////////////////////////////////////////////////////////// By paypal register
      if (this.state.objPayPal.isPaid) {
        // Close paypal modal
        this.childPaypal.current.handleClose(); 
        
        console.log('envio de comprobante', this.state.objPayPal, 'this.state.quote.totalPay', this.state.quote.totalPay)
        //send data
        let data = {
          TypeMethodPayment: 1,
          PaypalDTO: {
            nroOperation: this.state.objPayPal.idTransaction,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.IdPayment,
          // AmountPaidPayment: this.state.quote.totalPay,
          AmountPaidPayment: this.state.objPayPal.mountPaid,
          NumberAdvancePaymentPaid: 0,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
          daysUsed: this.state.GracePeriodDays,
          flagSchedule: this.state.flagSchedule,
          aplicategracePeriod: this.state.aplicategracePeriod,
          percentOverdueDetailId: this.state.percentOverdueDetailId,
          totalOverdue: this.state.totalOverdue
        };

        console.log('dsds', data, 'dsd')

        //return;

        let response = await UserService.registerPaymentQuoteVouhersv2(data);

        if (response !== undefined) {
          if (response.status === 1) {
            // Delete local storage item
            localStorage.removeItem("currentQuote");
            //sessionStorage.removeItem("amountWallet");
            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
            messageError: "Ocurrió un error.",
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      } else if (
        this.state.vouchers.length > 0 &&
        this.state.walletAmount <= 0
      ) {
        ///////////////////////////////////////////////////////////////////////////////////////////////////Vouchers
        console.log('haber DataPG en REGISTRAR XD =>',this.state.dataPG);
        let data = {
          TypeMethodPayment: 2,
          WalletTransaction: {
            Amount: this.state.walletAmount,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.IdPayment, //72990
          AmountPaidPayment: this.state.quote.totalPay, //this.state.quote.quoteUsd
          NumberAdvancePaymentPaid: 0,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
          //////////////LO NUEVO EN PERIODO DE GRACIA
          FlagSchedule:this.state.dataPG.flagschedule ? this.state.dataPG.flagschedule : 2,
          DaysUsed:this.state.dataPG.daysUsed == null ? 0 : this.state.dataPG.daysUsed,
          AplicategracePeriod:this.state.dataPG.aplicaPE ? this.state.dataPG.aplicaPE :0,
          PercentOverdueDetailId:this.state.dataPG.percentOverduedetailId === undefined ? 0 : this.state.dataPG.percentOverduedetailId  ,
          TotalOverdue:this.state.dataPG.overdue === undefined ? 0 : this.state.dataPG.overdue

        };

        let vouchers = [];
        let vouchersUpload = this.state.vouchers;
        console.log("vouchersUpload =>", vouchersUpload);
        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let contenType = vouchersUpload[i].voucherBase.split(",");
          let temp = {
            MethodSubTipoPagoId: Number(vouchersUpload[i].type),
            CompanyOperationNumber: vouchersUpload[i].code,
            OperationNumber: vouchersUpload[i].code,
            TotalAmount: Number(
              vouchersUpload[i].typeExchange === 3
                ? vouchersUpload[i].amount?.replace(",", "")
                : vouchersUpload[i].amount
            ),
            PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
            Note: vouchersUpload[i].nota,
            Base64: contenType[1],
          };
          vouchers.push(temp);
        }
        data.Vouchers = vouchers;
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");

        console.log("data enviada a response voucher =>", data);

        let response = await UserService.registerPaymentQuoteVouhersv2(data);
        if (response !== undefined) {
          if (response.status === 1) {
            localStorage.removeItem("currentQuote");
            this.setState({
              isComplete: true,
              loadSenData: false,
            });
          } else {
            this.setState({
              loadSenData: false,
              messageError: "Ocurrió un error.",
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: false,
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }

        ///
      } else if (
        this.state.vouchers.length === 0 &&
        this.state.walletAmount > 0
      ) {
        // Pay with only wallet
        console.log("wallet");

        // Implement into eventPayWallet
      } else if (
        this.state.vouchers.length > 0 &&
        this.state.walletAmount > 0
      ) {
        //////////////////////////////////////////////////////////////////////////////// Pay with vouchers and wallet

        let data = {
          TypeMethodPayment: 4,
          WalletTransaction: {
            Amount: this.state.walletAmount,
            ReferenceData: `Pago parcial de ${this.state.quote.quoteDescription}`,
          },
          IdUserPayment: this.state.quote.idUser,
          IdSuscriptionPayment: this.state.quote.idSuscription,
          IdPayment: this.state.quote.IdPayment,
          AmountPaidPayment: this.state.walletAmount,
          NumberAdvancePaymentPaid: 0,
          IsQuoteInitial: this.state.quote.isQuoteInitial,
          // Periodo de gracia
          ApplyGracePeriod: this.state.ApplyGracePeriod,
          GracePeriodDays: this.state.GracePeriodDays,
          // Cupon
          ApplyCoupon: this.state.ApplyCoupon,
          OverdueCoupon: this.state.OverdueCoupon,
          // days
          NewExpirationDate: this.state.NewExpirationDate,
          //////////////LO NUEVO EN PERIODO DE GRACIA
          FlagSchedule:this.state.dataPG.flagschedule ? this.state.dataPG.flagschedule : 2,
          DaysUsed:this.state.dataPG.daysUsed == null ? 0 : this.state.dataPG.daysUsed,
          AplicategracePeriod:this.state.dataPG.aplicaPE ? this.state.dataPG.aplicaPE :0,
          PercentOverdueDetailId:this.state.dataPG.percentOverduedetailId === undefined ? 0 : this.state.dataPG.percentOverduedetailId  ,
          TotalOverdue:this.state.dataPG.overdue === undefined ? 0 : this.state.dataPG.overdue,
        };

        let vouchers = [];
        let vouchersUpload = this.state.vouchers;

        for (let i = 0; i < vouchersUpload.length; i++) {
          // Split base64 code type and string
          let contenType = vouchersUpload[i].voucherBase.split(",");
          let temp = {
            MethodSubTipoPagoId: Number(vouchersUpload[i].type),
            CompanyOperationNumber: vouchersUpload[i].code,
            OperationNumber: vouchersUpload[i].code,
            TotalAmount: Number(vouchersUpload[i].amount),
            PaymentCoinCurrencyId: Number(vouchersUpload[i].typeExchange),
            Note: vouchersUpload[i].nota,
            Base64: contenType[1],
          };
          vouchers.push(temp);
        }
        data.Vouchers = vouchers;
        data.NotePay = vouchers.map((item) => item.NotePay).join(", ");

        let response = await UserService.registerPaymentQuoteVouhersv2(data);
        if (response !== undefined) {
          if (response.status === 1) {
            localStorage.removeItem("currentQuote");
            this.setState({
              isComplete: (this.state.isComplete = true),
              loadSenData: (this.state.loadSenData = false),
            });
          } else {
            this.setState({
              loadSenData: (this.state.loadSenData = false),
              messageError: "Ocurrió un error.",
            });
            alert("Ocurrió un error al momento de registrar su pago.");
          }
        } else {
          this.setState({
            loadSenData: (this.state.loadSenData = false),
            messageError: "Ocurrió un error.",
          });
          alert("Tuvimos un problema. Inténtalo más tarde.");
        }
      }
    }
    
  };

  cancelar = () => {};

  returnLogin = () => {
    history.push("/home");
  };

  ////////////////////////////////////////////////////// Register payment only wallet /> acction from wallet REGISTRO CON WALLET
  eventPayWallet = async (amount,diasUsados,cambioFecha,aplicaPG,respuestaPG) => {
    this.setState({
      loadSenData: (this.state.loadSenData = true),
    });
    /* console.log(' amount =>',amount);
    console.log('dias usados =>',diasUsados);
    console.log('cambio fecha =>',cambioFecha);
    console.log('aplicaPG =>',aplicaPG);
    console.log('respuestaPG =>',respuestaPG); */
    let data = {
      TypeMethodPayment: 3,
      WalletTransaction: {
        Amount: amount,
        ReferenceData: `Pago de ${this.state.quote.quoteDescription}`,
      },
      IdUserPayment: this.state.quote.idUser,
      IdSuscriptionPayment: this.state.quote.idSuscription,
      IdPayment: this.state.quote.IdPayment,
      AmountPaidPayment: amount,
      NumberAdvancePaymentPaid: 0,
      IsQuoteInitial: this.state.quote.isQuoteInitial,
      // Periodo de gracia
      ApplyGracePeriod: this.state.ApplyGracePeriod,
      GracePeriodDays: this.state.GracePeriodDays,
      // Cupon
      ApplyCoupon: this.state.ApplyCoupon,
      OverdueCoupon: this.state.OverdueCoupon,
      // days
      NewExpirationDate: this.state.NewExpirationDate,
      ///////////
     /*  FlagSchedule:this.state.dataPG.flagschedule ? this.state.dataPG.flagschedule : 2,
          DaysUsed:this.state.dataPG.daysUsed,
          AplicategracePeriod:this.state.dataPG.aplicaPE,
          PercentOverdueDetailId:this.state.dataPG.percentOverduedetailId === undefined ? null : this.state.dataPG.percentOverduedetailId  ,
          TotalOverdue:this.state.dataPG.overdue === undefined ? null : this.state.dataPG.overdue,
 */
      FlagSchedule:cambioFecha ? cambioFecha : 2,
      DaysUsed:diasUsados == null ? 0 : diasUsados,
      AplicategracePeriod:aplicaPG ? aplicaPG : 0,
      PercentOverdueDetailId:respuestaPG.percentOverduedetailId === undefined ? 0 : respuestaPG.percentOverduedetailId ,
      TotalOverdue:respuestaPG.overdue === undefined ? 0 : respuestaPG.overdue,
    };
    
    console.log('DATA DE PAGO CON WALLET ====>',data);


    let response = await UserService.registerPaymentQuoteVouhersv2(data);

    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          isComplete: (this.state.isComplete = true),
          loadSenData: (this.state.loadSenData = false),
        });
        // Delete local storage item
        localStorage.removeItem("currentQuote");
      } else {
        this.setState({
          loadSenData: (this.state.loadSenData = false),
          messageError: "Ocurrió un error. Intentelo más tarde",
        });
        alert(
          "Ocurrió un error al momento de registrar su pago. \n" +
            response.description
        );
      }
    } else {
      this.setState({
        loadSenData: (this.state.loadSenData = false),
      });
      alert("Tuvimos un problema. Inténtalo más tarde.");
    }
  };
  // Periodo de gracia
  ApplyGracePeriodAndCupon = async (
    ApplyGracePeriod = 0,
    GracePeriodDays = 0,
    totalPay = 0,
    ApplyCoupon = 0,
    OverdueCoupon = "",
    newExpirationDate = ""
  ) => {
    console.log('APLICASTE ApplyGracePeriodAndCupon ?????????????')
    if (ApplyGracePeriod === 1) {
      this.setState({
        ApplyGracePeriod: ApplyGracePeriod,
        GracePeriodDays: GracePeriodDays,
        quote: {
          ...this.state.quote,
          totalPay: totalPay,
        },
        ApplyCoupon: 0,
        OverdueCoupon: "",
        NewExpirationDate: newExpirationDate !== "" ? newExpirationDate : this.state.NewExpirationDate,
      });
      // this.state.quote.totalPay = totalPay;
    } else if (ApplyCoupon === 1) {
      this.setState({
        ApplyGracePeriod: 0,
        GracePeriodDays: 0,
        quote: {
          ...this.state.quote,
          totalPay: totalPay,
        },
        // Cupon
        ApplyCoupon: ApplyCoupon,
        OverdueCoupon: OverdueCoupon,
      });
    } else {
      this.setState({
        ApplyGracePeriod: 0,
        GracePeriodDays: 0,
        totalPay2: 0,
        // Cupon
        ApplyCoupon: 0,
        OverdueCoupon: "",
      });
    }
  };

 changeStateNew = (daysUsed = 0, flagSchedule = 1, percentOverdueDetailId = 0, totalOverdue = 0) => {
  this.setState({
    aplicategracePeriod: 1,
    GracePeriodDays: daysUsed,
    flagSchedule: flagSchedule,
    percentOverdueDetailId: percentOverdueDetailId,
    totalOverdue: totalOverdue
  })
 }
  
  render() {
    const {
      quote,
      loading,
      noData,
      noDataMessage,
      isComplete,
      walletAmount,
      dataPG,
    } = this.state;
  //  console.log('quote en RegisterQuoteView =>',quote);
   // console.log('ver quote ===>',quote)
 //   console.log('ver dataPG ===>',dataPG)
    return (
      <div>
        {!isComplete && (
          <div className="panel-form">
            {loading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="dark"></Spinner>
                <p>Obteniendo información ...</p>
              </div>
            )}
            {!loading && !noData && quote !== undefined && (
              <div>
                {<PayQuote
                  quote={quote}
                  eventPay={this.eventPaypal}
                  registerPayWallet={this.eventPayWallet}
                  eventWallet={this.eventAddWallet}
                  ref={this.childPaypal}
                  ApplyGracePeriodAndCupon={this.ApplyGracePeriodAndCupon}
                  ejemplo={this.ejemplo}
                  dataPG={dataPG}
                  funcionJalarDatosDePG ={this.funcionJalarDatosDePG}
                  changeStateNew={this.changeStateNew}
                ></PayQuote>}
                {walletAmount > 0 && (
                  <div>
                    <p>
                      <b>Monto pagado con Mi Wallet $ :</b> {walletAmount}
                    </p>
                  </div>
                )}

                <div
                  className="row justify-content-between"
                  style={{ display: this.state.displayFooter }}
                >
                  <Col style={{ textAlign: "right" }}>
                   {/*  REGISTRAR DE PAGOS ES AQUI  */}
                    <Button variant="primary" onClick={this.sendData}>
                      Registrar
                    </Button>
                  </Col>
                </div>
              </div>
            )}
            {/* {messageError.length > 0 &&
                            <Alert variant="danger">{messageError}</Alert>
                         } */}
            {noData && (
              <div style={{ textAlign: "center" }}>
                <Form.Label>{noDataMessage}</Form.Label>
              </div>
            )}
            <Modal
              show={this.state.loadSenData}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              centered
            >
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="dark"
                  ></Spinner>
                  <Form.Label>&nbsp; Guardando información...</Form.Label>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
        {isComplete && (
          <div className="panel-form">
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Form.Label>Registro exitoso!</Form.Label>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col sm={12}>
                <Button onClick={this.returnLogin}>Volver</Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
