import React, { Component } from "react";
import { Form, Table, Button, Spinner, Modal, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import AutosuggestCustom from "./AutosuggestCustom";

import Validation from "../utils/Validation";
import UserService from "../../services/user.service";

export default class Deposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registers: [],
      value: "",
      loadRegisters: false,
      loading: true,
      noData: false,
      noDataMesssage: false,
      tree: [],
      idsUpliner: [],
      listUpliner: [],
      suggestions: [],
      loadSenData: false,
      loadConfirm: false,
      itemSaved: undefined,
      loadingBranch: false,
      avaibleMessage: "",
      numBranch: 0,
      avaibleDeposition: false,
      showModal: false,
    };

    this.getRegister = this.getRegister.bind(this);
    this.getListAvailable = this.getListAvailable.bind(this);
  }

  componentDidMount() {
    this.getRegister();
  }

  componentWillReceiveProps(props) {
    // From register step 3: select suscription
    this.setState({
      tree: (this.state.tree = props.data),
    });

    // Convert tree into list only with the child have < 3 children and the state team==SI
    let listTree = [];
    let idsUpliner = [];

    // verify the root
    let tree = this.state.tree;
    if (tree.length > 0) {
      if (tree[0].children.length < 3) {
        listTree.push(tree[0]);
        idsUpliner.push(tree[0].id);
      }
      this.getListAvailable(tree[0].children, listTree, idsUpliner);
    }

    // order list accordign to level
    listTree.sort(function(a, b) {
      return a.nivel - b.nivel;
    });
    this.setState({
      suggestions: (this.state.suggestions = listTree),
      idsUpliner: (this.state.idsUpliner = idsUpliner),
    });
  }
  getListAvailable = (children, listTree, idsUpliner) => {
    let list = [];
    for (let i = 0; i < children.length; i++) {
      if (
        children[i].children.length < 3 &&
        children[i].team !== undefined &&
        children[i].team.toUpperCase().includes("SI")
      ) {
        listTree.push(children[i]);
        idsUpliner.push(children[i].id);
      }
      //get children
      this.getListAvailable(children[i].children, listTree, idsUpliner);
    }
    // return list;
  };

  // Get register for the table: user uvailable
  async getRegister() {
    let response = await UserService.getListDeposition();
    if (response !== undefined && response !== null) {
      if (response.status !== 1) {
        //;
        this.setState({
          registers: (this.state.registers = []),
          loading: (this.state.loading = false),
          loadRegisters: (this.state.loadRegisters = true),
          noData: (this.state.noData = true),
          noDataMesssage: (this.state.noDataMessage =
            "Ocurrió un problema mientras obteníamos los registros. Inténtelo más tarde."),
        });
      } else {
        if (response.objModel.length > 0) {
          this.setState({
            registers: (this.state.registers = response.objModel),
            loading: (this.state.loading = false),
            loadRegisters: (this.state.loadRegisters = true),
            noData: (this.state.noData = false),
            noDataMesssage: (this.state.noDataMessage = ""),
          });
        } else {
          this.setState({
            registers: (this.state.registers = response.objModel),
            loading: (this.state.loading = false),
            loadRegisters: (this.state.loadRegisters = false),
            noData: (this.state.noData = true),
            noDataMesssage: (this.state.noDataMessage =
              "No hay registros para mostrar."),
          });
        }
      }
    }
  }

  setDeposition = async (e, item) => {
    // Verify branch
    this.setState({
      loadConfirm: true,
      avaibleMessage: "Consultando número de ramas.",
      itemSaved: item,
    });
    let response = await UserService.getNumberBranch(item.idSon);

    if (response !== undefined && response.status === 1) {
      let message = `¿Desea desposicionar a ${item.name} ${item.lastname}?`;

      if (response.objModel > 0) {
        message = `${message}. Este usuario tiene `;
      }
      this.setState({
        numBranch: response.objModel,
        avaibleMessage: message,
        avaibleDeposition: true,
      });
    } else {
      this.setState({
        avaibleMessage: "Ocurrió un problema al consultar desposicionamiento",
        avaibleDeposition: false,
      });
    }
  };

  saveDeposition = async (item) => {
    let data = {
      IdSonDeposition: item.idSon,
      IdTypeTicket: 26,
      Observation: "",
      IdTicketCreationMedium: 2,
    };

    data.TicketDetailRequestData = {
      TicketDetailRequestDeposition: {
        IdUserSonDeposition: item.idSon,
      },
    };

    this.setState({
      showModal: (this.state.showModal = true),
    });
    let response = await UserService.generateDepostion(data);
    this.setState({
      showModal: (this.state.showModal = false),
    });
    if (response !== undefined) {
      if (response.status === 1) {
        alert("Se creo un solicitud de desposicionamiento.");
        this.getRegister();
      } else {
        alert("No pudimos guardar su información. Inténtelo más tarde.");
      }
    } else {
      alert("Tuvimos un error al guardar la información. Inténtelo más tarde.");
    }
  };

  // modal confirm
  handleCloseConfirm = () => {
    this.setState({
      showModal: (this.state.showModal = false),
      itemSaved: (this.state.itemSaved = undefined),
      avaibleMessage: "",
      numBranch: 0,
      avaibleDeposition: false,
      showModal: false,
    });
  };

  handleConfirm = (e) => {
    this.setState({
      showModal: (this.state.loadConfirm = false),
    });

    let savedData = this.state.itemSaved;
    this.saveDeposition(savedData);
  };

  getState = (idState) => {
    let description = "-";
    switch (idState) {
      case 0:
        // Inactivo
        description = "Inactivo";
        break;
      case 1:
        // Patrocinados directos
        description = "Activo";
        break;
      case 2:
        // Pendiente de validación inicial
        description = "Pendiente";
        break;
      case 3:
        // Rechazo de incial";
        description = "Inicial rechazado";
        break;
      case 4:
        // Pagar despues"
        description = "Pago después";
        break;
      case 5:
        // Deuda 1";
        // Pagar despues"
        description = "Deuda 1";
        break;
      case 6:
        // Deuda 2";
        description = "Deuda 2";
        break;
      case 7:
        // Deuda 3";
        description = "Deuda 3";
      case 8:
        description = "Preliquidacion";
        break;
      case 9:
        // Congelado";
        description = "Congelado";
        break;
      case 10:
        // Pendiente de validación";
        description = "Pendiente";
        break;
      case 11:
        // Pago rechazado";
        description = "Cuota rechazado";
        break;
      case 12:
        // Suscription finalizada";
        description = "Finalizado";
        break;
      case 13:
        description = "Pendiente";
        break;
      case 14:
        // Suscription finalizada";
        description = "Rechazo";
        break;
      case 15:
        // Suscription finalizada";
        description = "Liquidacion";
        break;
    }

    return description;
  };

  render() {
    const {
      registers,
      suggestions,
      value,
      loadRegisters,
      loading,
      noData,
      noDataMesssage,
      loadSenData,
      loadConfirm,
      itemSaved,
    } = this.state;

    return (
      <div style={{ paddingLeft: 30, paddingRight: 30 }}>
        <hr></hr>
        <Row>
          <Col sm={12}>
            {/* <Form.Label className="content-subtitle">Placement</Form.Label> */}
          </Col>
        </Row>
        <Table responsive>
          <thead className="table-head">
            <tr>
              <th>Nº</th>
              <th>Usuario</th>
              <th>Nombres y Apellidos</th>
              <th>Fecha de posicionamiento</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={6}>
                  <div>
                    <Spinner animation="border" variant="dark"></Spinner>
                    <p>Cargando registros...</p>
                  </div>
                </td>
              </tr>
            )}
            {loadRegisters &&
              this.state.registers.map((item, idx) => {
                let date = "";

                let value =
                  item.upliner !== undefined ? item.upliner.title : "";
                return (
                  <tr key={item.idSon}>
                    <td>{idx + 1}</td>
                    <td>
                      <div style={{ width: "150px", wordBreak: "break-all" }}>
                        {item.username}
                      </div>
                    </td>
                    <td>
                      <div style={{ width: "200px", wordBreak: "break-all" }}>
                        {item.name} {item.lastname}
                      </div>
                    </td>
                    <td>
                      {Validation.convertDateToString(item.positioningDate)}
                    </td>
                    <td>{this.getState(item.idState)}</td>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={(e) => {
                          this.setDeposition(e, item);
                        }}
                      >
                        Desposicionar
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {noData && (
              <tr>
                <td colSpan="6">
                  <Form.Label>{noDataMesssage}</Form.Label>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={6}>
                <div style={{ height: "400px" }}></div>
              </td>
            </tr>
          </tbody>
        </Table>
        {/* loading */}
        <Modal
          show={this.state.showModal}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; Guardando información...</Form.Label>
            </div>
          </Modal.Body>
        </Modal>

        {/* confirmacion */}
        <Modal show={loadConfirm} onHide={this.handleCloseConfirm}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {itemSaved !== undefined && <div>{this.state.avaibleMessage}</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseConfirm}>
              Cancelar
            </Button>
            {this.state.avaibleDeposition && (
              <Button variant="primary" onClick={(e) => this.handleConfirm(e)}>
                Confirmar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
