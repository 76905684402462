import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Svg,
  Rect,
} from "@react-pdf/renderer";

Font.register({
  family: "Calibri",
  fonts: [
    { src: "/pdf/fonts/Calibri Bold.TTF", fontWeight: 700 },

    {
      src: "/pdf/fonts/Calibri Regular.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Calibri",
  },
  body: {
    fontSize: 11.5,
    fontFamily: "Calibri",
    paddingTop: 10,
    paddingHorizontal: 85,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
    paddingHorizontal: 50,
    paddingBottom: 15,
  },
  imgHeader: {
    maxWidth: "180px",
    // opacity: 0.5,
  },
  title: {
    fontSize: 12,
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 20,
    width: "80%",
    margin: "0 auto",
  },
  subtitle: {
    fontFamily: "Calibri",
    textDecoration: "underline",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  text: {
    textAlign: "justify",
    paddingBottom: 10,
    //lineHeight: 1.5,
  },
  titleList: {
    textAlign: "justify",
    paddingLeft: 15,
    paddingBottom: 10,
  },
  list: {
    textAlign: "justify",
    paddingLeft: 20,
    paddingBottom: 2,
  },
  image: {
    width: "100%",
    marginVertical: 15,
    marginHorizontal: 90,
  },
  selected: {
    width: 20,
    height: 15,
    backgroundColor: "black",
    borderRadius: 1,
    border: "1 solid black",
  },
  noSelected: {
    width: 20,
    height: 15,
    backgroundColor: "white",
    borderRadius: 1,
    border: "1 solid black",
  },
  footer: {
    fontSize: 10,
    textAlign: "right",
    position: "absolute",
    bottom: 40,
    right: 85,
  },
});

const fechaYear = (fecha) => {
  let fechaArray = fecha.split("/");

  return fechaArray[2];
};

export default function SemillaPDF(props) {
  console.log("props SemillaPDF =>", props);
  let membershipmaintenanceletter = props.dataPDF.membershipmaintenanceletter;
  membershipmaintenanceletter = membershipmaintenanceletter
    .replace("DOLARES", "")
    .replace("M.N.", " ")
    .replace("USD", "dólares")
    .trim();

  membershipmaintenanceletter = membershipmaintenanceletter.toLowerCase();
  membershipmaintenanceletter =
    membershipmaintenanceletter.charAt(0).toUpperCase() +
    membershipmaintenanceletter.slice(1);

  let pricetotalLetter = props.dataPDF.pricetotalLetter;

  pricetotalLetter = pricetotalLetter
    .replace("DOLARES", "")
    .replace("M.N.", "")
    .replace("USD", "dólares")
    .trim();

  pricetotalLetter = pricetotalLetter.toLowerCase();
  pricetotalLetter =
    pricetotalLetter.charAt(0).toUpperCase() + pricetotalLetter.slice(1);

  const obtenerNombreMes = (mesNumero) => {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    return meses[mesNumero - 1];
  };
  const fecha = props.dataPDF.creationDate.split("/");
  return (
    <Document>
      <Page wrap={true} size="A4">
        {/*   1 */}
        <View style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.title}>
              CONTRATO DE ASOCIACIÓN EN PARTICIPACIÓN PARA EL DESARROLLO DE
              NEGOCIOS SEMILLA N° {props.dataPDF.user.username.toUpperCase()}-
              RRCR
              {props.dataPDF.countSuscriptionByFamily}-C-
              {fechaYear(props.dataPDF.creationDate)}
            </Text>

            <Text style={styles.text}>
              Conste por el presente documento, el Contrato de Asociación en
              Participación para el Desarrollo de Negocios, que celebran de una
              parte{" "}
              <Text style={{ fontWeight: "600" }}>VALLE ENCANTADO S.A.C.</Text>,
              identificado con RUC N° 20601460271, constituida y regida bajo las
              leyes de la República del Perú, con domicilio legal en Mz.{" "}
              {"<<B>>"} Lote 72, Tercera Etapa Cieneguilla, Provincia y
              Departamento de Lima, debidamente representada por el Sr. Omar
              Fernando Urteaga Cabrera , identificado con D.N.I. N° 41958311; a
              quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "600" }}>“VALLE ENCANTADO”</Text>; y de
              otra parte el(la) Sr(a). {props.dataPDF.user.name.toUpperCase()}{" "}
              {props.dataPDF.user.lastname.toUpperCase()}, de nacionalidad{" "}
              {props.dataPDF.user.courtesy}, identificado con D.N.I. N°{" "}
              {props.dataPDF.user.nroDocument}, con domicilio en{" "}
              {props.dataPDF.user.address}, a quien en lo sucesivo se denominará{" "}
              <Text style={{ fontWeight: "bold" }}>“EL ASOCIADO”</Text>; en los
              términos y condiciones contenidos en las cláusulas siguientes:
            </Text>

            <View style={styles.text}>
              <Text style={styles.subtitle}>PRIMERA: ANTECEDENTES</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.1{"  "}VALLE ENCANTADO, es una empresa encargada de
                  gestionar y desarrollar el proyecto
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  inmobiliario de carácter recreacional y hotelero de RIBERA DEL
                  RIO CLUB RESORT S.A., esta última identificada con RUC N°
                  20608720911, debidamente inscrita en la Partida Electrónica N°
                  14809461 del Registro de Personas Jurídicas de Registro de
                  Personas Jurídicas de Lima y ubicada en Mz. {"<<B>>"} Lote 72,
                  Tercera Etapa Cieneguilla, Provincia y Departamento de Lima.
                  Por lo que, VALLE ENCANTADO requiere contar con recursos y EL
                  ASOCIADO tiene interés en participar en el negocio conforme a
                  las condiciones establecidas en este instrumento.
                </Text>
              </View>

              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  1.2{"  "}EL ASOCIADO es una persona natural que, en calidad de
                  asociado-inversionista, mani-
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  fiesta, por el presente acto, su voluntad expresa de
                  participar en el proyecto inmobiliario desarrollado por VALLE
                  ENCANTADO en los términos establecidos en este documento.
                  Asimismo, declara haber recibido la presentación del proyecto
                  y con base en ello, y luego de las consultas del caso, ha
                  decidido invertir en el desarrollo del mismo.
                </Text>
              </View>
            </View>

            <View style={styles.text}>
              <Text style={styles.subtitle}>SEGUNDA: OBJETO DEL CONTRATO</Text>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.1.{"  "}Por el presente contrato,las partes acuerdan
                  participar en el negocio descrito en la
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  cláusula siguiente bajo la modalidad de asociación en
                  participación. VALLE ENCANTADO ha puesto a disposición de EL
                  ASOCIADO,{" "}
                  {props.dataPDF.packagesavailable
                    .find((e) => e.package.name === props.dataPDF.userPackage)
                    .numberShares.toLocaleString({
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  acciones de inversión Clase C de RIBERA DEL RIO CLUB RESORT
                  S.A, por el Paquete de acciones de inversión:{" "}
                  {props.dataPDF.userPackage.toUpperCase()}, conforme al
                  siguiente detalle:
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 10,
                  marginLeft: -20,
                  marginRight: -20
                }}
              >
                {props.dataPDF.packagesavailable.map((e, index) => {
                  if (e.package.name === props.dataPDF.userPackage) {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        
                        }}
                      >
                        <Text
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {e.package.name.substring(0, 7)}
                        </Text>
                        <Text
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {" "}
                          {e.package.name.substring(7, 11)}
                        </Text>
                        <View style={styles.selected}></View>
                      </View>
                    );
                  } else {
                    return (
                      <View
                        key={index}
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {e.package.name.substring(0, 7)}
                        </Text>
                        <Text
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {" "}
                          {e.package.name.substring(7, 11)}
                        </Text>
                        <View style={styles.noSelected}></View>
                      </View>
                    );
                  }
                })}
              </View>

              <Text style={[styles.text, { marginLeft: 16.5 }]}>
                Estas acciones de inversión son Acciones preferentes de primer
                orden sin derecho a voto y sin derecho a usufructo, con sujeción
                “Código de Ética y Conducta” - Anexo 1 que es parte integral del
                presente contrato.
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>1</Text>
      </Page>

      <Page wrap={true} size="A4">
        {/* 2 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>
          <View style={styles.body}>
            <View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  2.2. Por su parte, EL ASOCIADO se obliga a transferir a VALLE
                  ENCANTADO como capital de
                </Text>
                <Text style={[styles.text, { marginLeft: 20 }]}>
                  negocio, la suma pactada en la cláusula tercera, en la forma y
                  oportunidad convenidas.
                </Text>
              </View>
              <Text style={styles.subtitle}>
                TERCERA: PRECIO Y FORMA DE PAGO
              </Text>
              {(() => {
                const packageWords = props.dataPDF.userPackage.trim().split(" ")
                  .length;
                if (packageWords === 1) {
                  return (
                    <View style={styles.text}>
                      <Text style={{ textAlign: "justify" }}>
                        3.1.{"  "}El capital de negocio por el Paquete de
                        acciones de inversión{" "}
                        {props.dataPDF.userPackage.toUpperCase()}, asciende a la
                      </Text>
                      <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                        suma de{" "}
                        {`US$ ${props.dataPDF.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}{" "}
                        ({pricetotalLetter}), el cual le otorga a EL ASOCIADO el
                        número de acciones y beneficios mencionados en el
                        numeral 2.1 del presente contrato.
                      </Text>
                    </View>
                  );
                } else if (packageWords >= 2) {
                  return (
                    <View style={styles.text}>
                      <Text style={{ textAlign: "justify" }}>
                        3.1.{"  "}El capital de negocio por el Paquete de
                        acciones de inversión{" "}
                        {props.dataPDF.userPackage.toUpperCase()},
                      </Text>
                      <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                        asciende a la suma de{" "}
                        {`US$ ${props.dataPDF.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        , ({pricetotalLetter}), el cual le otorga a EL ASOCIADO
                        el número de acciones y beneficios mencionados en el
                        numeral 2.1 del presente contrato.
                      </Text>
                    </View>
                  );
                }
              })()}
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.2.{"  "}Las partes acuerdan que el abono del capital de
                  negocio, se efectuará en dólares de los
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  Estados Unidos de América, al amparo de lo dispuesto por el
                  Artículo 1237 del Código Civil.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.3.{"  "}El capital de negocio será abonado por EL ASOCIADO
                  en su totalidad, en la fecha de la
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  suscripción del presente contrato.
                </Text>
              </View>
              <View style={styles.text}>
                <Text style={{ textAlign: "justify" }}>
                  3.4.{"  "}El capital de negocio podrá ser abonado en las
                  siguientes cuentas bancarias de titu-
                </Text>
                <Text style={{ textAlign: "justify", marginLeft: 20 }}>
                  laridad de VALLE ENCANTADO, a elección de EL ASOCIADO:
                </Text>
              </View>
              <Image
                style={[{ width: "100%", height: "105px", paddingBottom: 10 }]}
                src="/pdf/inresorts/precios.png"
              />
              <Text style={[styles.text, { marginLeft: 20 }]}>
                En caso de cambio o incrementos de cuentas bancarias, VALLE
                ENCANTADO informará oportunamente a través del correo registrado
                de EL ASOCIADO dentro de los primeros 7 (siete) días del cambio.
              </Text>

              <Text style={styles.subtitle}>
                CUARTA: RÉGIMEN DE RENDIMIENTO Y RETORNO DE LA INVERSIÓN
              </Text>

              <Text style={styles.text}>
                Por su parte, en contraprestación, VALLE ENCANTADO se obliga a
                retribuir a EL ASOCIADO con Acciones Clase C (Acciones
                preferentes de primer orden sin derecho a voto y sin derecho a
                usufructo), contando con los siguientes beneficios:
              </Text>
              <Text style={[styles.titleList, { fontWeight: "semibold" }]}>
                - Acciones:
              </Text>

              <View style={styles.text}>
                <Text style={styles.list}>
                  • EL ASOCIADO tendrá derecho a recibir{" "}
                  {props.dataPDF.packagesavailable
                    .find((e) => e.package.name === props.dataPDF.userPackage)
                    .numberShares.toLocaleString({
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  acciones Clase C, libre de gravámenes,
                </Text>
                <Text style={{ marginLeft: 30 }}>
                  cargas y cualquier obligación que limite o restrinja su
                  dominio o libre disposición, las cuales tienen derecho a la
                  participación de las utilidades de la empresa cuando estas se
                  produzcan en proporción del número de sus acciones de
                  inversión que en merito al presente contrato se encuentra
                  representando en su certificado de acciones obtenido.
                  Asimismo, como parte del presente contrato EL ASOCIADO por la
                  representación de las acciones tendrá derecho al paquete de
                  beneficios que dichas acciones representan de acuerdo al monto
                  de la inversión realizada por este. Queda entendido que EL
                  ASOCIADO participa de los resultados, tanto de las utilidades
                  como de las pérdidas de RIBERA DEL RIO CLUB RESORT S.A.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>2</Text>
      </Page>

      <Page wrap={true} size="A4">
        {/* 3 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={styles.list}>
                • El cumplimiento de la inversión hará acreedor a EL ASOCIADO de
                un Certificado de
              </Text>
              <Text style={{ marginLeft: 30 }}>
                Acciones Clase C, de acuerdo al Paquete de acciones elegido por
                EL ASOCIADO.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={styles.list}>
                • Las acciones Clase C corresponden ser acciones preferentes de
                primer orden sin
              </Text>
              <Text style={{ marginLeft: 30 }}>
                derecho a voto y sin derecho a usufructo, estas confieren a su
                titular la calidad de socio y le atribuye, entre ellos, los
                siguientes derechos:
              </Text>

              <Text style={[styles.list, { marginLeft: 8, marginTop: 8 }]}>
                1. Participar en el reparto de utilidades y del patrimonio neto
                resultante de la
              </Text>
              <Text style={{ marginLeft: 40, marginBottom: 5 }}>
                liquidación.
              </Text>

              <Text style={[styles.list, { marginLeft: 10 }]}>
                2. Posibilidad de participar en el Directorio, a través de un
                representante elegido por
              </Text>
              <Text style={{ marginLeft: 40, marginBottom: 5 }}>
                {" "}
                todos los accionistas del Sistema Red.
              </Text>
              <Text style={[styles.list, { marginLeft: 10 }]}>
                3. Ser informado cuando menos semestralmente de las actividades
                y gestión de
              </Text>
              <Text style={{ marginLeft: 40, marginBottom: 5 }}>
                RIBERA DEL RIO CLUB RESORT S.A.
              </Text>

              <Text style={{ marginLeft: 40, marginBottom: -5 }}>
                Asimismo, le confiere a EL ASOCIADO los demás derechos
                establecidos en el Estatuto de RIBERA DEL RIO CLUB RESORT S.A.,
                conforme a la Ley General de Sociedades.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                • La entrega del Certificado de Acciones, el cual tiene como
                característica ser vitalicio y
              </Text>
              <Text style={{ marginLeft: 30 }}>
                heredable, se realizará a la fecha del cumplimiento del abono
                total de la inversión. Dicho certificado, será legalizado
                Notarialmente.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={styles.list}>
                • Las acciones son heredables, por lo que, el heredero o
                legatario se convierte en
              </Text>
              <Text style={{ marginLeft: 30 }}>
                {" "}
                accionista de la sociedad en aplicación del artículo 660 del
                Código Civil, en tanto que, la muerte pone fin a la persona,
                produce la apertura de su sucesión y origina la transmisión de
                su patrimonio, constituido por activos y pasivos, es decir, por
                los bienes, derechos y obligaciones.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={styles.list}>
                • EL ASOCIADO tiene derecho a invertir en nuevas acciones de
                inversión, teniendo en
              </Text>
              <Text style={{ marginLeft: 30 }}>
                consideración las siguientes condiciones:
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={[styles.list, { marginLeft: 10 }]}>
                - El monto del capital de negocio corresponderá al valor de
                mercado vigente en el
              </Text>
              <Text style={{ marginLeft: 35, marginBottom: -5 }}>
                momento de la inversión.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={[styles.list, { marginLeft: 10, marginTop: -5 }]}>
                - EL ASOCIADO al optar por la inversión en nuevas acciones de
                inversión, acepta las
              </Text>
              <Text style={{ marginLeft: 35, marginBottom: 10 }}>
                condiciones y normas vigentes a dicha fecha.
              </Text>
            </View>

            <Text style={styles.subtitle}>
              QUINTA: OBLIGACIONES DE LAS PARTES
            </Text>
            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                5.1 VALLE ENCANTADO tiene las siguientes obligaciones:
              </Text>
            </View>

            <Text
              style={[styles.text, { paddingLeft: 10, fontWeight: "semibold" }]}
            >
              - Acciones
            </Text>
            <View style={styles.text}>
              <Text style={styles.list}>
                • VALLE ENCANTADO se obliga la gestionar, administrar y realizar
                el negocio, materia
              </Text>
              <Text style={{ marginLeft: 30 }}>
                del presente contrato, de manera ordenada y adecuada. En tal
                sentido, VALLE ENCANTADO deberá proceder con la diligencia,
                prudencia, buena fe y lealtad.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={styles.list}>
                • VALLE ENCANTADO se obliga a otorgar las acciones materia de
                este contrato a EL
              </Text>
              <Text style={{ marginLeft: 30 }}>
                ASOCIADO, libres de gravámenes, cargas y cualquier obligación
                que limite o restrinja su dominio o libre disposición.
              </Text>
            </View>
          </View>

          {/*  */}
        </View>
        <Text style={styles.footer}>3</Text>
      </Page>

      <Page wrap={true} size="A4">
        {/* 4 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <View style={styles.text}>
              <Text style={styles.list}>
                • Suscribir todos los documentos o contratos necesarios para
                perfeccionar el otorga-
              </Text>
              <Text style={{ marginLeft: 30 }}>
                miento de titularidad de acciones.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={styles.list}>
                • VALLE ENCANTADO se compromete que RIBERA DEL RIO CLUB RESORT
                S.A. abone
              </Text>
              <Text style={{ marginLeft: 30, marginBottom: 5 }}>
                las utilidades correspondientes por cada año fiscal a EL
                ASOCIADO, en caso si hubiere, conforme a los plazos establecidos
                por Ley para la repartición de utilidades y conforme al
                certificado de acciones obtenido.
              </Text>
            </View>

            <View style={styles.text}>
              <Text style={{ textAlign: "justify" }}>
                5.2. EL ASOCIADO tiene las siguientes obligaciones:
              </Text>
            </View>
            <Text
              style={[styles.text, { paddingLeft: 10, fontWeight: "semibold" }]}
            >
              - Acciones
            </Text>
            <View style={styles.text}>
              <Text style={styles.list}>
                • EL ASOCIADO se obliga a efectuar el abono en el momento y
                forma pactados, confor-
              </Text>
              <Text style={{ marginLeft: 30 }}>
                me a lo dispuesto en la cláusula tercera de este documento. En
                caso contrario, EL ASOCIADO recaerá en incumplimiento según lo
                dispuesto en la cláusula sétima.
              </Text>
            </View>
            <View style={styles.text}>
              <Text style={styles.list}>
                • Suscribir los documentos y contratos que sean necesarios para
                perfeccionar el otor-
              </Text>
              <Text style={{ marginLeft: 30, marginBottom: 10 }}>
                gamiento de titularidad de las acciones seleccionadas de acuerdo
                a su inversión.
              </Text>
            </View>

            <Text style={styles.subtitle}>SEXTA: FACULTADES DE LAS PARTES</Text>
            <Text style={styles.text}>
              6.1. Las partes declaran expresamente que corresponderá a VALLE
              ENCANTADO cualquier
            </Text>
            <Text style={{ paddingLeft: 22, marginTop: -8 }}>
              {" "}
              vinculación económica que en el desarrollo del negocio se acuerde
              con terceros, para lo cual VALLE ENCANTADO actuará en nombre
              propio al celebrar contratos, al asumir obligaciones o al adquirir
              créditos. En consecuencia, queda convenido que no existirá
              relación jurídica alguna entre los terceros y EL ASOCIADO; y,
              asimismo, los terceros no adquirirán obligaciones frente a EL
              ASOCIADO ni éste ante aquellos.
            </Text>

            <Text style={[styles.text, { marginTop: 5 }]}>
              6.2. Queda expresamente establecido que EL ASOCIADO no asume ni
              asumirá ninguna
            </Text>
            <Text style={{ paddingLeft: 22, marginTop: -8 }}>
              responsabilidad, de ninguna naturaleza, por las acciones,
              decisiones, compromisos o cualquier otro acto, sea verbal o
              escrito, que realice VALLE ENCANTADO, ni por las consecuencias que
              ellos generen al vincularse con otros asociados-inversionistas en
              el desarrollo del negocio referido en el presente contrato, hecho
              que EL ASOCIADO declara conocer plenamente y acepta en el presente
              acto en cuanto a su naturaleza y relación jurídica.
            </Text>

            <Text style={[styles.text, { marginTop: 5 }]}>
              6.3. En virtud de lo acordado en la presente cláusula, las partes
              convienen que VALLE
            </Text>
            <Text style={{ paddingLeft: 22, marginTop: -8 }}>
              ENCANTADO no requiere de ningún tipo de consentimiento previo
              adicional de EL ASOCIADO para cualquier vinculación económica
              celebrada en el desarrollo del negocio con terceros, más allá de
              lo expresado y acordado en la citada cláusula.
            </Text>

            <Text style={[styles.text, { marginTop: 5 }]}>
              6.4. EL ASOCIADO declara estar conforme en que no tendrá
              injerencia directa ni indirecta en
            </Text>
            <Text style={{ paddingLeft: 22, marginTop: -8, marginBottom: 15 }}>
              las actividades de VALLE ENCANTADO.
            </Text>

            <Text style={styles.subtitle}>SÉTIMA: INCUMPLIMIENTO</Text>

            <Text
              style={{
                textAlign: "justify",
                paddingBottom: 10,
              }}
            >
              El ASOCIADO renuncia irrevocablemente a invocar hechos de terceros
              o causas no imputables a él, en caso de incumplimiento de
              cualesquiera de las obligaciones asumidas en el presente CONTRATO.
            </Text>
          </View>
        </View>
        <Text style={styles.footer}>4</Text>
      </Page>

      <Page wrap={true} size="A4">
        {/* 5 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>
              OCTAVA: CUMPLIMIENTO DE NORMAS SOBRE PREVENCIÓN DE LAVADO DE
              ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO
            </Text>
            {/* <View style={styles.text}> */}
              <Text style={{ textAlign: "justify" }}>
                A la suscripción del presente contrato, las partes se sujetan a
                las normas vigentes y aplicables sobre prevención de lavado de
                activos y financiamiento del terrorismo del Reglamento de
                Gestión de Riesgo de Lavado de activos, aprobado mediante
                Resolución SBS N° 2660-2015. En ese sentido, las partes declaran
                que sus actividades, así como los valores materia de la presente
                transferencia o transferencias no se encuentran relacionados con
                actividades ilícitas, particularmente de lavado de activos o del
                financiamiento del terrorismo.
              </Text>
              <Text style={{ textAlign: "justify", paddingBottom: 10, marginTop: 10, }}>
                Si alguna de las partes resultase implicada o involucrada en una
                investigación relacionada con actividades ilícitas de lavado de
                activos y/o financiamiento del terrorismo o fuese incluida en
                Listas de control como las de la ONU, OFAC, Listas de
                terroristas de la Unión Europea, Listas de Países y Territorios
                No Cooperantes y otras que señale la SBS; esta se compromete a
                indemnizar y/o pagar los daños y perjuicios directos, multas,
                sanciones, costas, honorarios de abogados, en los que la parte
                involucrada haya incurrido tras un procedimiento en el que
                hubiera sido finalmente condenado por resolución administrativa
                o judicial firme, y que pudiera sufrir sin que medie
                responsabilidad suya, esto como consecuencia directa de los
                hechos o actos dolosos o mediando culpa grave de la parte
                involucrada y que generen demandas, denuncias de todo tipo,
                medidas cautelares fuera y dentro del proceso, reclamos
                interpuestos por cualquier causa y ante cualquier fuero
                judicial, arbitral o administrativo. La parte involucrada deberá
                asumir los gastos mencionados en la presente cláusula hasta que
                la parte afectada se vea eximida del proceso.
              </Text>
            {/* </View> */}

            <Text style={styles.subtitle}>
              NOVENA: ASOCIACIÓN EN PARTICIPACIÓN{" "}
            </Text>
            <Text style={{ textAlign: "justify", marginBottom: 10 }}>
              En armonía con lo establecido por los artículos 438 y 441 de la
              Ley General de Sociedades, las partes dejan constancia de que el
              presente contrato de asociación en participación no genera la
              creación de una persona jurídica y tampoco tiene razón social ni
              denominación alguna. En consecuencia, VALLE ENCANTADO actuará en
              nombre propio en las relaciones comerciales que se originen a
              propósito del presente contrato.
            </Text>

            <Text style={styles.subtitle}>DÉCIMA: CONFIDENCIALIDAD </Text>
            <Text style={{ textAlign: "justify", marginBottom: 10 }}>
              Las partes acuerdan que, si no cuentan con el consentimiento
              previo de la otra parte, no revelarán el contenido de este
              contrato a ningún tercero, a no ser que alguna de las partes tenga
              que revelar dicha información: (a) para implementar o hacer
              cumplir este contrato; (b) si se ve obligada a hacerlo por una
              ley, tribunal o norma; (c) si sus abogados le informan que está
              obligada legalmente a hacerlo o que el hecho de que no cumpla con
              hacerlo pueda dar lugar a que asuma alguna responsabilidad frente
              a cualquier otra Entidad o se genere el riesgo de la imposición de
              una sanción administrativa de cualquier tipo a sus asesores y
              auditores profesionales de ser el caso.
            </Text>

            <Text style={styles.subtitle}>
              DÉCIMO PRIMERA: PAGO DE TRÁMITES LEGALES{" "}
            </Text>
            <Text style={{ textAlign: "justify" }}>
              Las partes establecen que los gastos notariales correspondientes a
              la legalización del presente contrato, queda a cargo de EL
              ASOCIADO.
            </Text>
          </View>
        </View>

        <Text style={styles.footer}>5</Text>
      </Page>

      <Page wrap={true} size="A4">
        {/* 6 */}
        <View wrap={false} style={styles.page}>
          <View style={styles.header}>
            <Image
              style={[styles.imgHeader, { height: "80px" }]}
              src="/pdf/inresorts/1.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/2.png"
            />
            <Image
              style={[styles.imgHeader, { height: "50px" }]}
              src="/pdf/inresorts/3.png"
            />
          </View>

          <View style={styles.body}>
            <Text style={styles.subtitle}>
              DÉCIMO SEGUNDA: DOMICILIO Y NOTIFICACIONES LEGALES{" "}
            </Text>
            <Text style={{ textAlign: "justify", marginBottom: 10 }}>
              Las partes señalan como sus domicilios los consignados en la
              introducción del presente contrato. Cualquier comunicación o
              notificación deberá ser cursada a dichos domicilios por escrito,
              con cargo de recepción. En caso de cambio de domicilio, para su
              efecto o validez legal deberá comunicarse a la otra parte, con
              cargo de recepción y a la brevedad posible. En tanto no recepcione
              la otra parte la citada comunicación se entenderán válidamente
              efectuadas las comunicaciones remitidas al anterior domicilio y
              surtirán todos sus efectos legales. Cualquier variación del mismo
              deberá ser comunicada mediante carta notarial con una anticipación
              de treinta (30) días naturales, sin cuyo requisito no tendrá
              efecto la variación del domicilio.
            </Text>

            <Text style={styles.subtitle}>
              DÉCIMO TERCERA: LEGISLACIÓN APLICABLE{" "}
            </Text>
            <Text style={{ textAlign: "justify", marginBottom: 10 }}>
              El contrato se regirá por la Legislación Peruana. En todo lo no
              previsto por las partes en el presente contrato, ambas se someten
              a lo establecido por las normas del Código Civil, la Ley General
              de Sociedades, los principios de buena fe contractuales y demás
              del sistema jurídico que resulten aplicables.
            </Text>

            <Text style={styles.subtitle}>
              DÉCIMO CUARTA: COMPETENCIA ARIBITRAL
            </Text>
            <Text style={{ textAlign: "justify", marginBottom: 10 }}>
              En caso de cualquier discrepancia o conflicto que surgiera entre
              las partes, en relación con la celebración, interpretación,
              ejecución y/o efectos jurídicos, resolución o cualquier otro
              vinculado con el presente contrato, éste contrato será sometido a
              las disposiciones del Centro de Arbitraje Nacional e Internacional
              de la Cámara de Comercio de Lima.
            </Text>

            <Text style={{ textAlign: "justify" }}>
              Dicho Tribunal Arbitral estará constituido por tres (3) miembros.
              Cada parte escoge a un (1) árbitro, siendo el tercer miembro
              escogido por los árbitros de cada una de las partes. En caso este
              tercer miembro no se escoja dentro del plazo máximo de diez (10)
              días naturales, dicho tercer miembro será escogido por el mismo
              Centro de Arbitraje de la Cámara de Comercio de Lima. Este tercer
              miembro será quien presida el Tribunal Arbitral.
            </Text>

            <Text
              style={{
                textAlign: "justify",
                marginTop: 10,
              }}
            >
              El laudo del Tribunal será definitivo e inapelable, así como de
              obligatorio cumplimiento y ejecución para las partes.
            </Text>
            <Text
              style={{
                textAlign: "justify",
                marginTop: 10,
              }}
            >
              Cabe señalar que la parte que no se vea favorecida con el laudo
              expedido por el Tribunal Arbitral, será la que asuma el costo del
              procedimiento invocado, en cuanto a las costas y los costos.
            </Text>
            <Text
              style={{
                textAlign: "justify",
                marginTop: 10,
              }}
            >
              En señal de conformidad las partes suscriben este documento en la
              ciudad de Lima, a los {fecha[0]} días del mes de{" "}
              {obtenerNombreMes(parseInt(fecha[1]))} de {fecha[2]}.
            </Text>

            <View
              style={{
                paddingTop: 25,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <View style={{ flexDirection: "column", alignItems: "center" }}>
                <Image
                  src="/pdf/inresorts/firmaOmarUrteaga.jpg"
                  style={{ width: "100px", height: "50px" }}
                />
                <Text>____________________________</Text>
                <Text>VALLE ENCANTADO S.A.C.</Text>
                <Text>Omar Fernando Urteaga Cabrera</Text>
                <Text>Gerente General</Text>
                <Text>VALLE ENCANTADO</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <Text>____________________________</Text>
                <Text>{`${props.dataPDF.user.name} ${props.dataPDF.user.lastname}`}</Text>
                <Text>
                  Documento de identidad N°{props.dataPDF.user.nroDocument}
                </Text>
                <Text>EL ASOCIADO</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>6</Text>
      </Page>
    </Document>
  );
}
