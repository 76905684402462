import React from 'react';
import { Card, Button } from 'react-bootstrap';


export const BlockAlertConciliation = ({show = false, handleClose, onComprove}) => {
  return (
    <div
        style={{
            display: show ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: 1024,
            left: 0,
            top: 0,
            backgroundColor: 'rgb(0 0 0 / 70%)'
        }}
    >
        <Card style={{maxWidth: '480px'}}>
            <Card.Header as='h5'>¡Aviso Importante!</Card.Header>
            <Card.Body>
                <Card.Text style={{fontSize: '16px'}}>
                    Estimado socio o socia, por favor realizar el registro de conciliación, de lo contrario no podrá realizar sus funciones con normalidad.
                </Card.Text>
                <div className='d-flex flex-row justify-content-center mt-5'>
                    <Button variant="secondary" className='me-2' onClick={onComprove}>Ya registré mis conciliaciones</Button>
                    <Button variant="primary" className='' onClick={handleClose}>Ir a Conciliación</Button>
                </div>
            </Card.Body>
        </Card>
    </div>
  )
}
