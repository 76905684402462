import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import icon4 from "../../images/icons/money.png";
import icon1 from "../../images/icons/partner.png";
import icon2 from "../../images/icons/settings.png";
import icon5 from "../../images/icons/shopping-cart.png";
import icon3 from "../../images/icons/team.png";
import userService from "../../services/user.service";
import { BlockAlert } from "views/navigation/BlockAlert";
import { BlockAlertConciliation } from "views/navigation/BlockAlertConciliation";


import Swal from "sweetalert2";

export default class MenuHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuHome: true,
      showMenuSocio: false,
      showMenuTool: false,
      showMenuNet: false,
      showMenuPay: false,
      showMenuShop: false,
      loading: false,
      onlyPlacement: false,
      showNotification: false,
      showAlertBlock: false,
      repetBlockAlert: true,
      showAlertBlockCon: false,
    };
  }

  componentDidMount() {
    //this.getNotifications();
  }

  componentDidMount(){
    this.getNotificationPlacementByUser();
    this.getNoticationConciliationByUser();
  }

  // funcion NoticationConciliation request
  async getNoticationConciliationByUser(){
    let ok = false;
    const response = await userService.notificationsConciliationByUser();

    console.log('aqui notification conciliation', response);

    if ( response.objModel != null && response.objModel.length > 0){
      this.setState({
        showAlertBlockCon: true
      })

      ok = true;
    }else{
      this.setState({
        showAlertBlockCon: false
      })  
    }

    return ok;
  }

  async getNotificationPlacementByUser(){
    const response = await userService.notificationsPlacementByUser();
    let ok = false;

    if ( response.objModel != null && response.objModel.length > 0){
      this.setState({
        showAlertBlock: true
      })

      ok = true;
    }else{
      this.setState({
        repetBlockAlert: false,
        showAlertBlock: false
      })
    }
    console.log('response ver aqui', response);

    return ok;
  }

async getCheckNoticationConciliationByUser(){
  Swal.fire({
    position: "center",
    icon: 'info',
    title: "Comprobando...",
    showConfirmButton: false,
    
  });

  const response = await this.getNoticationConciliationByUser();
  
  if( response ){
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Gracias, por registrar sus conciliaciones...",
      showConfirmButton: false,
      timer: 1500
    });
    this.actionBlockAlert('2')
  }

  Swal.close()
}

async getUpdateNotificationPlacementByUser(){
  Swal.fire({
    position: "center",
    icon: 'info',
    title: "Comprobando...",
    showConfirmButton: false,
    
  });

  const response = await userService.updateNotificationPlacementByUser();
  
  if( response.status == 1 && response.objModel ){
   const resp = await this.getNotificationPlacementByUser();
    
   if( !resp ){
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Gracias, por posicionar a tus socios...",
      showConfirmButton: false,
      timer: 1500
    });
   }
  }

  Swal.close()
  
}

  getNotifications = async () => {
    this.setState({ loading: true });
    
    let response = await userService.getNotificationUser();
    console.log('aqui notificaciones', response)
    if (response !== undefined && response.status === 1) {
      let verify = response.objModel.filter(
        (item) => item.idTypeNotification === 4
      );

      if (verify.length > 0) {
        this.setState({ onlyPlacement: true, showNotification: true });
      }
    }
    this.setState({ loading: false });
  };

  onClicked = (e, path) => {
    this.props.onClick(e, path);
  };

  actionBlockAlert(type = '1'){
    this.setState(
      {
        showMenuHome: false,
        showMenuSocio: false,
        showMenuTool: false,
        showMenuNet: type == '1' ? true : false,
        showMenuPay: type == '1' ? false : true,
        showMenuShop: false,
        //showAlertBlock: false
      },
      () => this.props.onChange(this.state)
    );
  }

  async hideComponent(name) {
  


    switch (name) {
      case "showMenuHome":
        this.setState(
          {
            showMenuHome: true,
            showMenuSocio: false,
            showMenuTool: false,
            showMenuNet: false,
            showMenuPay: false,
            showMenuShop: false,
          },
          () => this.props.onChange(this.state)
        );
        break;
      case "showMenuSocio":
        this.setState(
          {
            showMenuHome: false,
            showMenuSocio: true,
            showMenuTool: false,
            showMenuNet: false,
            showMenuPay: false,
            showMenuShop: false,
          },
          () => this.props.onChange(this.state)
        );
        break;
      case "showMenuTool":
        this.setState(
          {
            showMenuHome: false,
            showMenuSocio: false,
            showMenuTool: true,
            showMenuNet: false,
            showMenuPay: false,
            showMenuShop: false,
          },
          () => this.props.onChange(this.state)
        );
        break;
      case "showMenuNet":
        this.setState(
          {
            showMenuHome: false,
            showMenuSocio: false,
            showMenuTool: false,
            showMenuNet: true,
            showMenuPay: false,
            showMenuShop: false,
          },
          () => this.props.onChange(this.state)
        );
        break;
      case "showMenuPay":
        this.setState(
          {
            showMenuHome: false,
            showMenuSocio: false,
            showMenuTool: false,
            showMenuNet: false,
            showMenuPay: true,
            showMenuShop: false,
          },
          () => this.props.onChange(this.state)
        );
        break;
      case "showMenuShop":
        this.setState(
          {
            showMenuHome: false,
            showMenuSocio: false,
            showMenuTool: false,
            showMenuNet: false,
            showMenuPay: false,
            showMenuShop: true,
          },
          () => this.props.onChange(this.state)
        );
        break;
      default:
        this.setState({ showMenuHome: true }, () =>
          this.props.onChange(this.state)
        );
    }
  }

  handleClose = () => {
    this.setState({ showNotification: false });
  };


  render() {
    const { loading, onlyPlacement, showNotification, showAlertBlock, showMenuNet, showAlertBlockCon, showMenuPay } = this.state;
    return (
      <div className="menu-content">
        {loading && (
          <div>
            <Spinner animation="border" variant="dark"></Spinner>
            <p>
              <Form.Label>Cargando información...</Form.Label>
            </p>
          </div>
        )}
        {!loading && (
          <Row>
            <Col sm={1}></Col>
            <Col xs={2} sm={2}>
              <div
                className="menu-item"
                style={onlyPlacement ? { display: "none" } : {}}
              >
                {/* <a  onClick={e => this.onClicked(e, "/sign-up")}> */}
                <a onClick={(e) => this.hideComponent("showMenuSocio")}>
                  <Image className="col-image" src={icon1}></Image>
                  <p>Nuevo Socio</p>
                </a>
              </div>
            </Col>
            <Col xs={3} sm={2}>
              <div
                className="menu-item"
                style={onlyPlacement ? { display: "none" } : {}}
              >
                {/* <a onClick={e => this.onClicked(e, "/sign-up")}> */}
                <a onClick={(e) => this.hideComponent("showMenuTool")}>
                  <Image className="col-image" src={icon2}></Image>
                  <p style={{ wordBreak: "break-all" }}>Herramientas</p>
                </a>
              </div>
            </Col>
            <Col xs={2} sm={2}>
              <div className="menu-item">
                <a onClick={() => this.hideComponent("showMenuNet")}>
                  <Image className="col-image" src={icon3}></Image>
                  <p>Mi Red</p>
                </a>
              </div>
            </Col>
            <Col xs={3} sm={2}>
              <div
                className="menu-item"
                style={onlyPlacement ? { display: "none" } : {}}
              >
                <a onClick={() => this.hideComponent("showMenuPay")}>
                  <Image className="col-image" src={icon4}></Image>
                  <p style={{ wordBreak: "break-all" }}>Comisiones y Pago</p>
                </a>
              </div>
            </Col>
            <Col xs={2} sm={2}>
              <div
                className="menu-item"
                style={onlyPlacement ? { display: "none" } : {}}
              >
                <a onClick={() => this.hideComponent("showMenuShop")}>
                  <Image className="col-image" src={icon5}></Image>
                  <p>Tienda</p>
                </a>
              </div>
            </Col>
            <Col sm={1}></Col>
          </Row>
        )}
        <Modal show={showNotification} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Notificacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Es necesario que posiciones a tus socios, hasta que no lo hagas se
            te bloquerá ciertas opciones de la oficina virtual
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        <BlockAlert 
          show={showAlertBlock && !showMenuNet ? true : false}
          handleClose={() => this.actionBlockAlert()}
          onComprove={() => this.getUpdateNotificationPlacementByUser()}
        />
        <BlockAlertConciliation 
          show={showAlertBlockCon && !showMenuPay ? true : false}
          handleClose={() => this.actionBlockAlert('2')}
          onComprove={() => this.getCheckNoticationConciliationByUser()}
        />
      </div>
    );
  }
}
