import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Image,
  Table,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

import PayPalButton from "../payment/PaypalButton";
import PayVoucher from "../payment/PayVoucher";
// import { PayPalButton } from 'react-paypal-button-v2'
import logo from "../../images/paypal-logo.png";
import logoBcp from "../../images/bcp-logo.jpg";
import logoInter from "../../images/interbank-logo.png";
import walletImge from "../../images/wallet.png";
import logoOther from "../../images/other-logo.png";
import BanColombia from "../../images/colombia/bancolombia.jpg";
import DaVivienda from "../../images/colombia/davivienda.jpg";
import UtilService from "../../services/utils.service";
import PayVoucherInter from "../payment/PayVoucherInter";
import PayWallet from "./PayWallet";
import PayOther from "./PayOther";
import PayVoucherDaVivienda from "./PayVoucherDaVivienda";


const diasDePeriodoDeGracia = localStorage.getItem("diasPeriodoGracia");
const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
export default class PayMultipleQuote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: props.quote,
      comision: 0,
      payQuotes: 0,
      overdue: 0,
      idPackage: props.quote.idPaquete,
      total: props.quote.totalPay,
      numQuotes: props.quote.numQuotes,
      totalQuotes: 1,
      newExpirationDate: "",
      countQuotes: 1,
      isOkQuote: true,
      showPayPal: false,
      showVoucher: false,
      showWallet: false,
      isPaid: false,
      typePaidMethod: 0,
      method: "",
      bank: "",
      vouchers: [],
      typePay: 0,
      typeBank: {},
      typeExchange: {
        venta: 1,
      },
      amountWallet: 0,
      typeTotal: 0,
      graceDaysUsed: 0,
      dataDias: {},
      // cupon
      cupon: "",
      showCupon: false,
      // days
      graceDays: 0,
    };

    this.getTypeBank = this.getTypeBank.bind(this);
    this.setCommission = this.setCommission.bind(this);
  }

  setCommission(commission) {
    this.setState({ comision: commission });
  }
  componentDidMount() {
    this.getTypeBank();
    this.getExchange();
    this.props.eventPay(this.state.total, "totalPay");
    this.obtenerDias();
    console.log('this.state.total mount', this.state.total);
  }

  // Get exchange dolar
  async getExchange() {
    let response = await UtilService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeExchange: (this.state.typeExchange = response.objModel),
        });
      }
    }
  }
  // async getTotalPayment() {}

  async getTypeBank() {
    let response = await UtilService.getTypeBank();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          typeBank: (this.state.typeBank = response.objModel),
        });
      }
    }
  }

  async obtenerDias() {
    let quote = this.state.quote;
    console.log("haber quote", quote);
    console.log("haber quote id subscription", quote.idSuscription);
    let response = await UtilService.getDays(quote.idSuscription);
    console.log(response);
    console.log(quote);
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          dataDias: (this.state.dataDias = response.objModel),
        });
      }
    }
  }
  /**
   * Connect with paypal
   * @param {*} e
   */
  onEventPayPal = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago con PayPal ha registrado exitosamente.");
      } else {
        if (this.state.vouchers.length > 0) {
          alert("Usted ha subido comprobantes de pago.");
        } else {
          this.handleShow();
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };
  /**
   * Event pay
   */
  eventPay = (value, field) => {
    console.log('eventPay', field, value)
    this.setState({ [field]: value });
    if (this.props.eventPay) {
      this.props.eventPay(value, field);
    }
  };

  // Handle modal
  handleClose = () => {
    this.setState({
      showPayPal: false,
    });
  };
  handleShow = () => {
    console.log(this.state.total, 'this.state.total');
    this.setState({
      showPayPal: true,
    });
  };

  /**Handle upload voucher */
  // Handle modal voucher
  // Add list of vouchers
  addVoucher = (voucherList) => {
    let vouchers = this.state.vouchers;
    vouchers = vouchers.concat(voucherList);
    this.setState({
      vouchers: vouchers,
    });
    this.props.eventPay(vouchers, "vouchers");
  };

  deleteVoucher = (e, idx) => {
    let temp = [];
    let vouchers = this.state.vouchers;
    let i = 0;
    for (i = 0; i < vouchers.length; i++) {
      if (i !== idx) {
        temp.push(vouchers[i]);
      }
    }
    this.setState({
      vouchers: temp,
    });
    this.props.eventPay(temp, "vouchers");
  };
  handleShowVoucher = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        if (bank === "BCP") {
          this.setState({
            showVoucher: true,
            bank: "BCP",
            isBcp: true,
            isInter: false,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Interbank") {
          this.setState({
            showVoucher: true,
            bank: "Interbank",
            isBcp: false,
            isInter: true,
            isDavi: false,
            isBancol: false,
          });
        } else if (bank === "Davivienda") {
          this.setState({
            showVoucher: true,
            bank: "Davivienda",
            isBcp: false,
            isInter: false,
            isDavi: true,
            isBancol: false,
          });
        } else if (bank === "Bancolombia") {
          this.setState({
            showVoucher: true,
            bank: "Bancolombia",
            isBcp: false,
            isInter: false,
            isDavi: false,
            isBancol: true,
          });
        }
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseVoucher = () => {
    this.setState({
      showVoucher: false,
    });
  };

  // Handle show modal wallet
  handleShowWallet = (e) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showWallet: true,
        });
      }
    } else {
      alert("Ocurrió un error al obtener información de paquete.");
    }
  };

  handleCloseWallet = () => {
    this.setState({
      showWallet: false,
    });
  };

  // Event Wallet
  addWallet = (amount) => {
    // console.log("event wallet");
    this.setState({
      amountWallet: (this.state.amountWallet = amount),
    });
  };

  // Register Payment only with wallet
  registerPayWallet = (amount) => {
    this.props.registerPayWallet(amount);
  };

  eventWallet = (amount) => {
    this.props.eventWallet(amount);
  };

  // Pay Other
  // Payment other
  handleShowOther = (e, bank) => {
    if (this.state.idPackage > 0) {
      if (this.state.isPaid) {
        alert("Su pago ha sido registrado con PayPal.");
      } else {
        this.setState({
          showOther: true,
          bank: "Otros",
          isOther: true,
          isBcp: false,
          isInter: false,
        });
      }
    } else {
      alert("Debe seleccionar un paquete de suscripción.");
    }
  };

  handleCloseOther = () => {
    this.setState({
      showOther: false,
    });
  };

  handleChange = async (e, type, cupon = "", days = 0, cuota) => {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////input number en PAGAR CUOTAS
    console.log("dio click en Aplicar multi");
    console.log("e.target.value ==>", e.target.value);
    console.log("quote.quoteUsd ==>", cuota);
    console.log("multiplicacion --->", cuota * e.target.value);
    console.log("this.state.quote.numQuotes", this.state.quote.numQuotes)
    let value =
      type === 3 || type === 2
        ? e
        : type === 1
        ? Number(e.target.value)
        : Number(e.target.value);
    this.setState({
      typeTotal: type,
    });

    console.log("this.state.quote.numQuotes", this.state.quote.numQuotes, "value", value, "type", type, "e.target.value", e.target.value)

    if (value >= 0 && value <= this.state.quote.numQuotes) {
      // let total = this.state.quote.quoteUsd * value;
      let total = 0;
      // add ep
      let form = {
        IdPayment: this.state.quote.idMembershipDetail,
        NumberQuotesPaid: value - 1,
        SuscriptionId: this.state.quote.idSuscription,
        TypeTotal: type,
        GracePeriodDays: days,
        OverdueCode: cupon,
      };
      console.log(this.state.numQuotes, 'this.state.numQuotes');
      if ( value > this.state.numQuotes ) {
        this.setState({
          isOkQuote: false,
        });
        return;
      }

      //console.log(parseFloat(cuota * e.target.value).toFixed(2), 'ssds')

      this.setState({
        payQuotes: cuota * e.target.value,
      });
      //  console.log('haber form =>',form)
      /*  await UtilService.PostCalculeGraciaDays(form).then((res) => {

        if (res.status == 0) {
          alert(res.description);
        }


        total = res.objModel.totalPay;


        let newDate = new Date(res.objModel.newExpirationDate);
        console.log("valor de type", type);
        this.setState({
          graceDaysUsed: res.objModel.graceDaysUsed,
          newExpirationDate: newDate.toLocaleDateString(),
          overdue: res.objModel.overdue,
        });

        if (type == 3) {
          console.log("valor de type", type);
          // con periodo de gracia
          this.props.ApplyGracePeriodAndCupon(
            1,
            res.objModel.graceDaysUsed,
            res.objModel.totalPay,
            0,
            "",
            res.objModel.newExpirationDate
          );
        } else if (type == 2) {
          console.log("valor de type", type);
          // cupon
          this.props.ApplyGracePeriodAndCupon(
            0,
            res.objModel.graceDaysUsed,
            res.objModel.totalPay,
            1,
            this.state.cupon
          );
        } 
      }); */
      console.log('total sds', total, 'total qouta', value)
      this.setState({
        total: (this.state.total = total),
        totalQuotes: (this.state.totalQuotes = value),
        isOkQuote: (this.state.isOkQuote = true),
      });
      if (this.props.eventPay) {
        console.log('sdsds', 'aqui')
        this.props.eventPay(value - 1, "quotesPay");
        this.props.eventPay(total, "totalPay");
      }
    } else {
      let total = this.state.quote.quoteUsd * 1;
      console.log("entro aquui ----------")

      this.setState({
        total: (this.state.total = total),
        totalQuotes: (this.state.totalQuotes = 1),
        isOkQuote: (this.state.isOkQuote = false),
        countQuotes: (this.state.countQuotes = 1),
      });

      if (this.props.eventPay) {
        this.props.eventPay(0, "quotesPay");
        this.props.eventPay(total, "totalPay");
      }
    }
  };

  /* FIN handleChange */
  sumartotalvoucher = () => {
    let vouchers = this.state.vouchers;
    let totalamount = Number(0);
    let cambio = Number(this.state.typeExchange.venta);
    let cantidad = 0;
    let moneda = "";

    for (let i = 0; i < vouchers.length; i++) {
      // Split base64 code type and string
      moneda = vouchers[i].typeExchangeDes;
      cantidad = Number(vouchers[i].amount);
      if (moneda == "Dólares") {
        //dolar
        totalamount += cantidad;
      } else {
        //moneda=2 sol
        //convertir sol a dolar
        cantidad /= cambio;
        totalamount += cantidad;
      }
    }
    //console.log(amountpay)
    return totalamount.toFixed(2);
  };

  displayCupon = () => {
    if (this.state.showCupon == false) {
      this.setState({
        showCupon: true,
      });
    } else if (this.state.showCupon == true) {
      this.setState({
        showCupon: false,
      });
    }
  };

  render() {
    const {
      quote,
      vouchers,
      isPaid,
      typeExchange,
      amountWallet,
      dataDias,
      total,
      payQuotes,
    } = this.state;
    console.log('ver TOTAL -->',total)
    const nacionalidadUsuario = sessionStorage.getItem("info")
      ? JSON.parse(sessionStorage.getItem("info")).idResidenceCountry
      : "";
    console.log("haber payQuotes", payQuotes);
    return (
      <div>
        <Row style={{ textAlign: "center" }}>
          <Col sm={12}>
            <Form.Label className="content-subtitle">Pagar cuotas</Form.Label>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p>
              <b>Suscripción:</b> {quote.namePackage}
            </p>
          </Col>
          <Col sm={6}>
            {this.props.mora ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: "10px",
                  flexDirection: "column",
                }}
              >
                {/*  <Form.Check
                  inline
                  type="checkbox"
                  label="Usar Cupon"
                  id={`inline-radio`}
                  style={{ marginTop: 2 }}
                  onChange={this.displayCupon}
                  checked={this.state.showCupon}
                /> */}
                {this.state.showCupon ? (
                  <div className="tw-flex">
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el codigo del cupon"
                      value={this.state.cupon}
                      onChange={(e) => this.setState({ cupon: e.target.value })}
                    />
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        this.handleChange(
                          this.state.totalQuotes,
                          2,
                          this.state.cupon
                        )
                      }
                    >
                      Aplicar
                    </Button>
                  </div>
                ) : diasDePeriodoDeGracia == 0 ? null : (
                  <>
                    <p className="tw-mb-0">Ingrese el numero de días</p>
                    <div className="tw-flex tw-column">
                      <Form.Control
                        type="number"
                        placeholder="Ingrese el numero de dias"
                        value={this.state.graceDays}
                        onChange={(e) =>
                          this.setState({ graceDays: Number(e.target.value) })
                        }
                      />
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() =>
                          this.handleChange(
                            this.state.totalQuotes,
                            3,
                            "",
                            this.state.graceDays
                          )
                        }
                      >
                        Aplicar
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : null}
          </Col>
          {/* <Col sm={6}>
            <div style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}>
              <Button variant="primary" size="sm" onClick={() => this.handleChange(this.state.totalQuotes, 3)}>Usar Periodos de gracia</Button>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <p>
              <b> Cuota:</b> {quote.quoteUsd} USD
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <Form inline>
              <p style={{ marginTop: "4px", marginBottom: "4px" }}>
                <b> Ingrese el número de cuotas a pagar : {this.state.numQuotes}</b>
              </p>
              <Form.Control
                id="txtQuotes"
                type="number"
                min="1"
                max={this.state.numQuotes}
                value={this.state.totalQuotes}
                onChange={(e) => {
                  this.handleChange(
                    e,
                    1,
                    "",
                    this.state.graceDays,
                    quote.quoteUsd
                  );
                }}
              ></Form.Control>
            </Form>
          </Col>
        </Row>
        <Row style={{ display: this.state.isOkQuote ? "none" : "block" }}>
          <Col>
            <Form.Text className="textAlert">
              El número máximo de cuotas a pagar es : {this.state.numQuotes}
            </Form.Text>
          </Col>
        </Row>
        {/* modificar */}
        {this.state.graceDaysUsed > 0 ? (
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <p>
                <b> Tiempo de gracia:</b> {this.state.graceDaysUsed} Dias
              </p>
            </Col>
          </Row>
        ) : null}
        <Row style={{ marginTop: "16px" }}>
          <Col>
            <p>
              <b> Total a pagar:</b> {quote.quoteUsd} USD
            </p>
          </Col>
        </Row>
        {payQuotes > 0 && (
          <Row>
            <Col>
              <p>
                <b> Total a pagar por las cuotas seleccionadas:</b> { payQuotes } USD
              </p>
            </Col>
          </Row>
        )}

        {this.state.comision > 0 && (
          <>
            <Row>
              <Col>
                <p>
                  <b>Comisión por medio de pago: </b> 
                    {(this.state.comision).toFixed(2)} USD
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p><b>Total a pagar más comisión por medio de pago:</b> {(payQuotes + this.state.comision).toFixed(2)} USD</p>
              </Col>
            </Row>
          </>
        )}

        {this.state.graceDaysUsed > 0 ? (
          <>
            <Row>
              <Col>
                <p>
                  <b> Nuevo fecha de pago :</b> {this.state.newExpirationDate}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b> Mora :</b> {this.state.overdue} USD
                </p>
              </Col>
            </Row>
          </>
        ) : null}

        <hr></hr>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Selecione un medio de pago: </Form.Label>
            </Col>
          </Row>
          <Row style={{ padding: 16 }}>
            {(() => {
              switch (nacionalidadUsuario) {
                case 45:
                  return (
                    <>
                     <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>

                      {/*  BANCO DAVIVIENDA */}
                      <Col sm={2}>
                        <Image
                          src={DaVivienda}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Davivienda");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
                case 167:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                        </Col>
                      <Col sm={2}>
                        <Image
                          src={logoBcp}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "BCP");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoInter}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowVoucher(e, "Interbank");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
                default:
                  return (
                    <>
                      <Col sm={2}>
                        <Image
                          src={logo}
                          rounded
                          style={{
                            width: "100%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={this.onEventPayPal}
                        ></Image>
                      </Col>

                      <Col sm={2}>
                        <Image
                          src={logoOther}
                          rounded
                          style={{
                            width: "120%",
                            maxWidth: 136,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            this.handleShowOther(e, "Otros");
                          }}
                        ></Image>
                      </Col>
                      <Col sm={3}>
                        <div
                          onClick={(e) => {
                            this.handleShowWallet(e);
                          }}
                        >
                          <p style={{ margin: 2, fontWeight: "bold" }}>
                            <Image
                              src={walletImge}
                              style={{ width: "32px", marginRight: "10px" }}
                            ></Image>
                            Mi Wallet
                          </p>
                        </div>
                      </Col>
                    </>
                  );
              }
            })()}
          </Row>
          {/* show the table if has register */}
          {vouchers.length > 0 && (
            <div className="mb-3">
              <Table responsive>
                <thead className="table-head">
                  <tr>
                    <th>Banco</th>
                    <th>Operación</th>
                    <th>Código</th>
                    <th>Nota</th>
                    <th>Archivo</th>
                    <th>Moneda</th>
                    <th>Monto</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vouchers.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.bank}</td>
                      <td>{item.typeDescription}</td>
                      <td>{item.code}</td>
                      <td>{item.nota}</td>
                      <td>{item.name}</td>
                      <td>{item.typeExchangeDes}</td>
                      <td>{item.amount}</td>
                      <td
                        style={{ fontSize: 15 }}
                        onClick={(e) => {
                          this.deleteVoucher(e, idx);
                        }}
                      >
                        <RiDeleteBinLine></RiDeleteBinLine>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* Show amount payed with wallet */}
          {amountWallet > 0 && (
            <Row
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 10 }}
            >
              <Col sm={12}>
                <Alert variant="success">
                  <b>Monto pagado con Wallet $ : </b>
                  {amountWallet}
                </Alert>
              </Col>
            </Row>
          )}
          {/* show the PayPal payment if it was successful */}

          {isPaid && (
            <Form.Group>
              <Form.Label>Su pago ha sido registrado con PAYPAL.</Form.Label>
            </Form.Group>
          )}
        </Form.Group>
        {/* Modal */}
    
        <Modal
          show={this.state.showPayPal}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>PayPal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayPalButton
                mount={payQuotes > 0 ? payQuotes : quote.quoteUsd}
                description={this.state.name}
                eventPay={this.eventPay}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
              ></PayPalButton>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            {/* <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button> */}
          </Modal.Footer>
        </Modal>
        {/* Modal upload voucher*/}
        <Modal
          show={this.state.showVoucher}
          onHide={this.handleCloseVoucher}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de {payQuotes} {this.state.bank}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isBcp && (
              <Form.Group>
                <PayVoucher
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  nacionalidadUsuario={nacionalidadUsuario}
                  totalPagar={payQuotes > 0 ? payQuotes : quote.quoteUsd}
                  setCommission={this.setCommission}
                ></PayVoucher>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
            {this.state.isInter && (
              <Form.Group>
                <PayVoucherInter
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  fontSize={10}
                  nacionalidadUsuario={nacionalidadUsuario}
                 // totalPagar={quote.quoteUsd}
                  totalPagar={payQuotes > 0 ? payQuotes : quote.quoteUsd}
                  setCommission={this.setCommission}
                ></PayVoucherInter>
              </Form.Group>
            )}
            {this.state.isDavi && (
              <Form.Group>
                <PayVoucherDaVivienda
                  total={this.state.total}
                  addVoucher={this.addVoucher}
                  close={this.handleCloseVoucher}
                  typeBank={this.state.typeBank}
                  exchange={typeExchange.venta}
                  nacionalidadUsuario={nacionalidadUsuario}
                  fontSize={10}
                 // totalPagar={quote.quoteUsd}
                  totalPagar={payQuotes > 0 ? payQuotes : quote.quoteUsd}
                ></PayVoucherDaVivienda>
                {/* <PayVoucher onChangePay= {this.onchangePayVoucher}></PayVoucher> */}
              </Form.Group>
            )}
          </Modal.Body>
        </Modal>

        {/* Modal pay with wallet*/}
        <Modal
          show={this.state.showWallet}
          onHide={this.handleCloseWallet}
          style={{ fontSize: 10 }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pago con Wallet </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayWallet
                idUser={quote.idUser}
                total={this.state.total}
                addWallet={this.addWallet}
                registerPayWallet={this.registerPayWallet}
                eventWallet={this.eventWallet}
                close={this.handleCloseWallet}
                fontSize={10}
                nacionalidadUsuario={nacionalidadUsuario}
               // totalPagar={quote.quoteUsd}
                totalPagar={payQuotes > 0 ? payQuotes : quote.quoteUsd}
              ></PayWallet>
            </Form.Group>
          </Modal.Body>
        </Modal>

        {/* Modal upload voucher other tickets*/}
        <Modal
          show={this.state.showOther}
          onHide={this.handleCloseOther}
          style={{ fontSize: 10 }}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Pago en efectivo a través de otros medios de pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <PayOther
                total={this.state.total}
                addVoucher={this.addVoucher}
                close={this.handleCloseOther}
                typeBank={this.state.typeBank}
                exchange={typeExchange.venta}
                fontSize={10}
                nacionalidadUsuario={nacionalidadUsuario}
               // totalPagar={quote.quoteUsd}
                totalPagar={payQuotes > 0 ? payQuotes : quote.quoteUsd}
                setCommission={this.setCommission}
              ></PayOther>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
