// https://codepen.io/pghiran/pen/XMoEmY
import React, { Component } from "react";
import {
  Form,
  Row,
  Button,
  Modal,
  Table,
  Spinner,
  Col,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import NoImage from "../../images/noimage.jpg";

import Validation from "../utils/Validation";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import "../../views/styles/ModalCustom.css";
import stateData from "../utils/dataKey.json";
import ModalContent from "components/ModalContent";
import userService from "../../services/user.service";
import Swal from "sweetalert2";
import Days from "./Days";

const url = `${process.env.REACT_APP_APP_URL}/register-quote`;
const url_mul = `${process.env.REACT_APP_APP_URL}/register-mul-quote`;

const colorActive = "#8bc34a";
const colorInactive = "#666464"; // Inactivo
const colorEndSusciption = "#aeaeae"; // "#9e9e9e"; //
// ------------------------------
const colorPendingVal = "#ffe924";
const colorPendingCuota = "#fffc4d";
const colorPendingMigracion = "#fffd84";
// -------------------------------
const colorRejectInicial = "#FF6000";
const colorRejectCuota = "#FF7900";
const colorRejectMigracion = "#FF8500";
// ------------------------------------
const colorDeuda1 = "#E01E37";
const colorDeuda2 = "#C1192C";
const colorDeuda3 = "#B71D32";
// --------------------------------
const colorPreliquidacion = "#075396";
const colorLiquidacion = "#104167";
// ------------------------------------
const colorCongelado =
  "linear-gradient(90deg, rgba(1,139,232,1) 0%, rgba(129,196,251,1) 50%, rgba(178,238,252,1) 100%)";


// const status array pendiente
const arrStatusVerifi = [10, 16];

export default class MyPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idDeLaSubscripcion: null,
      hijo: null,
      suscription: [],
      schedule: [],
      loadSuscription: true,
      noData: false,
      message: "",
      showModal: false,
      showImagesItem: false,
      collectionImage: [],
      itemCurrentQuote: undefined,
      showModalQuote: false,
      stateUser: "",
      totalActivos: 0,
      totalResidual: 0,
      loadSenData: false,
      currenteState: -1,
      showMessageFee: false,
      hasPending: false,
      states: [],
      isOpen: false,
      days: 1,
      inputMotivo: "",
      quotaDescription: "",
      allowGracePeriod: null,
      nombreSubscripcion: null,
    };

    this.toggleGallery = this.toggleGallery.bind(this);
    this.getSuscription = this.getSuscription.bind(this);
    this.getStates = this.getStates.bind(this);
  }

  componentDidMount() {
    this.getSuscription();
    this.getStates();
  }

  async getStates() {
    let response = await UserService.getStates();
    if (response !== undefined && response.status === 1) {
      this.setState({
        states: response.objModel,
      });
    }
  }
  toggleGallery() {
    this.setState((prevState) => ({
      galleryOpened: !prevState.galleryOpened,
    }));
  }

  async getSuscription() {
    let suscriptions = await UserService.getSuscriptionv2();
    console.log("haber suscriptions en getSuscription", suscriptions);
    if (suscriptions !== undefined && suscriptions !== null) {
      if (suscriptions.status !== 1) {
        this.setState({
          suscription: [],
          loadSuscription: false,
          noData: true,
          message: "Ocurrió un error mientras consultábamos su información.",
        });
      } else {
        if (suscriptions.objModel !== undefined) {
          this.setState({
            stateUser: suscriptions.objModel.stateNameUsuario,
            totalActivos: suscriptions.objModel.totalScoreActivoCompuesto,
            totalResidual: suscriptions.objModel.totalScoreActivoResidual,
          });
          if (suscriptions.objModel.suscriptions.length > 0) {
            this.setState({
              suscription: suscriptions.objModel.suscriptions,
              loadSuscription: false,
              noData: false,
              message: "",
            });
          }
        } else {
          this.setState({
            suscription: [],
            loadSuscription: false,
            noData: true,
            message: "No hay registros para mostrar.",
          });
        }
      }
    } else {
      this.setState({
        suscription: [],
        loadSuscription: false,
        noData: true,
        message: "Ocurrió un error. Intételo más tarde.",
      });
    }
  }

  // Handle modal
  getSchedule = async (e, item) => {
    e.preventDefault();
    console.log("SUBCRIPCION =>", item);
    console.log("SUBCRIPCION NOMBRE =>", item.package.name);
    console.log("STATUS SUSCRIPCION =>", item.status);
    let idSuscription = item.id;
    this.setState({ loadSenData: true });
    console.log("SUBCRIPCION ID =>", idSuscription);
    this.setState({
      idDeLaSubscripcion: idSuscription,
    });
    let schedule = await UserService.getScheduleWithImages(idSuscription);
    console.log("schedule general", schedule);
    this.setState({ loadSenData: false });

    if (schedule !== undefined && schedule !== null) {
      if (schedule.status === 1) {
        schedule.objModel.map((item) => {
          item.idSuscription = idSuscription;
          item.idUser = Number(AuthService.getCurrentIdUser());
          // let quote = item.quote/item.dollarExchange;
          // item.quoteUsd = Math.round((quote + Number.EPSILON) * 100) / 100;
        });

        let count = schedule.objModel.filter(
          (item) => item.verif === 0 || item.verif === 11
        ).length;

        // get last paid
        let lastPaid = schedule.objModel.find(
          (item) =>
            item.verif === stateData.codeState.inactivo ||
            item.verif === stateData.codeState.rechazoCuota
        );

        // verify if list has pending
        let pendingList = schedule.objModel.filter(
          (item) =>
            item.verif === stateData.codeState.pendValCuota ||
            item.verif === stateData.codeState.pendValInicial ||
            item.verif === stateData.codeState.pendValMigracion
        );

        // get next quote to pay
        let currentQuote = schedule.objModel.filter(
          (item) =>
            item.verif === 0 ||
            item.verif === 3 ||
            item.verif === 11 ||
            item.verif === 13 ||
            item.verif === 14
        );
        console.log("DETALLE CRONOGRAMA =>", schedule);
        console.log("DETALLE CURRENTQUOTE", currentQuote);
        

        this.setState({
          schedule: schedule.objModel,
          nombreSubscripcion: item.package.name,
          showModal: true,
          currentIdSuscripcion: idSuscription,
          currentState: item.status,
          unpaidFees: count,
          idLastPaid: lastPaid !== undefined ? lastPaid.idPayment : 0,
          currentQuote:
            currentQuote !== undefined && currentQuote.length > 0
              ? currentQuote[0].idPayment
              : -1,
          hasPending: pendingList.length > 0 ? true : false,
          quotaDescription: item.quotaDescription,
          allowGracePeriod: item.allowGracePeriod,
        });
      } else {
        this.setState({
          schedule: [],
          showModal: false,
          hasPending: false,
          currentIdSuscripcion: 0,
          unpaidFees: 0,
        });
        alert(
          "Tuvimos un error al obtener la información. Inténtelo más tarde."
        );
      }
    } else {
      this.setState({
        schedule: [],
        showModal: false,
        hasPending: false,
        currentIdSuscripcion: 0,
        unpaidFees: 0,
      });

      alert("Tuvimos un error al obtener la información. Inténtelo más tarde.");
    }
  };

  onShowImage = (e, item) => {
    this.setState({
      showImagesItem: true,
      collectionImage: item.paymentVouchers,
    });
  };

  backToDetail = (e) => {
    this.setState({
      showImagesItem: false,
      collectionImage: [],
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
      hasPending: false,
      schedule: [],
      showImagesItem: false,
      collectionImage: [],
      currentIdSuscripcio: 0,
    });
  };

  handleShow = () => {
    this.setState({
      showModal: true,
    });
  };

  /**
   * Handle payment of quotes
   */
  payQuote = (e, itemQuote) => {
    // Verify
    if (
      this.state.currenteState !== stateData.codeState.pendValInicial &&
      this.state.currenteState !== stateData.codeState.rechazoInicial &&
      this.state.currenteState !== stateData.codeState.pagarDespues &&
      this.state.currenteState !== stateData.codeState.pendValMigracion &&
      this.state.currenteState !== stateData.codeState.rechazoMigracion &&
      this.state.currenteState !== stateData.codeState.finalizada
    ) {
      this.setState({
        itemCurrentQuote: (this.state.itemCurrentQuote = itemQuote),
      });
      // Save item quote
      localStorage.setItem("currentQuote", JSON.stringify(itemQuote));
      // redireect new page sha512
      let field =
        "6f1e7dc9b9c43fb7dd2ff99b7a6bc0cb5607e8b03d5a97ae3004685338a7f1821ec146da4567500bd97fb2e851df1a1b99361a7ff2366b7700ebc856d702cb69";
      window.open(
        url +
          `?${field}=&ms=${itemQuote.idPayment}=&sus=${itemQuote.idSuscription}`,
        "_blank"
      );
      this.handleClose();
    } else {
      this.setState({ showMessageFee: true });
      setTimeout(this.handleCloseMessage, 2500, "funky");
    }
  };

  /**Handle multiples quotes */
  payMultiplesQuotes = (e) => {
    if (this.state.currentIdSuscripcion > 0) {
      // redireect new page sha512
      let field =
        "6f1e7dc9b9c43fb7dd2ff99b7a6bc0cb5607e8b03d5a97ae3004685338a7f1821ec146da4567500bd97fb2e851df1a1b99361a7ff2366b7700ebc856d702cb69";
      window.open(
        url_mul +
          `?${field}=&q=${this.state.unpaidFees}=&sus=${this.state.currentIdSuscripcion}=&id=${this.state.idLastPaid}`,
        "_blank"
      );
      this.handleClose();
    }
  };

  handleCloseMessage = () => {
    this.setState({ showMessageFee: false });
  };

  getMessage(show) {
    return (
      <div>
        {show && (
          <Alert
            variant="primary"
            onClick={() => this.setState({ showMessageFee: false })}
            dismissible
          >
            El pago de su cuota inicial o migración debe de ser validados para
            que pueda realizar el pago de alguna cuota.
          </Alert>
        )}
      </div>
    );
  }

  allowPayment = (currentState) => {
    let allowPayment = false;

    switch (currentState) {
      case 2:
        // Pendiente de validación inicial
        allowPayment = true;
        break;
      case 10:
        // Pendiente de validación";
        allowPayment = true;
        break;
      case 13:
        // Pendiente de validacion migracion";
        allowPayment = true;
        break;
    }

    return allowPayment;
  };

  /**End modal to pay quote */

  getState = (idState) => {
    let colorState = "";
    switch (idState) {
      case 0:
        colorState = colorInactive;
        break;
      case 1:
        colorState = colorActive;
        break;
      case 2:
        colorState = colorPendingVal;
        break;
      case 3:
        colorState = colorRejectInicial;
        break;
      case 4:
        colorState = colorActive;
        break;
      case 5:
        colorState = colorDeuda1;
        break;
      case 6:
        colorState = colorDeuda2;
        break;
      case 7:
        colorState = colorDeuda3;
      case 8:
        colorState = colorPreliquidacion;
        break;
      case 9:
        colorState = colorCongelado;
        break;
      case 10:
        colorState = colorPendingCuota;
        break;
      case 11:
        colorState = colorRejectCuota;
        break;
      case 12:
        colorState = colorEndSusciption;
        break;
      case 13:
        colorState = colorPendingMigracion;
        break;
      case 14:
        colorState = colorRejectMigracion;
        break;
      case 15:
        colorState = colorLiquidacion;
        break;
    }

    return colorState;
  };
  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };
  handleShowModal = () => {
    this.setState({ isOpen: true });
  };
  handleChangeDays = (e) => {
    this.setState({ days: Number(e.target.value) });
  };
  handleChangeInputMotivo = (e) => {
    this.setState({ inputMotivo: (e.target.value) });
  };
  useGracePeriod = async (e) => {
    let SuscriptionId = this.state.currentIdSuscripcion;
    let Days = this.state.days;
    let InputMotivo = this.state.inputMotivo;
    let data = {
      idsuscription:SuscriptionId,
      days: Days,
      observation:InputMotivo,
    };
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Esperando respuesta...",
      showConfirmButton: false,
      timer: 2500,
      toast: true,
    });
    await userService.useGracePeriod(data).then(async (res) => {
      //console.log("haber resp useGracePeriod", res);
      if (res.status === 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha aplicado el periodo de gracia",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
        this.setState({ isOpen: false });
        this.handleClose();
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: res.description,
          showConfirmButton: false,
          timer: 1500,
          toast: true,
        });
      }
    });
  };

  render() {
    const {
      suscription,
      loadSuscription,
      noData,
      message,
      loadModal,
      collectionImage,
      showImagesItem,
      showModalQuote,
      itemCurrentQuote,
      totalActivos,
      totalResidual,
      stateUser,
      idDeLaSubscripcion,
      currentState,
      allowGracePeriod,
      hijo,
      nombreSubscripcion,
    } = this.state;
    //console.log("schedule", this.state.schedule);
    console.log("SUBSCRIPCIONES =>", suscription);
    console.log("Subscripcion Nombre :", nombreSubscripcion);
    return (
      <div style={{ padding: 30 }}>
        {loadSuscription && (
          <div>
            <Spinner animation="border" variant="dark"></Spinner>
            <p>
              <Form.Label>Cargando información...</Form.Label>
            </p>
          </div>
        )}
        {!loadSuscription && (
          <div className="card-wallet">
            {/* <p className="title-wallet">Puntaje total por rama: 0.00</p> */}
            <Row style={{ marginTop: 16 }}>
              <Col sm={4}>
                <p className="label-title">Status: {stateUser}</p>
              </Col>
              {/* </Row>
                        <Row> */}
              <Col sm={4}>
                <p className="label-title">
                  {" "}
                  Puntaje Activo Compuesto: {totalActivos}
                </p>
              </Col>
              {/* </Row>
                        <Row> */}
              <Col sm={4}>
                <p className="label-title">
                  {" "}
                  Puntaje Activo Residual: {totalResidual}
                </p>
              </Col>
            </Row>
          </div>
        )}
        {!loadSuscription && !noData && (
          <div className="box-container-wrap">
            {/* AQUI SE LISTAN LAS CARDS */}
            {suscription.map((item, id) => {
              let classCard = "card-suscription-default";
              let colorState = this.getState(item.status);
              return (
                <div
                  className={classCard}
                  key={id}
                  onClick={(e) => this.getSchedule(e, item)}
                  style={
                    colorState.length > 0
                      ? { border: `solid 2px ${colorState}` }
                      : {}
                  }
                >
                  {/* AQUI ES SI ES ACTIVO O NO */}
                  <div
                    className="card-header-suscription-default"
                    style={
                      colorState.length > 0
                        ? {
                            background: colorState,
                            color:
                              item.status === 0 ||
                              item.status === 8 ||
                              item.status === 15
                                ? "white"
                                : "#142d47",
                          }
                        : {}
                    }
                  >
                    {item.statusName}
                  </div>

                  <div className="card-main-suscription">
                    <p className="content-title">{item.package.name}</p>
                    <div style={{ display: "flex" }}>
                      <div style={{ paddingRight: 7 }}>
                        <b>
                          PAC:{" "}
                          {item.scoreActiveCompuesto
                            ? item.scoreActiveCompuesto
                            : "0"}
                        </b>
                      </div>
                      <div style={{ paddingLeft: 7 }}>
                        <b>
                          PAR:{" "}
                          {item.scoreActiveResidual
                            ? item.scoreActiveResidual
                            : "0"}
                        </b>
                      </div>
                    </div>
                    <div className="main-suscription">
                      <b>Descripción de pago:</b> {item.quotaDescription}
                    </div>
                    <div className="main-suscription">
                      <b>Puntaje:</b> {item.package.volume}
                    </div>
                    <div className="main-suscription">
                      <b>Fecha de adquisición:</b>{" "}
                      {Validation.convertDateToString(item.creationDate)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {noData && (
          <div>
            <Form.Label>{message}</Form.Label>
          </div>
        )}
        {/* Modal */}
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.handleClose}
          style={{ fontSize: 12 }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Detalle de cronograma</Modal.Title>
          </Modal.Header>
          <Modal.Body textAlign="center">
            {/* aqui va los dias de periodo de gracia */}
            <Days idDeLaSubscripcion={idDeLaSubscripcion} hijo={null} />
            {/*  {console.log("haber hijo", hijo)} */}
            {/* aqui va los dias de periodo de gracia */}
            <Row style={{ textAlign: "right" }}>
              {!this.state.hasPending &&
                !this.state.quotaDescription.includes(
                  "No tiene cuotas pendientes de pago"
                ) && (
                  <>
                    {/* esto se comento */}
                    {/* <Col sm={12}>
                   <Button
                       size="sm"
                       variant="primary"
                       onClick={(e) => this.payMultiplesQuotes(e)}
                       >
                       Pagar varias cuotas
                   </Button>
               </Col> */}
                    {/* esto se agrego */}
                    {/*  este es el status */}

                       {currentState == 1 && (
                      <>
                        <Col sm={6}>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={this.handleShowModal}
                          >
                            Solicitar período de gracia adelantado
                          </Button>

                          <ModalContent
                            title="Usar perido de gracia"
                            isOpen={this.state.isOpen}
                            handleClose={this.handleCloseModal}
                          >
                            <div
                              className="
                       tw-flex 
                       tw-align-center
                       tw-justify-center
                       tw-flex-col
                       tw-p-4
                       tw-items-center
                       tw-text-center
                       "
                            >
                              <Form inline>
                                <div>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="text-start">
                                      Indicar el periodo de gracia que desea
                                      usar
                                    </Form.Label>
                                    <Form.Control
                                      id="txtDays"
                                      type="number"
                                      min="1"
                                      // max={this.state.numQuotes}
                                      value={this.state.days}
                                      className="w-100"
                                      onChange={(e) => this.handleChangeDays(e)}
                                      //style={{
                                      //width: "100px",
                                      //margin: "0px auto",
                                      //}}
                                    ></Form.Control>
                                  </Form.Group>

                                  <Form.Group className="mb-3">
                                    <Form.Label className="text-start w-100">
                                      Motivo
                                    </Form.Label>
                                    <Form.Control as="textarea" rows={3} 
                                    onChange={(e) => this.handleChangeInputMotivo(e)}
                                    />
                                  </Form.Group>
                                </div>

                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={(e) => this.useGracePeriod(e)}
                                  style={{ marginTop: "10px" }}
                                >
                                  <b>Enviar solicitud</b>
                                </Button>
                              </Form>
                            </div>
                          </ModalContent>
                        </Col>
                        <Col sm={6}>
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={(e) => this.payMultiplesQuotes(e)}
                      >
                        Pagar varias cuotas
                      </Button>
                    </Col>
                      </>
                    )} 

                    
                  </>
                )}
              {/* <Col sm={3}><Button size="sm" variant="secondary" block>Adelanto de pago de cuotas</Button></Col>
                            <Col sm={3}><Button size="sm" variant="secondary" block>Congelamiento de Deuda</Button></Col> */}
            </Row>
            <Row>
              <Col sm={12}>{this.getMessage(this.state.showMessageFee)}</Col>
            </Row>
            <Row>
              {this.state.hasPending && (
                <Col sm={12}>{this.getMessage(this.state.hasPending)}</Col>
              )}
            </Row>
            <br></br>
            {!showImagesItem && (
              <Row>
                <Col>
                  <Table responsive>
                    <thead className="table-head">
                      <tr>
                        <th>Descripción</th>
                        <th>Fecha de vencimiento</th>
                        <th>Capital</th>
                        <th>Amortización</th>
                        <th>Ínteres</th>
                        <th>Mora</th>
                        <th>Cuota</th>
                        <th>Total</th>
                        <th>Fecha de Pago</th>
                        <th>Estado</th>
                        <th></th>
                        <th>Observación</th>
                      </tr>
                    </thead>
                    {nombreSubscripcion &&
                    nombreSubscripcion.includes("Semilla") ? (
                      <tbody>
                        {console.log(
                          "DETALLE CRONOGRAMA SEMILLA =>",
                          this.state.schedule
                        )}

                        {this.state.schedule.map((item, idx) => {
                          let amortization =
                            item.amortization / item.dollarExchange;
                          amortization =
                            Math.round((amortization + Number.EPSILON) * 100) /
                            100;

                          let capitalBalance =
                            item.capitalBalance / item.dollarExchange;
                          capitalBalance =
                            Math.round(
                              (capitalBalance + Number.EPSILON) * 100
                            ) / 100;

                          let descriptionState =
                            item.verif == 0
                              ? ""
                              : Validation.getState(item.verif, true).state;
                          console.log("haber idx", idx);

                          let activeBTN = true;

                          if( idx > 0 && arrStatusVerifi.includes(this.state.schedule[idx - 1].verif)){
                            console.log(item, item.verif === 0)
                            activeBTN = false;
                          }
                          if (idx == 0) {
                            return (
                              <tr key={idx}>
                                <td>{item.quoteDescription}</td>
                                <td>
                                  {Validation.convertDateToStringEx(
                                    item.nextExpiration
                                  )}
                                </td>
                                <td>{capitalBalance} USD</td>
                                <td>{amortization} USD</td>
                                <td>{item.interested} USD</td>
                                <td>{item.totalOverdue} USD</td>
                                <td>{item.quoteUsd} USD</td>
                                <td>{item.totalPay} USD</td>
                                <td>
                                  {Validation.convertDateToStringEx(
                                    item.payDate
                                  )}
                                </td>
                                <td>{descriptionState}</td>
                                <td style={{ textAlign: "center" }}>
                                  {(item.verif === 0 ||
                                    item.verif === 3 ||
                                    item.verif === 11 ||
                                    item.verif === 13 ||
                                    item.verif === 14) && (
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={
                                        this.state.hasPending ||
                                        this.state.currentQuote !==
                                          item.idPayment
                                        || !activeBTN
                                          ? null
                                          : (e) => this.payQuote(e, item)
                                      }
                                      disabled={
                                        this.state.hasPending ||
                                        this.state.currentQuote !==
                                          item.idPayment
                                        || !activeBTN
                                      }
                                    >
                                      Pagar
                                    </Button>
                                  )}
                                  {item.verif === 1 &&
                                    item.paymentVouchers.length === 1 && (
                                      <img
                                        src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                        width={50}
                                        style={{ height: "auto", width: 32 }}
                                        onClick={(e) => {
                                          this.onShowImage(e, item);
                                        }}
                                      />
                                    )}
                                  {item.verif === 2 &&
                                    item.paymentVouchers.length === 1 && (
                                      <img
                                        src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                        width={50}
                                        style={{ height: "auto", width: 32 }}
                                        onClick={(e) => {
                                          this.onShowImage(e, item);
                                        }}
                                      />
                                    )}
                                  {item.verif === 10 &&
                                    item.paymentVouchers.length > 0 && (
                                      <img
                                        src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                        width={50}
                                        style={{ height: "auto", width: 32 }}
                                        onClick={(e) => {
                                          this.onShowImage(e, item);
                                        }}
                                      />
                                    )
                                  // <img src={"https://www.hola.com/imagenes/estar-bien/20180925130054/consejos-para-cuidar-a-un-gatito-recien-nacido-cs/0-601-526/cuidardgatito-t.jpg"} style={{margin: 10, height: 'auto', height: auto, width: 80}}></img>
                                  }

                                  {item.verif === 1 &&
                                    item.paymentVouchers.length > 1 && (
                                      <img
                                        src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                        width={50}
                                        style={{ height: "auto", width: 32 }}
                                        onClick={(e) => {
                                          this.onShowImage(e, item);
                                        }}
                                      />
                                    )}
                                </td>
                                <td>{item.obs}</td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        {console.log(
                          "DETALLE CRONOGRAMA =>",
                          this.state.schedule
                        )}

                        {
                        this.state.schedule.map((item, idx) => {
                          let amortization =
                            item.amortization / item.dollarExchange;
                          amortization =
                            Math.round((amortization + Number.EPSILON) * 100) /
                            100;

                          let capitalBalance =
                            item.capitalBalance / item.dollarExchange;
                          capitalBalance =
                            Math.round(
                              (capitalBalance + Number.EPSILON) * 100
                            ) / 100;

                          let descriptionState =
                            item.verif == 0
                              ? ""
                              : Validation.getState(item.verif, true).state;
                          
                          let activeBTN = true;

                          if( idx > 0 && arrStatusVerifi.includes(this.state.schedule[idx - 1].verif)){
                            console.log(item, item.verif === 0)
                            activeBTN = false;
                          }
                          return (
                            <tr key={idx}>
                              <td>{item.quoteDescription}</td>
                              <td>
                                {Validation.convertDateToStringEx(
                                  item.nextExpiration
                                )}
                              </td>
                              <td>{capitalBalance} USD</td>
                              <td>{amortization} USD</td>
                              <td>{item.interested} USD</td>
                              <td>{(item.totalOverdue).toFixed(2)} USD</td>
                              <td>{item.quoteUsd} USD</td>
                              <td>{(item.totalPay).toFixed(2)} USD</td>
                              <td>
                                {Validation.convertDateToStringEx(item.payDate)}
                              </td>
                              <td>{descriptionState}</td>
                              <td style={{ textAlign: "center" }}>
                                {(item.verif === 0 ||
                                  item.verif === 3 ||
                                  item.verif === 11 ||
                                  item.verif === 13 ||
                                  item.verif === 14) && (
                                  <Button
                                    size="sm"
                                    variant="success"
                                    onClick={
                                      this.state.hasPending ||
                                      this.state.currentQuote !== item.idPayment
                                      || !activeBTN
                                        ? null
                                        : (e) => this.payQuote(e, item)
                                    }
                                    disabled={
                                      this.state.hasPending ||
                                      this.state.currentQuote !== item.idPayment
                                      || !activeBTN
                                    }
                                  >
                                    Pagar
                                  </Button>
                                )}
                                {item.verif === 1 &&
                                  item.paymentVouchers.length === 1 && (
                                    <img
                                      src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                      width={50}
                                      style={{ height: "auto", width: 32 }}
                                      onClick={(e) => {
                                        this.onShowImage(e, item);
                                      }}
                                    />
                                  )}
                                {item.verif === 2 &&
                                  item.paymentVouchers.length === 1 && (
                                    <img
                                      src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                      width={50}
                                      style={{ height: "auto", width: 32 }}
                                      onClick={(e) => {
                                        this.onShowImage(e, item);
                                      }}
                                    />
                                  )}
                                {item.verif === 10 &&
                                  item.paymentVouchers.length > 0 && (
                                    <img
                                      src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                      width={50}
                                      style={{ height: "auto", width: 32 }}
                                      onClick={(e) => {
                                        this.onShowImage(e, item);
                                      }}
                                    />
                                  )
                                // <img src={"https://www.hola.com/imagenes/estar-bien/20180925130054/consejos-para-cuidar-a-un-gatito-recien-nacido-cs/0-601-526/cuidardgatito-t.jpg"} style={{margin: 10, height: 'auto', height: auto, width: 80}}></img>
                                }

                                {item.verif === 1 &&
                                  item.paymentVouchers.length > 1 && (
                                    <img
                                      src={`data:image/jpeg;base64,${item.paymentVouchers[0].base64}`}
                                      width={50}
                                      style={{ height: "auto", width: 32 }}
                                      onClick={(e) => {
                                        this.onShowImage(e, item);
                                      }}
                                    />
                                  )}
                              </td>
                              <td>{item.obs}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                </Col>
              </Row>
            )}
            {showImagesItem && (
              <Form.Group>
                <Row style={{ textAlign: "right" }}>
                  <Col sm={12}>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={(e) => {
                        this.backToDetail(e);
                      }}
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="box-container-wrap">
                      {collectionImage.map((image, id) => {
                        let src = "";
                        // Verify length of base 64
                        if (image.base64.length > 40) {
                          src = `data:image/jpeg;base64,${image.base64}`;
                        } else {
                          src = NoImage;
                        }
                        return (
                          <div className="card-main-suscription">
                            <Form.Label>
                              <strong>Codigo:</strong> {image.operationNumber}
                            </Form.Label>
                            <img
                              key={id}
                              src={src}
                              width={350}
                              style={{ margin: 10, height: "auto" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!showImagesItem && (
              <Button variant="secondary" onClick={this.handleClose}>
                Cerrar
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.loadSenData}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Spinner size="sm" animation="border" variant="dark"></Spinner>
              <Form.Label>&nbsp; Obteniendo cronograma...</Form.Label>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
