import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import UtilService from "../../services/utils.service";

export const FormBeneficiary = ({
    show = false,
    onCloseModal,
    idNationality,
    typeFormBeneficiary,
    objectBeneficiary,
    onSubmitForm
 }) => {
    const [typeDocuments, setTypeDocuments] = useState([]);
    const [beneficiary, setBeneficiary] = useState(objectBeneficiary);

    useEffect(() => {
        getTypeDocuments(idNationality)

    }, [idNationality])

    useEffect(() => {

        setBeneficiary((values => ({...values, ...objectBeneficiary})))

    }, [objectBeneficiary])
    
    
    const getTypeDocuments = async(id) => {
        if (id > 0) {
            let response = await UtilService.getTypeDocByNat(id);
            console.log(response)
            if (response !== null && response !== undefined) {
              let items = [];
              if (response.status === 1 && response.objModel.length > 0) {
                items = response.objModel;
              } 
      
              setTypeDocuments(items)
            }
          }
    }

    /**
     * Method to handle the change  of properties and send it to parent
     * @param {*} e
     * @param {*} field
     */
     const handleChange = (e, field) => {
        let value = e.target.value.trim();
        setBeneficiary(values => ({...values, [field] : value }))
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { name, lastname, idDocument, nroDocument } = beneficiary;

        if( name == '' || lastname == '' || idDocument == '' || nroDocument == '' ){
            alert('Debe completar todo el formulario para el registro');
            return;
        }

        const data = {...beneficiary, idDocument: Number(idDocument)}

        onSubmitForm(data, typeFormBeneficiary);
    }

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            style={{ fontSize: 10 }}
            size="lg"
            backdrop="static"
            >
            <Modal.Header closeButton>
                <Modal.Title>{typeFormBeneficiary == 'store' ? 'Nuevo' : 'Editar'} Beneficiario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => onSubmit(e)}>
                    <Form.Group>
                        {/*<Form.Group>
                        <h4>{typeFormBeneficiary == 'store' ? 'A' : 'Editar'} Beneficiario</h4>
                    </Form.Group>*/}
                        <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Nombres *</Form.Label>
                                <Form.Control
                                  required
                                  value={beneficiary.name}
                                  type="text"
                                  placeholder="Nombres"
                                  onChange={(e) =>
                                    handleChange(e, "name")
                                  }
                                />
                              </Form.Group>
                            </Col>

                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Apellidos *</Form.Label>
                                <Form.Control
                                  required
                                  value={beneficiary.lastname}
                                  type="text"
                                  placeholder="Apellidos"
                                  onChange={(e) =>
                                    handleChange(e, "lastname")
                                  }
                                />
                              </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: '15px'}}>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Tipo de documento *</Form.Label>
                                    <Form.Select
                                        defaultValue={""}
                                        value={beneficiary.idDocument}
                                        size="sm"
                                        onChange={(e) =>
                                            setBeneficiary(values => ({...values, idDocument: e.target.value}))
                                        }
                                    >
                                        <option value="" disabled>
                                        Seleccione una opción
                                        </option>
                                        { 
                                        typeDocuments.map((elem) => (
                                            <option key={elem.id} value={elem.id}>
                                            {elem.name}
                                            </option>
                                        ) )
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={6}  className='mb-3' >
                                <Form.Group>
                                    <Form.Label>Nro. de documento *</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={beneficiary.nroDocument}
                                        placeholder="Nro. documento"
                                        onChange={(e) => handleChange(e, "nroDocument")}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                        <Col sm={6} style={{ textAlign: "left" }}>
                            <Button
                            variant="secondary"
                            size="sm"
                            onClick={onCloseModal}
                            type='button'
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col sm={6} style={{ textAlign: "right" }}>
                            <Button
                                variant="primary"
                                size="sm"
                                type='submit'
                            >
                             Guardar
                            </Button>
                        </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

FormBeneficiary.prototype = {
    show: PropTypes.bool.isRequired, 
    onCloseModal: PropTypes.func.isRequired,
    idNationality: PropTypes.number.isRequired,
    typeFormBeneficiary: PropTypes.string.isRequired,
    objectBeneficiary: PropTypes.object.isRequired,
    onSubmitForm: PropTypes.func.isRequired
}
  