import AuthService from "./auth.service";
import axios from "axios";
const API_URL = `${process.env.REACT_APP_APP_URL_API}/`;
const API_USR = `${process.env.REACT_APP_APP_URL_API}/User`;

class UserService {
  async getTicketUser(id) {
    let url = API_URL + "Ticket/list/" + id;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getRange() {
    let url = API_URL + "range/getrangeslist";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getStates() {
    let url = API_URL + "state/list";

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getUserInformation() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "User/" + id;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async updateInfo(id, data) {
    let url_update = API_USR + "/" + "edituserprofile/" + id;
    let user = AuthService.getCurrentUser();

    return await fetch(url_update, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_Token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        //;
        if (response.access_Token !== null) {
          let idUser = AuthService.getCurrentIdUser();
          AuthService.getUserInformation(idUser);
        }
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // TODO change id
  async getResidualTree() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "tree/residual/" + id;
    let user = AuthService.getCurrentUser();

    // {
    //     method:'GET',
    //     headers:{
    //         'Content-Type':'application/json',
    //         'Authorization': `Bearer ${user.access_Token}`
    //     }
    // }
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // TODO profilePicture

  async updateProfilePicture(data) {
    let url_verifydoc = API_USR + "/update/profilePicture";

    return await fetch(url_verifydoc, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  // Get sponsor by iduser
  async getSponsorbyUser() {
    let id = AuthService.getCurrentIdUser();
    let url = API_USR + "/sponsor/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfobyUser() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "coaffiliate/usercoaffiliate/" + id;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // Update user Info
  async updateAffiliateInfo(data) {
    let url_verifydoc = API_URL + "coaffiliate/updateusercoaffiliate";

    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  async registerCoafiliate(data) {
    let url_verifydoc = API_URL + "coaffiliate/insert";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel

        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // get profile picture
  async getProfilePicture(idUser) {
    let url = API_USR + "/profilePicture/" + idUser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // TODO
  async getPatrocinioTree() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "tree/Affiliate/" + id;
    let user = AuthService.getCurrentUser();

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get suscription
  async getSuscription() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "suscription/user/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async getSuscriptionv2() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "suscription/payment/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getCertificatesbyUser() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "certificate/shares/" + id + "/user";
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get suscription upgrade
  async getUpgradePackages() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "suscription/upgrade/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get suscription by ID suscripcion
  async validatetokenAfterPay(id) {
    let url = API_URL + "register/validate?token=" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get suscription by ID suscripcion
  async getSuscriptionById(id) {
    let url = API_URL + "suscription/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get Schedule - first inmplement
  async getSchedule(idSuscription) {
    let url = API_URL + "membershipPayDetail/schedules/" + idSuscription;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get schedule with tickets
  async getScheduleTickets(idSuscription) {
    let url =
      API_URL +
      "affilliatependingcronogram/affiliatependingcronogramlist/" +
      idSuscription;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get Schedule with ticket as image
  async getScheduleWithImages(idSuscription) {
    let url = API_URL + "payment/schedule/vouchers/" + idSuscription + '/1';
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
      //  console.log("PETICION getScheduleWithImages =>", response);
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get Schedule with ticket as image
  async getScheduleByIdSus(idSuscription) {
    let url = API_URL + "membershipPayDetail/schedule/" + idSuscription;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get register for placemen t: user that are not positions
  async getRegPlacement() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "tree/placement/upliners/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getUpLiner(id) {
    let url = API_URL + "tree/placement/upliners/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get sponsors
  async getSponsors() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "coaffiliate/sponsoredList/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get sponsors using a parameter
  async getSponsorsSearch(parameter) {
    parameter.IdSocio = Number(AuthService.getCurrentIdUser());

    let url = API_URL + "affiliate/listPartners/search";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(parameter),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        //console.error(err);
        return undefined;
      });
  }

  // Get sponsors using a parameter
  async getSponsorsSearchV2(parameter) {
    parameter.ParametersSearched.IdSocioSearch = Number(
      AuthService.getCurrentIdUser()
    );

    let url = API_URL + "affiliate/list/partners";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(parameter),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Get sponsors using a parameter
  async getSponsorsExcelSearchV2(parameter) {
    parameter.IdSocioSearch = Number(
      AuthService.getCurrentIdUser()
    );

    let url = API_URL + "affiliate/export/partners";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(parameter),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function(response) {
        return response.blob();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Get number of branch
  async getNumberBranch() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "affiliate/listPartners/numberBranch/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // TODO
  async getActivations() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "suscription/user/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Service to dashboard
  // TODO
  async getDashBoard(id) {
    let url = API_URL + "dashboard/profile/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoState(id) {
    let url = API_URL + "dashboard/partnerState/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoScore(id) {
    let url = API_URL + "dashboard/score/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoResidual(id) {
    let url = API_URL + "dashboard/score/residual/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoCompuesto(id) {
    let url = API_URL + "dashboard/score/compuesto/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoRange(id) {
    let url = API_URL + "dashboard/rangesection/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // End dashboard

  getInformationAccount() {
    let url_account = API_URL + "account";
    let ac = {
      accountSoles: "2323 1234 1234",
      accountInterSoles: "2314 5678 3214",
      accountDolars: "4321 3576 1234",
      accountInterDolars: "3254 7678 4509",
    };
    return ac;
  }

  async isDocumentRegistered(data) {
    //
    let url_verifydoc = API_USR + "/verifydocument";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson.objModel;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }

  async isEmailRegistered(data) {
    let url_verifydoc = API_USR + "/verifyemail";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        let flag = false;
        if (dataJson.objModel == 1) {
          flag = true;
        }

        return flag;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }

  async isNumberAccountRegistered(data) {
    let url_verifydoc = API_URL + "AccountBank/CheckExistence/accountNumber";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        let flag = false;
        if (dataJson.objModel == 1) {
          flag = true;
        }

        return flag;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }
  async isNumberCciRegistered(data) {
    let url_verifydoc = API_URL + "AccountBank/CheckExistence/cci";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        let flag = false;
        if (dataJson.objModel == 1) {
          flag = true;
        }

        return flag;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }
  async isNumberAccountUpdateRegistered(data) {
    let url_verifydoc =
      API_URL + "AccountBank/CheckExistence/update/accountNumbe";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        let flag = false;
        if (dataJson.objModel == 1) {
          flag = true;
        }

        return flag;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }
  async isNumberCciUpdateRegistered(data) {
    let url_verifydoc = API_URL + "AccountBank/CheckExistence/update/cci";
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        let flag = false;
        if (dataJson.objModel == 1) {
          flag = true;
        }

        return flag;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }

  //recover password
  async recoverPassword(data) {
    let url_verifydoc = API_USR + "/ExecuteRecovery";
    return await fetch(url_verifydoc, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Register user
  async registerUser(data) {
    let url = API_URL + "register/insert";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Register user v2
  async registerUserv2(data) {
    //console.log(data)
    let url = API_URL + "register/registrar";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        alert(err);
        return undefined;
      });
  }

  // Register user v2
  async registerpreuser(data) {
    let url = API_URL + "User/insertpreuser";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Register vouchers from after payment
  async registerVouhers(data) {
    let url = API_URL + "Voucher/loadVoucherPaymentLater";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  // Register vouchers from after payment V2
  async registerPaymentLater(data) {
    let url = API_URL + "payment/pay/later";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  /**
   * To update sucription after pay with paypal
   * @param {*} idSuscription
   */
  async registerPaypal(idSuscription) {
    let url = API_URL + "suscription/update/" + idSuscription;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  /**
   * To update sucription after pay with paypal and add code of ppaypal
   * @param {*} idSuscription
   * @param {*} code
   */
  async registerPaymentPayPal(idSuscription, code, mount) {
    let url =
      API_URL +
      "suscription/update/" +
      idSuscription +
      "?nroOperation=" +
      code +
      "&amount=" +
      mount;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  //Start recovery pasword
  async startRecovery(data) {
    console.log("entraste a peticion startRecovery", data);
    let url_verifydoc = API_USR + "/StarRecovery";
    console.log("haber url_verifydoc", url_verifydoc);
    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function(dataJson) {
        console.log("datajson", dataJson);
        return dataJson;
      })
      .catch(function(err) {
        console.log("hay error?", err);
        console.error(err);
        return undefined;
      });
  }

  // Recovery password
  async recoveryPassword(data) {
    //
    let url_verifydoc = API_USR + "/Recovery";

    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // List users for admin
  async getListUserForAdmin(data) {
    let url = API_URL + "User/getListUsersOfAdmin";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // get list user with filters
  async getListUserSearchAdmin(data) {
    let url = API_URL + "User/getListUsersOfAdmin/search";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  //Get suscripciotns  for admin
  async getSuscriptionByUserAdmin(idUser) {
    let url = API_URL + "suscription/admin/user/" + idUser;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get user information by id
  async getUserById(id) {
    let url = API_URL + "User/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get User Accounts

  async getUserAccounts(idUser) {
    let url = API_URL + "AccountBank/" + idUser;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Get Info Account by Id Account

  async getInfoAccount(idAccountBank) {
    let url = API_URL + "AccountBank/id/" + idAccountBank;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  // Get Info Bank by Id AccountBank

  async getInfoBank(idBank) {
    let url = API_URL + "bank/" + idBank;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Register Bank Account
  async registerBankAccount(data) {
    //
    let url_verifydoc = API_URL + "AccountBank/register";

    return await fetch(url_verifydoc, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Update Bank Account
  async updateBankAccount(data) {
    let url_verifydoc = API_URL + "AccountBank/update";

    return await fetch(url_verifydoc, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Delete Bank Account
  async deleteBankAccount(idBankAccount) {
    //
    let url_verifydoc = API_URL + "AccountBank/delete/" + idBankAccount;

    return await fetch(url_verifydoc, {
      method: "DELETE",
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Save upliner register
  async saveUpliner(data) {
    let url = API_URL + "tree/placement/upliners/insert";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  /** Managament  */
  // Send message
  async sendWelcomeMsg(data) {
    let url = API_URL + "User/sendemail/welcome";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async sendAlertPayment(data) {
    let url = API_URL + "suscription/sendemail/alert/payment";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementAffiliate(data) {
    // let url = API_URL + "suscription/affiliates/direct";
    let url = API_URL + "tree/affiliate/direct";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementAffiliateSearch(data) {
    let url = API_URL + "tree/affiliate/direct/search";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementAffiliatev2(data) {
    let url = API_URL + "tree/affiliate/level";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  async managementAffiliateSearchv2(data) {
    let url = API_URL + "tree/affiliate/level/search";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementAffiliateLevel(data) {
    let url = API_URL + "tree/affiliate/level";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementOrganization(data) {
    let url = API_URL + "tree/residual/partners";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementRange(data) {
    let url = API_URL + "tree/affiliate/level/dashbord";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // detail payment schedule

  async getScheduleDatail(idSuscription) {
    let url = API_URL + "suscription/detailpay/" + idSuscription;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  // Gestion de activacion
  // async managementAffiliatebyUser(data) {

  //     let url = API_URL + "tree/affiliate/direct";

  //     return await fetch(url, {
  //         method: 'POST',
  //         body: JSON.stringify(data),
  //         headers: {
  //             'Content-Type': 'application/json'
  //         }
  //     })
  //         .then(function (response) {
  //             return response.json();
  //         })
  //         .then(function (dataJson) {

  //             return dataJson;
  //         })
  //         .catch(function (err) {
  //             console.error(err);
  //             return undefined;
  //         });
  // }

  async managementAffiliateFilter(data) {
    let url = API_URL + "suscription/affiliates/direct/search";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementResidual(data) {
    // let url = API_URL + "suscription/residual";
    let url = API_URL + "tree/residual/list";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async managementResidualFilter(data) {
    let url = API_URL + "suscription/residual/search";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Service to modify last schedule
  async getLastSchedule(iduser) {
    let url = API_URL + "membershipPayDetail/schedules/" + iduser;

    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async modifySchedule(iduser, data) {
    let url = API_URL + "suscription/join/schedule/"; // + iduser;

    return await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  //Get FamilyPackage ByPackageId
  async getFamilyPackagebyId(id) {
    let url = API_URL + "familyPackage/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async saveOperation(data) {
    let url = API_URL + "membershipPayDetail/insertoperation";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  /**
   * Save  payment of quote by paypal
   */
  async registerPaymentQuotePayPal(data) {
    let url = API_URL + "membershipPayDetail/schedule/payQuote";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  /**
   * Save  payment of quote by Quote
   */
  async registerPaymentQuoteVouhers(data) {
    let url = API_URL + "membershipPayDetail/schedule/payQuote/vouchers";
    console.log(url);
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  /**
   * Save  payment of quote by Quote
   */
  async registerPaymentQuoteVouhersv2(data) {
    let url = API_URL + "payment/pay";
    //console.log('se envia esta data =>',data)
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getInfoQuotePayment(token) {
    let url = API_URL + "tokenPayment/access?token=" + token;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getInfoMemberShip(idMembershipDetail) {
    let url = API_URL + `payment/${idMembershipDetail}`;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async registerPaymentQuoteWallet(data) {
    let url = API_URL + "membershipPayDetail/schedule/payQuote/wallet";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Period

  async getPeriodByUser(data) {
    let url = API_URL + "period/user";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  // End period

  // Update user
  async updateUser(data) {
    let url_verifydoc = API_URL + "User/gestor/update/contacdetail";

    let user = AuthService.getCurrentUser();

    return await fetch(url_verifydoc, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_Token}`,
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // Get comisiones
  async getComisiones(data) {
    let url = API_URL + "bonus";
    //console.log('data en getComisiones =>',data)
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //console.log('dataJson =>',dataJson)
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  // History
  async getResidualHistory(data) {
    let url = API_URL + "bonus/historical/residual/user";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getCompHistory(data) {
    let url = API_URL + "bonus/historical/compuesto/user";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getRangeByUser(data) {
    let url = API_URL + "bonus/historical/compuesto/rangebyuser";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async verifyUser(username) {
    let url_verify = API_USR + "/search/username";
    let data = {
      username: username,
    };
    return await fetch(url_verify, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async BasicDataChange(data) {
    let url = API_URL + "BasicDataChange";
    console.log(url);
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel

        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async MotiveChange(data) {
    let url = API_URL + "MotiveChange";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel

        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async BasicDataChangeDetail(data) {
    let url = API_URL + "BasicDataChangeDetail";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel

        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getDocumentos(data) {
    let url = API_URL + "educational/document/section";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        //return state true or false in objmodel

        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getNotificationUser() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "notification/" + id;
    //console.log(url)
    return await fetch(url, {
      method: "GET" /*, cache: "default"*/,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getNotification() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "suscription/notification/expiration/user/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async downloadTree() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "tree/residual/export/" + id;

    return await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "arbol_patrocionio.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        return true;
      })
      .catch(function(err) {
        console.error(err);
        return false;
      });
  }

  // Desposicionamiento
  async getListDeposition() {
    let id = AuthService.getCurrentIdUser();
    let url = API_URL + "tree/placement/deposition/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async getNumberBranch(id) {
    let url = API_URL + "tree/residual/branchs/number/" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }

  async generateDepostion(data) {
    let id = AuthService.getCurrentIdUser();
    data.IdUserGenerateTicket = Number(id);
    let url = API_URL + "Ticket/generate";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }

  async getCalification() {
    let data = {
      SizeList: 40,
      NroPage: 1,
    };

    let url = API_URL + "bonus/historical/listaward";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(dataJson) {
        return dataJson;
      })
      .catch(function(err) {
        console.error(err);
        return undefined;
      });
  }
  async getdatauserbyid(id) {
    let url = API_URL + "User/username?value=" + id;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async getdatauserbyemail(email) {
    let url = API_URL + "User/email?value=" + email;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async Registergetdatabytoken(token) {
    let url = API_URL + "User/code?value=" + token;
    return await fetch(url)
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return undefined;
      });
  }
  async useGracePeriod(data) {
    let url = API_URL + "graceperiod/Advance";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return undefined;
      });
  }

  async ChangeLoginName(data) {
    const { id, newLoginName } = data;
    let url =
      API_URL + `User/updateLoginName?id=${id}&newLoginName=${newLoginName}`;
    return await fetch(url, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .then((response) => {
        return response;
      });
  }

  async onFetchSearchPartners(textSearch, dataTypeSearch){
    let url = API_URL + "User/userSearch";
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify({dataTypeSearch, textSearch}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return undefined;
      });
  }

  async onFetchSearchImagePartner(id){
    let url = API_URL + `user/profilePicture/${id}`;
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return undefined;
      });
  }

  //registrar nuevo beneficiario
  async registerBeneficiary(data){
    let url = API_URL + "User/InsertBeneficiaries";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  // obtener lista de beneficiarios
  async getListBeneficiaries(idSponsor){
    let url = API_URL + `User/GetBeneficiariesByidSponsor/${idSponsor}`;
    
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  
  // actualizar beneficiario
  async updateBeneficiary(data){
    let url = API_URL + "User/UpdateBeneficiaries";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  //get bonos peridos
  async onGetQuarterlyPeriodBonus(id){
    let url = API_URL + `QuarterlyPeriodBonus/${id}`;
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return undefined;
      });
  }

  // obtener premios
  async onAwardsBonus() {
    let url = API_URL + `AwardsBonus`;
    
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  // Notifications Placement By User
  async notificationsPlacementByUser(){
    
    const id = +AuthService.getCurrentIdUser();
    let url = API_URL + `notification/notificationsPlacementByUser?IdUser=${id}`;

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  // Notificacions Concliations By User
  async notificationsConciliationByUser(){
    const id = +AuthService.getCurrentIdUser();
    let url = API_URL + `notification/notificationsConciliationByUser?IdUser=${id}`;

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async updateNotificationPlacementByUser(){
    const id = +AuthService.getCurrentIdUser();
    let url = API_URL + `notification/updateNotificationsPlacementByUser?IdUser=${id}`;

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getDirectCycleQualificationByUser(){
    const id = +AuthService.getCurrentIdUser();
    let url = API_URL + `DirectCycleQualification/getDirectCycleQualificationByUser?userId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async suscriptionAdminsendemail(data){
    let url = API_URL + "suscription/admin/sendemail";

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  // get concilaciones
  async getConciliationsByUser(){
    const id = +AuthService.getCurrentIdUser();
    const url = API_URL + `Conciliation/GetConciliationsByUser?userId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getConciliationDetailsByConciliationId(id){
    const url = API_URL + `Conciliation/GetConciliationDetailsByConciliationId?conciliationId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getDocumentTypeInvoice(){
    const url = API_URL + `Conciliation/GetDocumentTypeInvoice`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getExchangeRateLastDayOfTheMonth(year, month){
    const url = API_URL + `Conciliation/getExchangeRateLastDayOfTheMonth?year=${year}&month=${month}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getTipoCambioDayOfTheMonth(year, month){
    const url = API_URL + `TipoCambio/date?year=${year}&month=${month}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async conciliationInsertStatement(data) {
    let url = API_URL + "Conciliation/insertStatement";
    //console.log('se envia esta data =>',data)

    try {
      const res = await axios.post(
        `${url}`,
        data
      );

      return res.data;
    } catch (error) {
   
      return error.response.data;
    }
   
  }

  async conciliationInsertThirdPartyPayment(data) {
    let url = API_URL + "Conciliation/insertThirdPartyPayment";
    //console.log('se envia esta data =>',data)

    try {
      const res = await axios.post(
        `${url}`,
        data
      );

      return res.data;
    } catch (error) {
   
      return error.response.data;
    }
   
  }

  async getRentExemptionByUser(){
    const id = +AuthService.getCurrentIdUser();
    const url = API_URL + `RentExemption/getRentExemptionByUser?userId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async rentExemptionInsert(data) {
    let url = API_URL + "RentExemption/insert";
    const user = AuthService.getCurrentUser();
    //console.log('se envia esta data =>',data)

    try {
      const res = await axios.post(
        `${url}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user.access_Token}`
          }
        }
      );

      return res.data;
    } catch (error) {
   
      return error.response.data;
    }
   
  }

  async getDocumentTypeList(){
    const userInfo = AuthService.getCurrentUserInfo();

    const url = API_URL + `DocumentType/${userInfo.idNationality}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getCountrieslist(){
    const url = API_URL + `country/countrieslist`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
  }

  async getLegalizationRequestPrices(params){
    const url = API_URL + `LegalizationRequestPrice/getLegalizationRequestPrices?${params}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
    
  }

  async getGetAuthorizationLetter(){
    const url = API_URL + `Conciliation/getGetAuthorizationLetter`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
    
  }

  async getThirdPartyPaymentGetByUserId(){
    const id = +AuthService.getCurrentIdUser();
    const url = API_URL + `Conciliation/thirdPartyPaymentGetByUserId?userId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
    
  }

  async getCalculate12MonthProjectionByUserId(){
    const id = +AuthService.getCurrentIdUser();
    const url = API_URL + `Conciliation/Calculate12MonthProjection?userId=${id}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return undefined;
    });
    
  }

}

export default new UserService();
