import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import userService from "services/user.service";
import utilsService from "services/utils.service";

function UpdateUser({ actualizarUsuario }) {
  
  const [show, setShow] = useState(JSON.parse(localStorage.getItem('temporal')));
  const [datosUsuario, setDatosUsuario] = useState("");
  const [tiposDocumentos, setTiposDocumentos] = useState("");
  const [paises, setPaises] = useState([]);
 

  const handleClose = () => {

  
    localStorage.setItem('temporal',JSON.stringify(false))
   /*  console.log('ver temporal handleClose ',JSON.parse(localStorage.getItem('temporal')))*/
    setShow(JSON.parse(localStorage.getItem('temporal'))) 
  };
  const handleShow = () => setShow(true);

  
  const obtenerDatosUsuario = async () => {
    try {
      const datosUsuario = await userService.getInfobyUser();

      console.log("ver datosUsuario ", datosUsuario.objModel.user);
      setDatosUsuario(datosUsuario.objModel.user);
      obtenerTiposDocumentos(datosUsuario.objModel.user);
    } catch (error) {
      console.log(error);
    }
  };
  const obtenerTiposDocumentos = async (usuario) => {
    try {
      console.log("ver idNationality", usuario.idNationality);
      const { idNationality } = usuario;
      const tiposDocumentos = await utilsService.getTypeDocByNat(idNationality);

      console.log("ver tiposDocumentos ", tiposDocumentos.objModel);
      setTiposDocumentos(tiposDocumentos.objModel);
    } catch (error) {
      console.log(error);
    }
  };
  const obtenerPaises = async () => {
    try {
      const dataPaises = await utilsService.getResidences();

      console.log("ver paises ==> ", dataPaises.objModel);
      setPaises(dataPaises.objModel);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    obtenerDatosUsuario();
    obtenerPaises();
  }, []);

  const handleChange = (e) => {
    setDatosUsuario({
      ...datosUsuario,
      [e.target.name]: e.target.value,
    });
    /* setCambioTecla(true); */
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const usuario = actualizarUsuario;
    //const eliminarUsuarioDescripcion = "description";
    delete usuario.description;
    delete usuario.equalData;
    delete usuario.isSuccess;
    //console.log("ver usuario deleteado =>", usuario);

  /*   console.log("vas a enviar esto", { ...datosUsuario, resetPassword: false 
    ,
    verifyUserData:{...usuario,isEqualData :true}
    }
    
    ); */
/* console.log('datosUsuario ',datosUsuario);
console.log('ver telefono',datosUsuario.nroTelf); */
     if( !datosUsuario.nroTelf.match(/^[0-9]+$/) ){
        alert('Error, el número de celular no debe contener caracteres especiales (+,-,*, etc) ni espacios en blanco');
        return false;
     }
     const data = { ...datosUsuario, resetPassword: false 
      ,
      verifyUserData:{...usuario,isEqualData :false}
      };
    try {
      let response = await userService.updateAffiliateInfo(data);
      console.log("ver response ->", response);
      if (response.status == 1) {
        alert("Tus datos han sido actualizados.");
        handleClose();
      } else {
        alert("Ocurrió un problema. Inténtelo más tarde.");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mismosDatos = async () => {
    const usuario = actualizarUsuario;
    //const eliminarUsuarioDescripcion = "description";
    delete usuario.description;
    delete usuario.equalData;
    delete usuario.isSuccess;

    if( !datosUsuario.nroTelf.match(/^[0-9]+$/) ){
      alert('Error, el número de celular no debe contener caracteres especiales (+,-,*, etc) ni espacios en blanco');
      return false;
    }

    const data = { ...datosUsuario, resetPassword: false 
      ,
      verifyUserData:{...usuario,isEqualData :true}
      };

      console.log('misma data =>',data)
      console.log('ver actualizarUsuario ',actualizarUsuario);
        try {
      let response = await userService.updateAffiliateInfo(data);
      console.log("ver response ->", response);
      if (response.status == 1) {
        alert("Solo puedo usarlo 2 veces ! muchas gracias!.");
        handleClose();
      } else {
        alert("Ocurrió un problema. Inténtelo más tarde.");
        return;
      }
    } catch (error) {
      console.log(error);
    } 
  };
  console.log('actualizarUsuario',actualizarUsuario);

  return (
    <>
      <Modal
        show={show}
        /*   onHide={handleClose} */
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>
            <p className="text-uppercase text-primary fw-bold">
              Actualice sus datos :
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => onSubmit(e)}>
            <Row>
              {paises.length >= 0 ? (
                <Col sm={4}>
                  <Form.Group>
                    <Form.Label>Nacionalidad</Form.Label>
                    <Form.Select
                      name="idNationality"
                      value={datosUsuario && datosUsuario.idNationality}
                      onChange={(e) => handleChange(e)}
                    >
                      {paises.map((item, i) => (
                        <option value={item.idCountry} key={i}>
                          {item.name}
                        </option>
                      ))}
                      {/*  {this.state.nationalities} */}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : (
                <p>no hay paises</p>
              )}

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Nombres *</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={datosUsuario && datosUsuario.name}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Apellidos *</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={datosUsuario && datosUsuario.lastname}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Sexo *</Form.Label>
                  <div key={"inline-radio"} className="mb-3">
                    <Form.Check
                      inline
                      label="Masculino"
                      name="gender"
                      type="radio"
                      id={`inline-radio`}
                      value="M"
                      onChange={(e) => handleChange(e)}
                      checked={datosUsuario.gender === "M"}
                    />
                    <Form.Check
                      inline
                      label="Femenino"
                      name="gender"
                      type="radio"
                      id={`inline-radio2`}
                      value="F"
                      onChange={(e) => handleChange(e)}
                      checked={datosUsuario.gender === "F"}
                    />
                  </div>
                </Form.Group>
              </Col>

              {paises.length > 0 && (
                <Col sm={4}>
                  <Form.Group>
                    <Form.Label>País de residencia</Form.Label>
                    <Form.Select
                      name="idResidenceCountry"
                      value={datosUsuario && datosUsuario.idResidenceCountry}
                      onChange={(e) => handleChange(e)}
                      //onChange={e => this.handleSelectList(e, "idResidenceCountry")}
                    >
                      {/*  {this.state.residenceList} */}

                      {paises.map((item, i) => (
                        <option value={item.idCountry} key={i}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Nro. Celular *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nro. celular"
                    name="nroTelf"
                    value={datosUsuario && datosUsuario.nroTelf}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => {
                      let key = window.Event ? e.which : e.keyCod;
                      if ( key < 48 || key > 57  ) {
                          e.preventDefault();
                      }
                    }}
                    required
                   
                  ></Form.Control>
                  <span className="text-danger fw-bold pb-2" style={{fontSize:'11px'}}>
                  Por favor evitar escribir el codigo del país nuevamente,
                  gracias!
                </span>
                  <Form.Control.Feedback type="invalid">
                    Ingrese un número de celular válido.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {tiposDocumentos.length > 0 && (
                <Col sm={4}>
                  <Form.Group>
                    <Form.Label>Tipo de documento *</Form.Label>
                    <Form.Select
                      name="idDocument"
                      value={datosUsuario && datosUsuario.idDocument}
                      onChange={(e) => handleChange(e)}
                    >
                      {tiposDocumentos.map((item, i) => (
                        <option value={item.idCountry} key={i}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <br></br>
                  </Form.Group>
                </Col>
              )}

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Nro. de documento *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nro. documento"
                    name="nroDocument"
                    value={datosUsuario && datosUsuario.nroDocument}
                    onChange={(e) => handleChange(e)}
                    disabled
                  ></Form.Control>
                  <Form.Text className="textAlert">
                    {/*   {this.state.messageDoc} */}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Distrito / Estado *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Distrito"
                    name="districtAddress"
                    value={datosUsuario && datosUsuario.districtAddress}
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Ingrese su distrito.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Dirección"
                    name="address"
                    value={datosUsuario && datosUsuario.address}
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Ingrese su dirección.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Correo electrónico"
                    name="email"
                    value={datosUsuario && datosUsuario.email}
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row></Row>

            <Row className="mt-4">
              <Col sm={2}>
                <Button variant="primary text-uppercase" type="submit">
                  Actualizar
                </Button>
              </Col>
            </Row>
            {
              actualizarUsuario.equalData <= 2 && (
                <Row>
                <Col sm={6} className="mt-2">
                  <Button
                    variant="warning text-uppercase"
                    type="button"
                    onClick={() => mismosDatos()}
                  >
                    Mis datos siguen siendo los mismos
                  </Button>
                </Col>
              </Row>
              )
            }
          
          </Form>
          {/* <Row className="mt-3">
            <Col sm={4}>
              <p >Deseas cambiar tu nombre , apellido o numero de documento? Cambia tus datos aqui</p>
            </Col>
            <Col sm={4}>
            <Button variant="primary" type="submit" disabled>
                Cambiar Datos
                </Button>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateUser;
