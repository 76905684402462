import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import {
  Button,
  Carousel,
  Col,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import MenuHome from "../../components/home/MenuHome";
import LinkRegister from "../../components/register/LinkRegister";
import logo3 from "../../images/carousel/BANNER INRESORT FINAL.png";
import logo4 from "../../images/carousel/BANNER KEOLA-3.png";
import banner1 from "../../images/carousel/BANNER-INCLUB.png";
import banner2 from "../../images/carousel/BANNER-INRESORT.png";
import banner3 from "../../images/carousel/BANNER-KEOLA.png";
import AuthService from "../../services/auth.service";
// import TreeView from '../tree/TreeView';
import RegisterMainView from "../login/RegisterMainView";
import FlyerView from "../message/FlyerView";
import history from "../navigation/history";
import "../styles/Flyer.css";
import MainView from "./MainView";
import NetworkView from "./NetworkView";
import PayView from "./PayView";
import ShopView from "./ShopView";
import ToolView from "./Toolview";
import UpdateUser from "./UpdateUser";

export default class HomeView extends Component {
  constructor(props) {
    super(props);

    // get idSocio from current socio
    let idSocio = Number(AuthService.getCurrentIdUser());
    this.state = {
      idSocio: idSocio,
      loadSenData: false,
      eventState: {
        showMenuHome: true,
        showMenuSocio: false,
        showMenuTool: false,
        showMenuNet: false,
        showMenuPay: false,
        showMenuShop: false,
      },
      showModalFlyer: false,
    };

    localStorage.setItem("flyer", 1);
  }

  componentDidMount() {
    this.verifyCurrentUser();
    if (Number(localStorage.getItem("flyer")) === 0) {
      this.setState({
        showModalFlyer: true,
      });
      localStorage.setItem("flyer", 1);
    }
  }

  verifyCurrentUser() {
    if (this.state.idSocio <= 0) {
      this.setState({
        loadSenData: true,
      });
    }
  }

  onClicked = (e, path) => {
    history.push(path);
  };

  redirect = (e) => {
    history.push("sign-in");
  };
  /**
   * Event of menu's component
   * @param {*} eventMenuState stat of menu component to show Views
   */
  eventMenu = (eventMenuState) => {
    this.setState({ eventState: eventMenuState });
  };

  // handle close modal flyer
  handleClose = () => {
    this.setState({
      showModalFlyer: false,
    });
  };

  render() {
   
    //const isLogged = AuthService.getIsLogged();
    const { eventState, idSocio, showModalFlyer } = this.state;
    //const name = AuthService.getName();
    //const idUser = AuthService.getCurrentIdUser();
    //const infoP = JSON.parse(sessionStorage.getItem("info"));

    const actualizarUsuario = localStorage.getItem("usuarioData")
      ? JSON.parse(localStorage.getItem("usuarioData"))
      : "";

   // console.log("ver actualizarUsuario  LoginGuest==>", actualizarUsuario);

    return (
      <div style={{ background: "white" }}>
        <Helmet>
          <title>Inclub</title>
        </Helmet>
        {/* <div style={{display: showCarrusel?'inline':'none'}}> */}
        {/* CAROUSEL INICIANDO SESION*/}
        <Carousel>
          <Carousel.Item>
            <Image className="d-block w-100" src='https://staticv1.inclub.site/images/inresort.png' />
          </Carousel.Item>
          <Carousel.Item>
            <Image className="d-block w-100" src='https://staticv1.inclub.site/images/keola-banner.png' />
          </Carousel.Item>
          <Carousel.Item>
            <Image className="d-block w-100" src='https://staticv1.inclub.site/images/keolalottery.png' />
          </Carousel.Item>
          <Carousel.Item>
            <Image className="d-block w-100" src='https://staticv1.inclub.site/images/la-joya-banner.png' />
          </Carousel.Item>
          <Carousel.Item>
            <Image className="d-block w-100" src={banner3} />
          </Carousel.Item>
        </Carousel>
        {/* </div> */}
        <div className="home-container">
          {/* Menu home */}
          <MenuHome
            onChange={this.eventMenu}
            onClick={this.onClicked}
          ></MenuHome>
          <hr></hr>
          {/* Content */}
          <div>
            {eventState.showMenuHome && <MainView idUser={idSocio}></MainView>}
            {eventState.showMenuSocio && (
              <div>
                <Row>
                  <Col sm={12}>
                    <LinkRegister idSocio={idSocio}></LinkRegister>
                  </Col>
                </Row>
                <RegisterMainView
                  idSocio={idSocio}
                  showWallet={true}
                ></RegisterMainView>
              </div>
            )}
            {eventState.showMenuTool && <ToolView></ToolView>}
            {eventState.showMenuNet && <NetworkView></NetworkView>}
            {eventState.showMenuPay && <PayView></PayView>}
            {eventState.showMenuShop && <ShopView></ShopView>}
          </div>
        </div>

        <Modal
          show={this.state.loadSenData}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    No pudimos obtener informacion de usuario.
                  </Form.Label>
                  <Form.Label>Inicie sesión nuevamente.</Form.Label>
                </Col>
              </Row>
              <Button onClick={(e) => this.redirect(e)}>Iniciar sesión</Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModalFlyer}
          onHide={this.handleClose}
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <FlyerView></FlyerView>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        {console.log('actualizarUsuario.requestDataUpdate',actualizarUsuario.requestDataUpdate)}
     {/*    <UpdateUser actualizarUsuario={actualizarUsuario}/> */}
              {
                actualizarUsuario.requestDataUpdate ===true && (
                  <UpdateUser actualizarUsuario={actualizarUsuario}/>
                )
              }
     
      {/*   {actualizarUsuario === true ? <UpdateUser /> : null} */}
      
      </div>
    );
  }
}
