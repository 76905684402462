import Validation from 'components/utils/Validation'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { MdFileDownload } from 'react-icons/md'
import walletService from 'services/wallet.service'

const TransferBrandExclusive = ({
    idUser
}) => {
    const [listBank, setListBank] = useState([])
    const [typesElectronic, setTypesElectronic] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [IdAccountBank, setIdAccountBank] = useState(0)
    const [messageMount, setMessageMount] = useState('')
    const [mount, setMount] = useState(0)
    const [img, setImg] = useState('')

    const [loadSenData, setLoadSenData] = useState(false)

    const [listTransactions, setListTransactions] = useState([])

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }


    const handleShowModal = () => {
        setShowModal(true)
    }
    const handleCloseModal = () => {
        setShowModal(false)
    }
    const handleSelect = (e) => {
        setIdAccountBank(e.target.value)
    }
    const handleMount = (e) => {
        setMount(e.target.value)
    }
    const handleFile = (e) => {
        let image = e.target.files[0];

        if (image !== undefined) {
            getBase64(image, (result) => {
                let base64Image = result;
                setImg(base64Image)
            });

        } else {
            setImg('')
        }
    }
    // Get accounts
    const getAccounts = async () => {
        let response = await walletService.getAccountBanks(idUser);
        if (response !== undefined && response.status === 1) {
            setListBank(response.objModel)
        }
    }

    const getType = async () => {
        let response = await walletService.getElectronic();
        if (response !== undefined && response.status === 1) {
            setTypesElectronic(response.objModel)
        }
    }
    const getTransactions = async () => {
        let response = await walletService.getTransactionByUserME(idUser);
        if (response !== undefined && response.status === 1) {
            setListTransactions(response?.objModel?.listElements)
        }
    }
    useEffect(() => {
        getAccounts()
        getType()
        getTransactions()
    }, [loadSenData])

    const validate = () => {
        if (Number(IdAccountBank) <= 0) {
            alert("Seleccione una cuenta");
            return false;
        }
        if (Number(mount) <= 0) {
            alert("Ingrese un monto válido.");
            return false;
        }
        if (img === '') {
            alert("Seleccione una imagen.");
            return false;
        }
        return true;
    };
    const registerTransfer = async (e) => {
        if (validate()) {
            setLoadSenData(true)
            let contenType = img.split(',');
            let data = {
                IdUser: Number(idUser),
                IdAccountBank: Number(IdAccountBank),
                WalletTransaction: {
                    Amount: Number(mount),
                    IdTypeWalletTransaction: 31,
                },
                CPicture: {
                    Base64: contenType[1],
                },
            }
            let response = await walletService.registerTransferAccount(data);
            if (response !== undefined) {
                setLoadSenData(false)
                if (response.status === 1) {
                    setMount('')
                    setShowModal(false)
                    alert("Solicitud registrada exitosamente.");
                } else {
                    alert("Ocurrió un error al momento de registrar su transferencia.");
                }
            } else {
                setLoadSenData(false)
                alert("Tuvimos un problema. Inténtalo más tarde.");
            }
        }
    }
    return (
        <div
            style={{ padding: 30 }}
        >
            <Row>
                <Col sm={12}>
                    <Form.Label className="content-subtitle" >
                        Historial de transferencias Marca Exclusiva
                    </Form.Label>
                </Col>
            </Row>
            <Row style={{ textAlign: 'right', paddingBottom: '15px' }}>
                <Col sm={12}>
                    <Button
                        variant="success"
                        size="sm"
                        onClick={handleShowModal}
                    >
                        Solicitar transferencia
                    </Button>
                </Col>
            </Row>
            {/* Paginador */}
            <Row>
                {/* <Form.Label column xs={12} sm={8} md={7} xl={6}>
                Mostrando del 1 al 10 de 100 registros
                <Form.Select
                  className="my-2 ml-2 mr-1"
                  id="selecPagesComision"
                  defaultValue={"1"}
                //   value={page}
                  onChange={(e) => {
                    // this.handlePageChange(e, "page");
                  }}
                  size="sm"
                  style={{display:"inline",width:"auto"}}
                >*/}
                {/* {optionPages} */}
                {/* 
                </Form.Select>
                {" "} de {totalPages}. Por página: {" "}
                <Form.Select
                    className="my-2 mr-sm-4"
                    id="selectCountcomision"
                    // defaultValue={size}
                    // onChange={(e) => {
                    //   this.handleSizeChange(e, "size");
                    // }}
                    size="sm"
                    style={{display:"inline",width:"auto"}}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </Form.Select>
              </Form.Label> */}

            </Row>
            {/* tabla */}
            <div>
                <Row>
                    <Col sm={12}>
                        <Table responsive>
                            <thead className="table-head">
                                <tr>
                                    <th>Nº </th>
                                    <th>Fecha</th>
                                    <th>Titular</th>
                                    <th>Banco</th>
                                    <th>Descripción</th>
                                    <th>Monto</th>
                                    <th>Solicitud</th>
                                </tr>
                            </thead>
                            <tbody>

                                {listTransactions.map((item, i) => {
                                    return (
                                        <tr key={item.id}>
                                            {/* <td>{size * (page - 1) + 1 + i}</td> */}
                                            <td>{i + 1}</td>
                                            <td>
                                                {Validation.convertDateToStringComplete(
                                                    item.inicialDate
                                                )}
                                            </td>
                                            <td>{item.holder}</td>
                                            <td>{item.nameBank}</td>
                                            <td>{item.referenceData}</td>
                                            <td>{Math.abs(item.amount)}</td>
                                            <td>{item.nameRequest}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
            {/* Solicitar transferencia */}
            <Modal
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                onHide={handleCloseModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Solicitar transferencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group style={{ paddingBottom: '15px' }}>
                        <Form.Label>Seleccione cuenta de destino</Form.Label>
                        <Form.Control as="select"
                            defaultValue="Default"
                            onChange={e => { handleSelect(e) }}>
                            <option value="Default" disabled>Seleccione cuenta ...</option>
                            {listBank.map((elem) => (
                                <option key={elem.idAccountBank} value={elem.idAccountBank}>
                                    {elem.nameBank} - {elem.numberAccount}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Monto a transferir</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Ingrese monto"
                            min={0}
                            value={mount}
                            onChange={e => { handleMount(e) }}
                        />
                        <Form.Label className="textAler">{messageMount}</Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Subir comprobante y/o Declaración jurada </Form.Label>
                        <input type="file"
                            accept="image/png, image/jpeg"
                            onChange={e => handleFile(e)} />
                        <br></br>
                        <a href="/files/template.jpeg" download><MdFileDownload></MdFileDownload>Ejemplo</a><br></br>

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={registerTransfer}>
                        Solicitar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={loadSenData}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        <Spinner size="sm" animation="border" variant="dark"></Spinner>
                        <Form.Label>&nbsp; Guardando información...</Form.Label>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TransferBrandExclusive
