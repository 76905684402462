import Avatar from "@material-ui/core/Avatar";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import React, { Component } from "react";
import {
  Button,
  Form,
  Navbar,
  Image,
  Nav,
  Modal,
  NavDropdown,
} from "react-bootstrap";
import { AiFillCreditCard } from "react-icons/ai";
import logo_nav from "../../images/navologo.png";
import AuthService from "../../services/auth.service";
//import userService from "../../services/user.service";
import UtilsService from "../../services/utils.service";
// import ChatBotIntech from "../../components/chatbot/ChatBot";
// import creditCard from "../../images/icons/creditCard.png";
import history from "../navigation/history";
import "../styles/Custom.css";
import "./NavBar.css";
// import { Divider } from "@material-ui/core";
import Notification from "./Notification";

const link = `${process.env.REACT_APP_APP_URL}`;

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: false,
      showAccount: false,
      navExpanded: false,
      showGuest: false,
      exchange: 0.0,
      user: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getExchange();
    //this.getNotifications();
    this.setState({ loading: false });
  }

  /*getNotifications = async () => {
    let response = await userService.getNotificationUser();
    if (response !== undefined && response.status === 1) {
      this.setState({
        notifications: response.objModel,
      });
    }
  };*/

  getExchange = async () => {
    let response = await UtilsService.getExchanges();
    if (response !== undefined) {
      if (response.status === 1) {
        this.setState({
          exchange: (this.state.exchange = response.objModel.venta),
        });
      }
    }
  };
  /**
   * Redirect to path
   * @param {*} e
   * @param {*} path
   */
  OnClicked = (e, path) => {
    history.push(path);
    this.setState({
      navExpanded: false,
    });
  };

  handleHome = (e) => {
    let currentLocation = window.location;

    if (currentLocation.pathname.includes("home")) {
      window.location.reload();
    } else {
      history.push("/home");
    }
  };

  // Handle modal logout
  handleClose = (e) => {
    this.setState({
      showLogout: false,
    });
  };

  handleShow = (e) => {
    e.preventDefault();
    this.setState({
      showLogout: true,
    });
   
  };

  onCloseSession = (e) => {
    e.preventDefault();
    AuthService.logout();

    if (this.props.logout) {
      this.props.logout();
      localStorage.removeItem('temporal')
    }
    this.setState({
      showLogout: false,
    });
  };

  //  LoginGuest
  OnClickGuest = async (e) => {
    e.preventDefault();

    let data = {
      username: this.state.user,
      password: this.state.password,
    };
    // Load services
    let response = await AuthService.loginAdmin(data);
    if (response !== undefined && response.access_Token !== null) {
      this.setState({
        showGuest: false,
        user: "",
        password: "",
      });

      // redireect new page sha512
      let idUser = response.idUser;
      let field =
        "6f1e7dc9b9c43fb7dd2ff99b7a6bc0cb5607e8b03d5a97ae3004685338a7f1821ec146da4567500bd97fb2e851df1a1b99361a7ff2366b7700ebc856d702cb69";
      let url = link + "/guest?" + field + "=" + idUser;
      window.open(url, "_blank");
    } else {
      this.setState({
        showGuest: false,
        user: "",
        password: "",
      });
      alert("Ocurrió un problema. Inténtelo más tarde.");
    }
  };

  handleShowGuest = (e) => {
    e.preventDefault();
    this.setState({
      showGuest: true,
    });
  };

  handleCloseGuest = (e) => {
    this.setState({
      showGuest: false,
      use: "",
      password: "",
    });
  };

  handleChange = (e, field) => {
    let value = e.target.value;
    this.setState({
      [field]: value,
    });
  };

  ///// End guest

  setNavExpanded = (expanded) => {
    this.setState({
      navExpanded: expanded,
    });
  };

  // Handle modal account
  handleCloseAccount = (e) => {
    this.setState({
      showAccount: false,
    });
  };
  handleShowAccount = (e) => {
    this.setState({
      showAccount: true,
    });
  };

  render() {
    const isLogged = AuthService.getIsLogged();
    const name = AuthService.getName();
    const idUser = AuthService.getCurrentIdUser();
    const defaultPreview = AuthService.getPicture();

    // TODO implement to master
    let issuperUser = false;
    if (Number(idUser) === 12853) {
      issuperUser = true;
    }
    const { navExpanded, notifications } = this.state;

    return (
      <div>
        <Navbar
          style={isLogged ? {} : { background: "#31769f", color: "white" }}
          expand="lg"
          onToggle={this.setNavExpanded}
          expanded={navExpanded}
          bg={isLogged ? "light" : null}
          variant={isLogged ? "light" : "dark"}
          className="tw-px-2"
        >
          <Navbar.Brand
            className="tw-cursor-pointer"
            onClick={(e) => this.handleHome(e)}
          >
            {isLogged && (
              <Image
                src="/resources/header/navologo.png"
                className="tw-w-28"
              ></Image>
            )}
            {!isLogged && (
              <Image
                src="/resources/header/logowhite.png"
                className="tw-w-28"
              ></Image>
            )}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="tw-ml-auto"
          />
          {isLogged && (
            <>
              <Navbar.Collapse id="basic-navbar-nav" className="tw-text-right">
                <Nav className="justify-content-end tw-w-full">
                  <NavDropdown
                    title={name}
                    id="basic-nav-dropdown"
                    style={{ border: 0 }}
                  >
                    <NavDropdown.Item
                      href=""
                      onClick={(e) => this.OnClicked(e, "/profile")}
                    >
                      Mi Cuenta
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href=""
                      onClick={(e) => this.handleShow(e)}
                    >
                      Cerrar sesión
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {/* <NavDropdown.Item onClick={e => this.handleShowAccount(e)}>Información de Empresa</NavDropdown.Item>
              <NavDropdown.Divider /> */}
                    {issuperUser && (
                      <NavDropdown.Item
                        href=""
                        onClick={(e) => this.handleShowGuest(e)}
                      >
                        Ver perfil de socio
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>

                  <Avatar alt="" src={defaultPreview} />

                  <div
                    style={{
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      textAlign: "center",
                      marginLeft: 3,
                      color: "#fff",
                      background: "#142d47",
                      padding: 2,
                      fontSize: 22,
                    }}
                    onClick={(e) => this.handleShowAccount(e)}
                  >
                    <AiFillCreditCard />
                  </div>
                </Nav>
              </Navbar.Collapse>
              
              <a href="https://api.whatsapp.com/send/?phone=51950612210&text&type=phone_number&app_absent=0" target="_blank"><i className='bx bxl-whatsapp' ></i></a>
              {!this.state.loading && <Notification />}
            </>
          )}
          {!isLogged && (
            <Navbar.Collapse id="basic-navbar-nav" className="tw-text-right">
              <Nav className="justify-content-end tw-w-full tw-pr-1">
                <Nav.Link style={{ color: "white" }} to="/home-in" as={NavLink}>
                  Home
                </Nav.Link>
                <Nav.Link
                  style={{ color: "white" }}
                  to="/portfolio"
                  as={NavLink}
                >
                  Portafolio
                </Nav.Link>
                <div className="main">
                  <Nav.Link
                    className="btn btn-orange btn-animate tw-inline-block tw-px-[0.5rem_!important] tw-py-[0.375rem] [color:white_!important] hover:[color:rgb(49,118,159)_!important]"
                    to="/calendar-events"
                    as={NavLink}
                  >
                    Eventos
                  </Nav.Link>
                </div>

                <div className="main">
                  <Nav.Link
                    className="btn btn-orange btn-animate tw-inline-block tw-px-[0.5rem_!important] tw-py-[0.375rem] [color:white_!important] hover:[color:rgb(49,118,159)_!important]"
                    to="/form-preregister-in"
                    as={NavLink}
                  >
                    Únete hoy
                  </Nav.Link>
                </div>

                <div className="main">
                  <Nav.Link
                    className="btn btn-orange btn-animate tw-inline-block tw-px-[0.5rem_!important] tw-py-[0.375rem] [color:white_!important] hover:[color:rgb(49,118,159)_!important]"
                    to="/sign-in"
                    as={NavLink}
                  >
                    Iniciar sesión
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar>
        {/* Notification */}
        {/* {notifications.length > 0 &&
          notifications.map((item, idx) => {
            return (
              <div
                className="notification"
                style={{
                  display: "-webkit-flex",
                  position: "fixed",
                  right: "40px",
                  zIndex: "999",
                  top: 60 + idx * 100,
                  background: "red",
                }}
              >
                <Toast
                  onClose={(e) => this.closeNotification(idx)}
                  show={item.show}
                  delay={20000}
                  autohide
                >
                  <Toast.Header>
                    <strong className="mr-auto">{item.title}</strong>
                  </Toast.Header>

                  <Toast.Body>{item.description}</Toast.Body>
                </Toast>
              </div>
            );
          })} */}

        {/* Modal  cerrar sesion */}
        <Modal
          show={this.state.showLogout}
          onHide={this.handleClose}
          size="sm"
          centered
        >
          <div className="modal-body p-4 text-center">
            <h5 className="modal-title fw-bold mb-0">¿Desea cerrar sesión?</h5>
          </div>
          <div className="modal-footer p-0">
            <button
              type="button"
              onClick={(e) => this.onCloseSession(e)}
              className="btn btn-lg btn-link fs-6 col m-0 text-decoration-none rounded-0"
            >
              <strong>Sí, cerrar sesión</strong>
            </button>
            <button
              type="button"
              onClick={(e) => this.handleClose(e)}
              className="btn btn-lg btn-link fs-6 col m-0 p-auto text-decoration-none rounded-0"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </Modal>
        {/* Modal cuenta*/}
        <Modal
          show={this.state.showAccount}
          onHide={(e) => this.handleCloseAccount(e)}
          style={{ fontSize: 12 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Información de empresa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Razón Social: </Form.Label>
                <p>VALLE ENCANTADO SAC</p>

                <Form.Label>RUC:</Form.Label>
                <p>20601460271 </p>
              </Form.Group>

              <Form.Group>
                <Form.Label>Cuenta Corriente Soles BCP: </Form.Label>
                <p>191-2606708-0-82</p>
                <Form.Label>Cuenta Corriente Soles BCP CCI: </Form.Label>
                <p>00219100260670808255</p>

                <Form.Label>Cuenta Corriente Dólares BCP: </Form.Label>
                <p>191-2616687-1-90</p>

                <Form.Label>Cuenta Corriente Dólares BCP CCI: </Form.Label>
                <p>00219100261668719050</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Cuenta Corriente Soles INTERBANK:</Form.Label>
                <p>200-3002538987</p>

                <Form.Label>Cuenta Corriente Soles INTERBANK CCI:</Form.Label>
                <p>003-200-003002538987-32</p>

                <Form.Label>Cuenta Corriente Dólares INTERBANK:</Form.Label>
                <p>200-3002538994</p>

                <Form.Label>Cuenta Corriente Dólares INTERBANK CCI:</Form.Label>
                <p>003-200-003002538994-38</p>
              </Form.Group>
              <hr></hr>
              <Form.Group>
                <p>
                  <b>Tipo de cambio : </b>
                  {this.state.exchange}
                </p>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => this.handleCloseAccount(e)}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal guest*/}
        <Modal
          show={this.state.showGuest}
          onHide={(e) => this.handleCloseGuest(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ver perfil de socio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese usuario"
                  onChange={(e) => {
                    this.handleChange(e, "user");
                  }}
                />
              </Form.Group>
            </Form>
            <Form>
              <Form.Group style={{ paddingTop: "20px" }}>
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Ingrese contraseña"
                  onChange={(e) => {
                    this.handleChange(e, "password");
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={(e) => this.OnClickGuest(e)}>
              Ingresar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
